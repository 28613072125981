import { FC } from 'react';
import { Typography, Link, Box } from '@mui/material';
import { Tr, UsefulLinkUtils } from '@utils';
import { ImpuritySpectrum, IrAnalysisResult, RankBasedMixtureData, SampleSpectrumInfo } from '@services/ir';
import { IrQualitativeTableOfAssignment } from '../IrIdentificationTableOfAssignment';
import { IrQuantificationTableOfAssignment } from '../IrQuantificationTableAssignment';
import { IrPurityCheckTableOfAssignment } from '../IrPurityCheckTableAssignment/IrPurityCheckTableAssignment';

interface IPeaksTableProps {
	resultData: IrAnalysisResult;
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
	impuritiesPeaksData?: ImpuritySpectrum[];
}

export const IRPeaksTable: FC<IPeaksTableProps> = ({ resultData, rankedRMData, userSpectrumInfo }) => {
	return (
		<>
			{rankedRMData && (
				<Typography variant="h3" sx={{ color: 'primary.main' }} marginBottom={2}>
					{resultData.isQuantification() ? (
						<Tr.IrPortal path="analysis-result.table-of-calculation" />
					) : (
						<Tr.IrPortal path="analysis-result.table-of-assignment" />
					)}
				</Typography>
			)}

			{!resultData.isIdentification() && !resultData.isQuantification() && (
				<>
					<IrPurityCheckTableOfAssignment rankedRMData={rankedRMData} userSpectrumInfo={userSpectrumInfo} />
				</>
			)}

			<Box
				sx={{
					width: 'initial',
					overflow: 'auto',
					borderRadius: '8px',
					border: '1px solid',
					borderColor: 'grey.200',
				}}
			>
				{resultData.isQuantification() && (
					<IrQuantificationTableOfAssignment rankedRMData={rankedRMData} userSpectrumInfo={userSpectrumInfo} />
				)}
				{resultData.isIdentification() && (
					<IrQualitativeTableOfAssignment
						resultData={resultData}
						rankedRMData={rankedRMData}
						userSpectrumInfo={userSpectrumInfo}
					/>
				)}
			</Box>

			<Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 700, marginTop: '1rem' }}>
				<Tr.IrPortal path="analysis-result.footnotes" />
			</Typography>
			<Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
				{'NA'}
			</Typography>

			<Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 700, marginTop: '1rem' }}>
				<Tr.IrPortal path="analysis-result.diagnostics" />
			</Typography>

			<Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
				{'NA'}
			</Typography>

			<Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 700, marginTop: '1rem' }}>
				<Tr.IrPortal path="analysis-result.other-notes" />
			</Typography>
			<Typography variant="caption" sx={{ fontSize: '1rem', fontWeight: 400 }}>
				<Tr.IrPortal path="analysis-result.find-additional" />{' '}
				<Link
					href={UsefulLinkUtils.FAQ}
					target="_blank"
					sx={{ color: 'text.secondary', fontWeight: 'bold', textTransform: 'underline', textDecorationColor: 'inherit' }}
				>
					<Tr.IrPortal path="analysis-result.faq" />
				</Link>
			</Typography>
		</>
	);
};
