import { Stack } from '@mui/material';
import { Tr } from '@utils/Translation';
import { IrDRMIndicator } from '../IrDrmDetailSection';
import { IrDrmDetail } from '@services/ir';
import { FC } from 'react';
import { OverflowText } from '@components/common';

type TIrReferenceMaterialCardContent = {
	detailData?: IrDrmDetail;
};

export const IrReferenceMaterialCardContent: FC<TIrReferenceMaterialCardContent> = ({ detailData }) => {
	return (
		<Stack direction="row">
			<Stack width="32.1rem">
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.molecular-weight" />}
					value={detailData?.molecularWeight ? detailData?.molecularWeight : undefined}
					unit="g/mol"
				/>
				<Stack direction="row" marginBottom={1.5}>
					<Stack sx={{ maxWidth: '230px', width: '100%', marginRight: '24px' }}>{<Tr.IrPortal path="drm-detail.smiles" />}</Stack>
					<Stack sx={{ width: '230px' }}>
						<OverflowText display="flex" variant="pg-m" color="grey.800" enableTooltip>
							{detailData?.smilesCode || ''}
						</OverflowText>
					</Stack>
				</Stack>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.physical-product-number" />}
					value={detailData?.physicalProductNumber}
					showProductNumber={true}
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.physical-product-quality-grade" />}
					value={detailData?.physicalProductQualityGrade}
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.physical-product-batch-number" />}
					value={detailData?.physicalProductBatchNumber}
				/>
			</Stack>

			<Stack width="43.25rem">
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.sampling-technique" />} value={detailData?.samplingTechnique} />
				<IrDRMIndicator label={<Tr.Portal path="drm-detail.number-of-scans" />} value={detailData?.numberOfScans} />
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.erm-resolution" />} value={detailData?.resolution} unit="cm⁻¹" />
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-lower-range" />}
					value={detailData?.lastX?.split('.')[0]}
					unit="cm⁻¹"
				/>
				<IrDRMIndicator
					label={<Tr.IrPortal path="drm-detail.erm-upper-range" />}
					value={detailData?.firstX?.split('.')[0]}
					unit="cm⁻¹"
				/>
				<IrDRMIndicator label={<Tr.IrPortal path="drm-detail.reference-code" />} value={detailData?.drmCode} />
			</Stack>
		</Stack>
	);
};
