import { ReferenceStatusCode } from '@components/common/UserReferenceStatus';
import { IUploadedFile } from '@models/file';
import { Stack, Typography } from '@mui/material';
import { getNmrUserReferenceValidationSchema } from '@schemas';
import { nmrDrmService, NmrUserReferenceModel } from '@services/nmr';
import { formFieldSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateRefSaveFields, NmrUserReferenceFormLayout } from '../NmrReferenceLayout';
import { loadingSpinnerOverlayService, notificationService } from '@services/core';
import { PortalPageRoutes } from '@models/router';

type FormFields = Extendable<NmrUserReferenceModel>;

export const NmrEditUserReference = () => {
	const { drmCode } = useParams<{ id: string; drmCode: string; status: string }>();
	const { t } = useTranslation('portal');
	const navigate = useNavigate();

	const [userReferenceData, setUserReferenceData] = useState<NmrUserReferenceModel>();
	const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
	const [fileReUploaded, setFileReUploaded] = useState(false);

	const formik = useFormik<FormFields>({
		initialValues: {
			isEditing: true,
			...userReferenceData,
		},
		validationSchema: getNmrUserReferenceValidationSchema(t, true),
		onSubmit: (values) => {
			const referenceMaterialId = userReferenceData?.id;
			loadingSpinnerOverlayService.increment();
			if (referenceMaterialId) {
				RxUtils.promisify(
					nmrDrmService.updateUserReference({
						...values,
						id: referenceMaterialId,
						status: ReferenceStatusCode.InProgress,
					}),
					() => {
						notificationService.sendSuccess(t('user-references.edit-success', { referenceName: values.name }));
						navigate(
							`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
						);
					},
					() => {
						notificationService.sendError(t('user-references.edit-fail', { referenceName: values.name }));
						navigate(
							`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
						);
					},
					() => loadingSpinnerOverlayService.decrement(),
				);
			}
		},
	});

	useEffect(() => {
		const body = { drmCode: drmCode };
		RxUtils.promisify(nmrDrmService.createUserReference(body), (res) => {
			if (res) {
				RxUtils.promisify(nmrDrmService.fetchUserReference(res), (details) => {
					const newReferenceMaterialDetails = {
						...details,
						batchNumber: details?.physicalProductBatchNumber,
						molecularWeight: details?.molecularWeight ? details?.molecularWeight : undefined,
						numberOfScans: details?.numberOfScans ? details?.numberOfScans : undefined,
						nmrReferenceMaterialLibraryId: details?.nmrReferenceMaterialLibraryId,
					};
					setUserReferenceData(newReferenceMaterialDetails);
					formik.setValues(newReferenceMaterialDetails);
				});
			}
		});
	}, []);

	useEffect(() => {
		formik.validateForm();
	}, [formik.values]);

	function handleUploadFile(data) {
		setUploadedFile(data);
		setFileReUploaded(true);
	}

	return (
		<>
			<Stack data-testid="edit-user-reference" spacing={formFieldSpacer}>
				<form onSubmit={formik.handleSubmit} data-testid="edit-reference-form-test-id">
					<Stack direction="row" alignItems="center" spacing={mainTitleSpacer} justifyContent="space-between">
						<Typography variant="h1" sx={{ color: 'text.secondary' }}>
							<Tr.IrPortal path="user-references.edit-reference" />
						</Typography>
						<CreateRefSaveFields uploadedFile={uploadedFile} formik={formik} editing={true} fileReuploaded={fileReUploaded} />
					</Stack>
					<NmrUserReferenceFormLayout
						formik={formik}
						editing={true}
						referenceFileId={formik?.values?.id}
						uploadedFile={uploadedFile}
						setUploadedFile={handleUploadFile}
					/>
				</form>
			</Stack>
		</>
	);
};
