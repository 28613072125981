import { Button, Grid, Stack, Typography } from '@mui/material';
import styles from './AuthLayout.module.scss';
import { GenericCard } from '../GenericCard';
import { LogoMLetter } from '@components/svg';
import { Footer } from '../Footer';
import { ExtendedComponent } from 'src/types';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { config } from '@config';

export const AuthLayout: ExtendedComponent = ({ children }) => {
	return (
		<Stack
			flexGrow={1}
			justifyContent="space-between"
			className={styles.Login}
			sx={{ backgroundColor: 'primary.main', overflowX: 'hidden', width: 1, height: 1 }}
		>
			<Stack
				sx={{
					marginY: '100px',
					marginX: 'auto',
					flexDirection: 'row',
					flexGrow: 1,
					justifyContent: 'space-between',
					maxWidth: '1440px',
					width: 'calc(100% - 10rem)',
				}}
			>
				<Stack>
					<LogoMLetter color="primary" style={{ width: '140px', marginTop: '10px', fill: '#A5CD50' }} />
					<Typography variant="h1" sx={{ marginTop: '1rem', color: 'secondary.main', fontSize: '3rem', textTransform: 'none' }}>
						ChemisTwin<sup>®</sup>
						<br /> Portal
					</Typography>
				</Stack>
				<Stack direction={'column'}>
					<GenericCard
						dataTestId="auth-data-test-id"
						sx={{
							width: '31rem',
							height: 'fit-content',
							boxShadow: '0px 16px 32px rgba(9, 23, 52, 0.15), 0px 0px 4px rgba(9, 23, 52, 0.15)',
						}}
					>
						{children}
					</GenericCard>
					<Stack sx={{ color: '#fff', mt: 5, p: '0px 32px' }} data-testid="docs-section-id">
						<Typography variant="subtitle1" sx={{ color: 'white' }}>
							Explore our Knowledge Center
						</Typography>
						<Grid container spacing={2} sx={{ mt: 1 }}>
							<Grid item xs={8}>
								<Typography variant="body2" sx={{ color: 'white' }}>
									Dive into our documentation to set up and learn more.
								</Typography>
							</Grid>
							<Grid item xs={4} container justifyContent="flex-end">
								<Button
									variant="outlined"
									endIcon={<ArrowRightIcon />}
									sx={{
										color: '#fff',
										borderColor: '#fff',
										'&:hover': {
											borderColor: '#fff',
										},
									}}
									onClick={() => window.open(config.docsUrl, '_blank')}
								>
									Access
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</Stack>
			<Footer data-testid="auth-footer" login />
		</Stack>
	);
};
