export interface ISolventNMR {
	label: string;
	value: string;
}

export const SolventsListNMR: ISolventNMR[] = [
	{ label: 'DMSO', value: 'DMSO' },
	{ label: 'CD2Cl2', value: 'CD2CL2' },
	{ label: 'CDCl3', value: 'CDCL3' },
	{ label: 'D2O', value: 'D2O' },
	{ label: 'MeOD', value: 'MEOD' },
	{ label: 'CD3CN', value: 'CD3CN' },
	{ label: 'Acetone-d6', value: 'ACETONE-D6' },
	{ label: 'BENZENE-d6', value: 'BENZENE-D6' },
	{ label: 'Ethanol-d6', value: 'ETHANOL-D6' },
	{ label: 'THF-d8', value: 'THF-D8' },
];
