import { FC, ReactNode } from 'react';
import { alpha, Stack, Theme, Tooltip, TooltipProps, Typography, TypographyProps, useMediaQuery, Grid } from '@mui/material';
import { IrCommentTypeEnum, IrCommentType } from '@services';
import { CUSTOM_THEME } from '@utils';
import { styled } from '@mui/material/styles';
import { Tr } from '@utils';
import { InfoOutlined } from '@mui/icons-material';

export const Cell: FC<TypographyProps> = ({ sx, ...props }) => {
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1500));
	return (
		<Typography
			sx={{
				whiteSpace: 'nowrap',
				paddingX: media ? 0.5 : 2,
				paddingY: media ? 1 : 1.5,
				fontWeight: 500,
				textAlign: 'center',
				...sx,
			}}
			{...props}
		/>
	);
};

export const HeaderCell: FC<{ children: ReactNode; createReport?: boolean }> = ({ children, createReport }) => (
	<span
		style={{
			maxWidth: '4rem',
			minWidth: '2rem',
			wordBreak: createReport ? 'unset' : 'keep-all',
			textAlign: 'center',
			fontSize: '14px',
		}}
	>
		{children}
	</span>
);

export const CellTooltip: FC<Omit<TooltipProps, 'title'> & { comments: IrCommentType[]; targetCommentType: IrCommentTypeEnum }> = ({
	children,
	comments,
	targetCommentType,
	...props
}) => {
	const filteredComments = comments?.filter((comment) => comment.type === targetCommentType);

	const commentText = filteredComments?.map((comment) => comment.comment).join(', ');

	return (
		<Tooltip disableHoverListener={!commentText} title={commentText || ''} placement="top" arrow {...props}>
			<Stack
				style={{
					background: commentText ? alpha(CUSTOM_THEME.palette.secondary.main, 0.2) : 'unset',
					height: '2.5rem',
					justifyContent: 'center',
				}}
			>
				{children}
			</Stack>
		</Tooltip>
	);
};

const Item = styled(Stack)(() => ({
	textAlign: 'center',
	border: '1px solid #E1E1EA',
}));

const getItems = (data: number[], roundTo3 = false) => {
	if (data?.length > 1) {
		const array = data.map((item, index) => {
			return <Item key={`item-${index}`}> {item ? (roundTo3 ? item?.toFixed(3) : item) : '---'}</Item>;
		});
		return array;
	}
};

export const renderPeakItensityDataGrid = (
	mainHeading,
	data: number[],
	intensity = true,
	dataTestId: string,
	roundTo3 = false,
	resultType?: number,
) => {
	return (
		<Grid item xs data-testid={`${dataTestId}`}>
			<Grid container alignItems="center">
				<Grid item xs padding={1} textAlign="center" style={{ border: '1px solid #E1E1EA' }} height={50}>
					<Stack direction="row" justifyContent="center">
						{mainHeading?.length > 20 ? (
							<Typography
								title={mainHeading}
								sx={{ textWrap: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '50px' }}
								variant={'subtitle3'}
							>
								{mainHeading?.toString().substring(0, 10)}...
							</Typography>
						) : (
							<Typography sx={{ textWrap: 'nowrap' }} variant={'subtitle3'}>
								{mainHeading}
							</Typography>
						)}
						{resultType === 2 && (
							<Tooltip title={'Not detected in the sample spectrum'} placement="top-start">
								<InfoOutlined sx={{ width: 20, height: 20, color: 'secondary.main', ml: '5px' }} />
							</Tooltip>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<Item>
						<Typography sx={{ height: '40px', fontSize: '14px', margin: '5px', textAlign: 'center' }}>
							{intensity ? (
								<Tr.IrPortal path="analysis-result.intensity" />
							) : (
								<Tr.IrPortal path="analysis-result.peak-position" />
							)}
						</Typography>
					</Item>
					{getItems(data, roundTo3)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export const getTargetAndSampleTableofAssignmentPeaks = (
	reference_peak_intensity: number[],
	sample_peak_intensity: number[],
	targetSubstanceTitle: string,
	sampleTitle: string,
) => {
	if (reference_peak_intensity && sample_peak_intensity) {
		return (
			<>
				{renderPeakItensityDataGrid(targetSubstanceTitle, reference_peak_intensity, true, 'target-peaks', true)}
				{renderPeakItensityDataGrid(sampleTitle, sample_peak_intensity, true, 'sample-peaks', true)}
			</>
		);
	}
};
