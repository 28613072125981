import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tr, innerTitleSpacer } from '@utils';
import { Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { useParams } from 'react-router-dom';

interface NmrReferenceAdditionalDetailsProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

export const NmrReferenceAdditionalDetails: FC<NmrReferenceAdditionalDetailsProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
}) => {
	const { t } = useTranslation('portal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack marginBottom={innerTitleSpacer}>
				<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
					<Tr.Portal path="new-analysis.additional-details" />
				</Typography>
			</Stack>
			<Stack sx={{ fontSize: 12 }}>
				<Typography color={disableRmInActiveFields ? 'grey.400' : '#primary.main'}>
					<Tr.Portal path="analysis-result.comment" />
				</Typography>
				<FormikTextfield
					multiline
					formikApi={formik}
					hiddenLabel
					rows={4}
					placeholder={t('user-references.ref-comment-msg')}
					variant="outlined"
					sx={{
						marginTop: '0.625rem',
						backgroundColor: 'grey.50',
					}}
					inputProps={{ 'data-testid': 'comment-text-id', maxLength: 4000, style: { fontSize: 16 } }}
					name="generalComment"
					disabled={disableRmInActiveFields}
					onChange={() => {
						formik.setFieldTouched('generalComment', true);
					}}
				/>
			</Stack>
		</GenericCard>
	);
};
