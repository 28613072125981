import { FC, ReactNode, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { useIrSettingsSelector } from '@store/slices/ir/ir.slice';

import { userSelector } from '@store/slices/common/common.slice';
import { Tr, sendIrImage } from '@utils';
import { UserRole } from '@models';
import { IrAnalysisReport, sendIrImagePurityQuantification } from '@components/ir-portal';
import { IrAnalysisResultContext } from './Iranalysis-result-context';
import { AnalysisConfirmation } from './IrAnalysisConfirmation';
import { IrResultImpurites, RankBasedMixtureData, RmSpectrumInfoPurity, SampleSpectrumInfo, SampleSpectrumInfoPurity } from '@services/ir';

interface IConfirmResultProps {
	confirmMessage: ReactNode;
	disabled?: boolean;
	selectedRadioButton: number;
	setSelectedRMIndex: (val: number) => void;
}

const ConfirmResult: FC<IConfirmResultProps> = ({ disabled, confirmMessage, selectedRadioButton, setSelectedRMIndex }) => {
	const [confirmVisible, setConfirmationVisibility] = useState(false);
	const [rejectVisible, setRejectionVisibility] = useState(false);

	return (
		<Stack data-testid="confirm-result-id" direction="row" columnGap={1.5}>
			<AnalysisConfirmation
				confirmVisible={confirmVisible}
				setSelectedRMIndex={setSelectedRMIndex}
				rejectVisible={rejectVisible}
				selectedRadioButton={selectedRadioButton}
				setConfirmationVisibility={setConfirmationVisibility}
				setRejectionVisibility={setRejectionVisibility}
			/>
			<Button
				data-testid="reject-id"
				variant="outlined"
				disableElevation
				disabled={disabled}
				sx={{ width: 147, marginLeft: 'auto', padding: '10px 12px' }}
				onClick={() => setRejectionVisibility(true)}
			>
				<Tr.IrPortal path="analysis-result.reject-analysis" />
			</Button>

			<Button
				data-testid="confirm-id"
				variant="contained"
				disabled={disabled}
				disableElevation
				sx={{ width: 147, padding: '10px 12px' }}
				onClick={() => setConfirmationVisibility(true)}
			>
				{confirmMessage}
			</Button>
		</Stack>
	);
};

interface UpdateButtonProps {
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
	selectedRadioButton: number;
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity;
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity;
	resultImpurities?: IrResultImpurites[];
	setSelectedRMIndex: (val: number) => void;
}

export const UpdateButtons: FC<UpdateButtonProps> = ({
	rankedRMData,
	userSpectrumInfo,
	selectedRadioButton,
	userSpectrumInfoPurityCheck,
	rmSpectrumInfoPurityCheck,
	setSelectedRMIndex,
	resultImpurities,
}) => {
	const { analysisResultData, peakData } = useContext(IrAnalysisResultContext);
	const loc = useLocation();
	const isSecondApprovalEnabled = analysisResultData?.irAnalysis?.secondApproval;
	const user = useSelector(userSelector);
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);
	const [openCreateReport, setOpenCreateReport] = useState(loc.search.includes('openReport=true'));
	const impurityPeakData = peakData && peakData['impurities'];

	if (!user) return null;
	const irSettings = useSelector(useIrSettingsSelector);

	useEffect(() => {
		if (openCreateReport && userSpectrumInfo) {
			if (analysisResultData?.isIdentification()) {
				sendIrImage(
					analysisResultData,
					rankedRMData,
					userSpectrumInfo,
					(urlPdf) => {
						setPdfUrl(urlPdf);
					},
					irSettings,
				);
			} else {
				if (analysisResultData) {
					sendIrImagePurityQuantification(
						analysisResultData,
						rankedRMData,
						userSpectrumInfoPurityCheck,
						rmSpectrumInfoPurityCheck,
						(urlPdf) => {
							setPdfUrl(urlPdf);
						},
						irSettings,
						peakData || [],
						impurityPeakData || [],
						resultImpurities,
					);
				}
			}
		}
	}, [openCreateReport, analysisResultData, rankedRMData, userSpectrumInfo, rmSpectrumInfoPurityCheck, userSpectrumInfoPurityCheck]);

	if (isSecondApprovalEnabled ? analysisResultData?.isApprovedByLabAdmin() : analysisResultData?.isConfirmedByLabMember()) {
		return (
			<>
				<Button
					data-testid="create-report-button-id"
					variant="contained"
					onClick={() => setOpenCreateReport(true)}
					disableElevation
					sx={{ width: 124, marginLeft: 'auto', display: 'flex' }}
				>
					<Tr.IrPortal path="analysis-result.create-report" />
				</Button>
				{pdfUrl && (
					<IrAnalysisReport
						data-testid="analysis-report-id"
						open={openCreateReport}
						onClose={() => setOpenCreateReport(false)}
						resultData={analysisResultData || []}
						pdfUrl={pdfUrl}
					/>
				)}
			</>
		);
	}

	if (user?.roles.includes(UserRole.LAB_MEMBER) && analysisResultData?.isExecuted()) {
		return (
			<ConfirmResult
				selectedRadioButton={selectedRadioButton}
				setSelectedRMIndex={setSelectedRMIndex}
				confirmMessage={<Tr.IrPortal path="analysis-result.approve-analysis" />}
			/>
		);
	}

	if (
		!analysisResultData?.isRejected() &&
		!analysisResultData?.isError() &&
		user?.roles.includes(UserRole.LAB_ADMIN) &&
		(analysisResultData?.isExecutedSuccessful() || analysisResultData?.isConfirmedByLabMember())
	)
		return (
			<ConfirmResult
				selectedRadioButton={selectedRadioButton}
				disabled={!analysisResultData.isConfirmedByLabMember() && analysisResultData?.irAnalysis?.operatorId !== user.username}
				confirmMessage={<Tr.IrPortal path="analysis-result.approve-analysis" />}
				setSelectedRMIndex={setSelectedRMIndex}
			/>
		);

	return null;
};
