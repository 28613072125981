import { Hub } from 'aws-amplify/utils';
import {
	confirmResetPassword,
	confirmSignIn,
	fetchAuthSession,
	getCurrentUser,
	resetPassword,
	setUpTOTP,
	signIn,
	signInWithRedirect,
	signOut,
	updatePassword,
	updateUserAttributes,
	verifyTOTPSetup,
	FetchAuthSessionOptions,
	updateMFAPreference,
	fetchMFAPreference,
} from '@aws-amplify/auth';
import { RxUtils, TypeUtils } from '@utils';
import { ILoginUserModel, UserRole, UserStoreModel } from '@models';
import { getStore } from '@store';
import { setUser } from '@store/slices/common/common.slice';
import { loadingSpinnerOverlayService } from '../loading-spinner-overlay';
import { userService } from '../user';

class AuthService {
	private store = getStore().store;
	constructor() {
		Hub.listen('auth', async (eventData) => {
			const { event } = eventData.payload;
			if (event === 'signedOut') {
				this.store.dispatch(setUser(null));
			} else if (event === 'signInWithRedirect') {
				const idToken = await this.getIdToken();

				if (idToken) {
					const userModel = TypeUtils.transform(UserStoreModel, {
						username: idToken.payload['cognito:username'],
						name: idToken.payload.given_name,
						surname: idToken.payload.family_name,
						email: idToken.payload.email,
						email_verified: idToken.payload.email_verified,
						roles: ((idToken.payload.role ?? idToken.payload['custom:Role']) as string)?.split(',') as UserRole[],
						exp: idToken.payload.exp,
						organizationId: idToken.payload.organizationName,
						mfaMethods: idToken.payload['custom:MFAMethods'],
						currentLabId: (idToken.payload['custom:Labs'] as string)?.split(',')[0],
					});
					this.store.dispatch(setUser(userModel));
				} else {
					this.store.dispatch(setUser(null));
				}
			}
		});
	}

	public async newLogin(username: string, password: string) {
		const signInData = await signIn({ username, password });
		return signInData;
	}

	public async resetPassword(oldPassword: string, newPassword: string) {
		return updatePassword({ oldPassword, newPassword });
	}

	public async completeUserPassword({ firstName, lastName, newPassword, phoneNumber }: ILoginUserModel) {
		return confirmSignIn({
			challengeResponse: newPassword ?? '',
			options: {
				userAttributes: {
					given_name: firstName,
					family_name: lastName,
					phone_number: phoneNumber,
				},
			},
		});
	}

	public async resendCode(username: string, password: string) {
		return signIn({ username, password }); //TODO: when triggered it, it will resend confirmation code, https://github.com/aws-amplify/amplify-js/issues/6676
	}

	public async forgotPassword(username: string) {
		return resetPassword({ username });
	}

	public async submitNewPassword(email: string, code: string, newPassword: string) {
		return confirmResetPassword({
			username: email,
			newPassword: newPassword,
			confirmationCode: code,
		});
	}

	public confirmSignUp(code: string) {
		return confirmSignIn({ challengeResponse: code });
	}

	public async setUpTOTP() {
		return setUpTOTP();
	}

	public async verifyTOTPCode(code: string) {
		return verifyTOTPSetup({ code });
	}

	public async updateUserAttributes(attributes: Record<string, string>) {
		return updateUserAttributes({ userAttributes: attributes });
	}

	public async updateMFAPreference(mfaPreferenceData) {
		const response = updateMFAPreference(mfaPreferenceData);
		return response;
	}

	public async fetchMFAPreference() {
		const response = fetchMFAPreference();
		return response;
	}

	public async login(disableLoader = false) {
		!disableLoader && loadingSpinnerOverlayService.increment();
		return signInWithRedirect()
			.then((resp) => {
				!disableLoader && loadingSpinnerOverlayService.decrement();
				return resp;
			})
			.catch((e) => {
				!disableLoader && loadingSpinnerOverlayService.decrement();
				throw e;
			});
	}

	public async logout(disableLoader = false) {
		!disableLoader && loadingSpinnerOverlayService.increment();
		return signOut()
			.then((resp) => {
				!disableLoader && loadingSpinnerOverlayService.decrement();
				this.store.dispatch(setUser(null));
				return resp;
			})
			.catch((e) => {
				!disableLoader && loadingSpinnerOverlayService.decrement();
				throw e;
			});
	}

	public async getUser(disableLoader = false, fetchAuthSessionOptions?: FetchAuthSessionOptions) {
		try {
			!disableLoader && loadingSpinnerOverlayService.increment();
			const resp = await fetchAuthSession(fetchAuthSessionOptions);
			const currentUser = this.store.getState().Common?.user;
			const idTokenPayload = resp.tokens?.idToken?.payload;
			if (!idTokenPayload) {
				throw new Error('Forbidden');
			}
			const currentLabId = currentUser?.currentLabId ?? (idTokenPayload?.['custom:Labs'] as string)?.split(',')[0];

			const userModel = TypeUtils.transform(UserStoreModel, {
				username: idTokenPayload?.['cognito:username'],
				name: idTokenPayload?.given_name,
				surname: idTokenPayload?.family_name,
				email: idTokenPayload?.email,
				email_verified: idTokenPayload?.email_verified,
				roles: ((idTokenPayload?.role ?? idTokenPayload?.['custom:Role']) as string)?.split(',') as UserRole[],
				exp: idTokenPayload?.exp,
				organizationId: idTokenPayload?.organizationName,
				mfaMethods: idTokenPayload?.['custom:MFAMethods'],
				laboratories: currentUser?.laboratories,
				permissions: (await RxUtils.promisify(userService.getCurrentUserPermissions(currentLabId))) as string[],
				currentLabId,
			});
			if (userModel?.roles) {
				this.store.dispatch(setUser(userModel));
			}
			!disableLoader && loadingSpinnerOverlayService.decrement();
			return userModel;
		} catch (e) {
			!disableLoader && loadingSpinnerOverlayService.decrement();
			throw e;
		}
	}

	public async getIdToken() {
		return (await fetchAuthSession()).tokens?.idToken;
	}

	public async authenticatedUser() {
		getCurrentUser();
	}

	public async refreshToken(cb?: () => void) {
		await this.getUser(false, { forceRefresh: true }).then(cb);
	}

	public async signOut() {
		return signOut();
	}
}

export const authService = new AuthService();
