export enum NotificationType {
	ReachedTheCountLimitOfAnalysis = 'ReachedTheCountLimitOfAnalysis',
	ReachedNinetyPercentOfAnalysis = 'ReachedNinetyPercentOfAnalysis',
	ReachedNinetyFivePercentOfAnalysis = 'ReachedNinetyFivePercentOfAnalysis',
	SubscriptionOneMonthRemained = 'SubscriptionOneMonthRemained',
	SubscriptionOneWeekRemained = 'SubscriptionOneWeekRemained',
	SubscriptionOneDayRemained = 'SubscriptionOneDayRemained',
	SubscriptionExpired = 'SubscriptionExpired',
	NewErmRequest = 'NewErmRequest',
	NewErmAvailable = 'NewErmAvailable',
	RequestedErmAvailable = 'RequestedErmAvailable',
	DeviceCalibrationCheckNeeded = 'DeviceCalibrationCheckNeeded',
	DeviceCalibrationExpiringInOneWeek = 'DeviceCalibrationExpiringInOneWeek',
	DeviceCalibrationExpiringInOneDay = 'DeviceCalibrationExpiringInOneDay',
	AccessoryCalibrationCheckNeeded = 'AccessoryCalibrationCheckNeeded',
	AccessoryCalibrationExpiringInOneWeek = 'AccessoryCalibrationExpiringInOneWeek',
	AccessoryCalibrationExpiringInOneDay = 'AccessoryCalibrationExpiringInOneDay',
	NewDeviceAvailable = 'NewDeviceAvailable',
	NewDeviceCalibrated = 'NewDeviceCalibrated',
	ApprovalRequiredMoreThan7Days = 'ApprovalRequiredMoreThan7Days',
	ApprovalRequired = 'ApprovalRequired',
	AnalysisApproved = 'AnalysisApproved',
	AnalysisRejected = 'AnalysisRejected',
	AnalysisExecutionCompleted = 'AnalysisExecutionCompleted',
	SubscriptionExpiredForMerckAdmin = 'SubscriptionExpiredForMerckAdmin',
	SubscriptionOneMonthRemainedForMerckAdmin = 'SubscriptionOneMonthRemainedForMerckAdmin',
	SubscriptionOneWeekRemainedForMerckAdmin = 'SubscriptionOneWeekRemainedForMerckAdmin',
	SubscriptionOneDayRemainedForMerckAdmin = 'SubscriptionOneDayRemainedForMerckAdmin',
	NewDrmRequestCreatedWithActionRequired = 'NewDrmRequestCreatedWithActionRequired',
	NewDrmRequestCreatedWithActionRequiredMoreThan7Days = 'NewDrmRequestCreatedWithActionRequiredMoreThan7Days',
	IrWaveFileUploadFailed = 'IrWaveFileUploadFailed',
	IrWaveFileUploadSuccess = 'IrWaveFileUploadSuccess',
	IrWaveReleaseFailed = 'IrWaveReleaseFailed',
	IrWaveReleaseSuccess = 'IrWaveReleaseSuccess',
	NmrDrmOrderDeclined = 'NmrDrmOrderDeclined',
	NmrDrmOrderRejected = 'NmrDrmOrderRejected',
	NmrDrmOrderBulkUploadCompleted = 'NmrDrmOrderBulkUploadCompleted',
	IrCustomRMCreateProcessSucceded = 'IrCustomRMCreateProcessSucceded',
	IrCustomRMCreateProcessFailed = 'IrCustomRMCreateProcessFailed',
	IrCustomRMUpdateProcessFailed = 'IrCustomRMUpdateProcessFailed',
	IrCustomRMUpdateProcessSucceded = 'IrCustomRMUpdateProcessSucceded',
	IrCustomRMReactivateProcessFailed = 'IrCustomRMReactivateProcessFailed',
	IrCustomRMReactivateProcessSucceded = 'IrCustomRMReactivateProcessSucceded',
	IrCustomRMDeactivateProcessFailed = 'IrCustomRMDeactivateProcessFailed',
	IrCustomRMDeactivateProcessSucceded = 'IrCustomRMDeactivateProcessSucceded',
	NmrUserReferenceMaterialUpdateProcessSucceeded = 'NmrUserReferenceMaterialUpdateProcessSucceeded',
	NmrUserReferenceMaterialUpdateProcessFailed = 'NmrUserReferenceMaterialUpdateProcessFailed',
}
