import { DataTableBackend } from '@components/common';
import { getParams, useFilterSearchParams } from '@hooks';
import { useService } from '@hooks';
import { FilterKey } from '@models/filter';
import { PageRoutes } from '@models/router';
import { SortOrder } from '@models/sorting';
import { ExtendedColumn } from '@models/table';
import { Link, Stack, Typography, TypographyProps } from '@mui/material';
import { NmrDrmOrder, NmrDrmOrderStatus, nmrDrmOrderService } from '@services/nmr-drm-pipeline';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDeclineReasonFilter } from '../NmrPipelineBacklog/nmr-pipeline-helper';
import { TableButton } from '@components/common/TableButton';

const DEFAULT_SORT_COLUMN = 'lastStatusTransitionDate';

const Body1: FC<TypographyProps> = ({ sx, ...props }) => <Typography variant="pg-s" sx={{ fontWeight: 400, ...sx }} {...props} />;

export const NmrPipelineDeclined: FC = () => {
	const { t: translate } = useTranslation('admin');

	const { searchParams, filtering } = useFilterSearchParams(DEFAULT_SORT_COLUMN, SortOrder.DESC);
	const [searchTextfieldValue, setSearchTextfieldValue] = useState('');
	const filterData = getDeclineReasonFilter(translate);

	const { data: nmrPipelineData } = useService(() => {
		setSearchTextfieldValue(getParams(searchParams).getQuery());
		searchParams.set(FilterKey.STATUS, String(NmrDrmOrderStatus.DECLINED));

		return nmrDrmOrderService.getAllWithSearchParams(searchParams);
	}, [searchParams]);

	const columns = useMemo<ExtendedColumn<NmrDrmOrder>[]>(
		() => [
			{
				accessor: 'physicalProductNumber',
				disableSortBy: true,
				Header: (
					<Stack sx={{ marginLeft: 2 }}>
						<Tr.Admin path="drm-pipeline.nmr.declined.physical-product-number" />
					</Stack>
				),
				Cell: ({ value }) => (
					<Body1 sx={{ paddingLeft: 2 }}>
						{value ? (
							<Link
								underline="hover"
								variant="body1"
								href={`https://www.sigmaaldrich.com/US/en/product/sial/${value}`}
								sx={{ cursor: 'pointer' }}
								target="_blank"
							>
								{value}
							</Link>
						) : (
							'-'
						)}
					</Body1>
				),
			},
			{
				accessor: 'casNumber',
				disableSortBy: true,
				Header: <Tr.Admin path="drm-pipeline.nmr.declined.cas-number" />,
				Cell: ({ value }) => <Body1>{value}</Body1>,
			},
			{
				accessor: 'lastStatusTransitionDate',
				Header: <Tr.Admin path="drm-pipeline.nmr.declined.declined-date" />,
				Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy') : '-'}</Body1>,
			},
			{
				accessor: 'wave',
				Header: <Tr.Admin path="drm-pipeline.nmr.declined.wave" />,
				Cell: ({ value }) => <Body1>{value?.name || '-'}</Body1>,
			},
			{
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ row }) => (
					<Stack sx={{ float: 'right', paddingX: 2 }}>
						<TableButton.Outlined
							href={`/admin/${PageRoutes.DRM_PIPELINE}/${PageRoutes.DRM_ORDER_DETAILS}/${row.original.id}`}
							data-testid={`declined-order-detail-button-${row.index}`}
							size="small"
						>
							<Tr.Admin path="drm-pipeline.nmr.declined.view" />
						</TableButton.Outlined>
					</Stack>
				),
			},
		],
		[],
	);
	return (
		<Stack direction="column" overflow="hidden">
			<DataTableBackend
				data={nmrPipelineData?.data || []}
				columns={columns}
				filterData={filterData}
				filteringSelections={filtering}
				sorting={{
					sortBy: getParams(searchParams).getSortBy(),
					sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
				}}
				pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: nmrPipelineData?.totalPages ?? 1 }}
				searchText={searchTextfieldValue}
				searchPlaceholder={translate('drm-pipeline.nmr.declined.search-placeholder')}
				onSearchTextChange={setSearchTextfieldValue}
				searchFieldProps={{ sx: { width: (500 / 1440) * window.screen.width, borderColor: 'grey.200', borderWidth: '1.5px' } }}
			/>
		</Stack>
	);
};
