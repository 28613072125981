import { FC, useMemo } from 'react';
import { LinearProgress, Stack, StackProps, Typography } from '@mui/material';
import { Currency } from '@models/subscribe';
import { useTranslation } from 'react-i18next';
import { TechnologyConsumptionChip } from '../TechnologyConsumptionChip/TechnologyConsumptionChip';

export interface BudgetConsumptionDetails {
	consumed: number;
	name: string;
	color?: string;
	price?: number;
}

interface ISubscriptionLinearProgressProps extends StackProps {
	title?: string;
	currency?: Currency;
	total: number;
	budgetConsumptionDetails: BudgetConsumptionDetails[];
}

const NORMALIZE_CONSUMED_MULTIPLIER = 100;
export const SubscriptionLinearProgress: FC<ISubscriptionLinearProgressProps> = ({
	budgetConsumptionDetails,
	total,
	currency,
	title,
	sx,
	...props
}) => {
	const { t } = useTranslation('common');
	const totalConsumption = budgetConsumptionDetails.reduce((accumulator: number, details) => accumulator + details.consumed, 0);
	const totalConsumptionAmount = (totalConsumption * NORMALIZE_CONSUMED_MULTIPLIER) / total;

	const remainingAnalysis = budgetConsumptionDetails
		.map((detail) => {
			const price = detail?.price ?? 1;
			const analysisValue = (total - totalConsumption) / price;
			const formattedAnalysis = analysisValue % 1 === 0 ? analysisValue : analysisValue.toFixed(2);
			return `${formattedAnalysis} Analysis (${detail.name})`;
		})
		.join(' or ');

	const handleConsumptionColors = () => {
		const consumptionColorArray = [
			{ color: '#FF0000', start: 0, end: Math.round(NORMALIZE_CONSUMED_MULTIPLIER - totalConsumptionAmount) },
		];
		budgetConsumptionDetails.forEach((consumption) =>
			consumptionColorArray.push({
				color: consumption.color ?? 'primary.main',
				start: consumptionColorArray[consumptionColorArray.length - 1].end,
				end: Math.round(
					Math.min(
						consumptionColorArray[consumptionColorArray.length - 1].end +
							(consumption.consumed * NORMALIZE_CONSUMED_MULTIPLIER) / total,
						NORMALIZE_CONSUMED_MULTIPLIER,
					),
				),
			}),
		);

		return (
			'linear-gradient(to right,' +
			consumptionColorArray.map(
				(consumption) => `${consumption.color} ${consumption.start}%, ${consumption.color} ${consumption.end}%`,
			) +
			')'
		);
	};

	const memoizedColor = useMemo<string>(handleConsumptionColors, [budgetConsumptionDetails]);
	return (
		<Stack {...props} height="100%" data-testid="subscription-linear-progress-wrapper">
			<Stack spacing={1} width="100%">
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="label-s" color="grey.800">
						{title}
					</Typography>
					<Typography variant="label-xs" color="grey.500">
						{`${totalConsumption}/${total} ${currency ?? ''}`}
					</Typography>
				</Stack>

				<Stack>
					<LinearProgress
						variant="determinate"
						value={totalConsumptionAmount}
						sx={{
							...sx,
							height: '8px',
							borderRadius: '4px',
							'&.MuiLinearProgress-bar': {},
							'&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
								backgroundColor: 'grey.200',
							},
							'& .MuiLinearProgress-colorPrimary': {
								backgroundColor: 'grey.200',
							},
							'& .MuiLinearProgress-barColorPrimary': {
								backgroundImage: memoizedColor,
							},
						}}
					/>
					{/*  */}
				</Stack>
				<Stack direction="row" spacing={1} paddingTop={0.5}>
					{budgetConsumptionDetails.map((technologyConsumption) => (
						<TechnologyConsumptionChip
							key={`${technologyConsumption.name}-chip`}
							content={technologyConsumption}
							currency={currency}
							technologyFieldPrice={technologyConsumption.price}
						/>
					))}
					<TechnologyConsumptionChip
						content={{
							consumed: total - totalConsumption,
							name: t('subscription.remaining'),
							color: 'grey.200',
						}}
						currency={currency}
						remainingAnalysis={remainingAnalysis}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};
