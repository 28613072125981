import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Radio,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Alert,
} from '@mui/material';
import {
	IrAnalysisResult,
	IrAnalysisResultMatchType,
	IrMatchCategory,
	IrReferenceMaterialResult,
	IrResultImpurites,
	RankBasedMixtureData,
} from '@services';
import { Tr } from '@utils/Translation';
import { irGetValueColor, StarIconIndicator, TableLegend } from './TableLegend';
import { WarningIcon } from '@components/icons';
import { IrAnalysisResultContext } from '@routes/Portal/ir/IrAnalysisResult';
import { IrAnalysisMatchedDescription } from './IrAnalysisMatchedDescription';
import { IrNoMatchTable } from './IrNoMatchTable';
import React from 'react';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { IrAnalysisType } from '@models';
import { IrImpurites } from './IrImpurities';
import { QualityGradeChip } from '@components/common';

export const getWarningMessage = (matchedResults: IrReferenceMaterialResult[]) => {
	const hCount = matchedResults.some((item) => item.isQuestionableHCount);
	const maxDa = matchedResults.some((item) => item.isQuestionableMaxDa);
	const nmrPurity = matchedResults.some((item) => item.isQuestionableNmrPurity);
	// TO DO: DO NOT USE NESTED IFS
	if (hCount) {
		if (maxDa) {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-h-count-max-d-a-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-h-count-max-d-a';
			}
		} else {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-h-count-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-h-count';
			}
		}
	} else {
		if (maxDa) {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-max-d-a-nmr-purity';
			} else {
				return 'analysis-result-table.warning-message-max-d-a';
			}
		} else {
			if (nmrPurity) {
				return 'analysis-result-table.warning-message-nmr-purity';
			}
		}
		return '';
	}
};
export const ExceptionIndicator: FC = (includeMarginTop = false) => (
	<Box
		sx={{
			maxWidth: '1rem',
			maxHeight: '1rem',
			minWidth: '1rem',
			minHeight: '1rem',
			borderRadius: '50%',
			backgroundColor: 'warning.main',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			marginRight: '0.4rem',
			transform: 'rotate(180deg)',
			marginTop: includeMarginTop ? '5px' : '0px',
		}}
	>
		<WarningIcon sx={{ ' path': { fill: '#0F1A2E' }, fontSize: '10px' }} />
	</Box>
);

interface IrAnalysisResultTableProps {
	rankedRMData: RankBasedMixtureData[];
	selectedRMIndex: number;
	onChangeMatchTable: (index: number) => void;
	resultImpurities?: IrResultImpurites[];
}

// TODO: refactor this business logic.
export const GetMatchCategory = (matchFac: number, type: any) => {
	const matchFactorVal = Math.round(matchFac <= 1 ? matchFac * 100 : matchFac);
	if (type === IrAnalysisType.QUANTIFICATION) {
		if (matchFactorVal > 92 && matchFactorVal <= 107) {
			return IrMatchCategory.SATISFACTORY;
		} else if ((matchFactorVal > 74 && matchFactorVal <= 92) || (matchFactorVal >= 108 && matchFactorVal <= 125)) {
			return IrMatchCategory.QUESTIONABLE;
		} else {
			return IrMatchCategory.UNSATISFATORY;
		}
	} else {
		if (matchFactorVal > 92) {
			return IrMatchCategory.SATISFACTORY;
		}
		if (matchFactorVal > 74 && matchFactorVal <= 92) {
			return IrMatchCategory.QUESTIONABLE;
		}
		if (matchFactorVal > 50 && matchFactorVal <= 74) {
			return IrMatchCategory.UNSATISFATORY;
		}
		return IrMatchCategory.UNSATISFATORY;
	}
};

export const IrAnalysisResultTable: FC<IrAnalysisResultTableProps> = ({
	rankedRMData,
	onChangeMatchTable,
	resultImpurities,
	selectedRMIndex,
}) => {
	const { analysisResultData } = useContext(IrAnalysisResultContext);
	if (!analysisResultData) return null;
	const { resultMatches: matchedResult } = analysisResultData;
	const { type } = analysisResultData.irAnalysis;
	const { integrationMessage } = analysisResultData;
	const [selectedRadio, setSelectedRadio] = useState<number>(0);
	const [isSimilarSpectrum, setIsSimilarSpectrum] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);
	const [initialRowsExpansion, setInitialRowsExpansion] = useState(true);
	const [allRowsCollapsed, setAllRowsCollapsed] = useState(false);

	const previousSelectedRMIndex = useRef<number | null>(null);

	useEffect(() => {
		onChangeMatchTable(selectedRadio);
		const selectedSubstance = analysisResultData?.resultMatches[`${selectedRadio}`] || [];
		const irAnalysisResultMix = selectedSubstance['irAnalysisResultMixtures'] || [];
		setIsSimilarSpectrum(false);
		if (irAnalysisResultMix?.length > 0) {
			const isSimilarSpectra = irAnalysisResultMix?.some((i) => i.isSimilarSpectra);
			setIsSimilarSpectrum(isSimilarSpectra);
		}
	}, [selectedRadio]);

	function renderMatchFactorCell(row) {
		const hqiColor = irGetValueColor(GetMatchCategory(row.matchFactor, type));
		return (
			<Table>
				<TableBody>
					<TableRow>
						<TableCell width={'20%'} style={{ borderBottom: 'none', padding: '0' }}>
							{hqiColor && (
								<div
									style={{
										maxWidth: '5rem',
										minWidth: '5rem',
									}}
								>
									<StarIconIndicator color={hqiColor} sx={{ minWidth: 'fit-content' }} />
								</div>
							)}
						</TableCell>
						<TableCell style={{ borderBottom: 'none', padding: '0' }} width={'80%'}>
							<Typography variant="pg-m" color={hqiColor === 'success' ? 'success.main' : 'inherit'}>
								{`${Math.round(row.matchFactor <= 1 ? row.matchFactor * 100 : row.matchFactor)}%  `}
							</Typography>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		);
	}

	function Row(props: {
		row: any;
		index: number;
		tableselectedRadio: number;
		tablesetSelectedRadio: Function;
		analysisResult: IrAnalysisResult;
		rowsToExpand: string[];
		setRowsToExpand: Function;
	}) {
		const { row, index = 0, tableselectedRadio, tablesetSelectedRadio, analysisResult, rowsToExpand, setRowsToExpand } = props;
		const [open, setOpen] = useState(false);

		useEffect(() => {
			// If analayis is co	nfirmed, Preselected Radio button row expands on initial page load
			if (analysisResult.isConfirmedByLabMember() && row.selectedResult && index > 0 && initialRowsExpansion) {
				setRowsToExpand(() => addIndexToArray(rowsToExpand, index));
			}
			if (selectedRMIndex !== previousSelectedRMIndex.current || !allRowsCollapsed) {
				if (
					previousSelectedRMIndex.current !== 0 ||
					selectedRMIndex !== 0 ||
					(previousSelectedRMIndex.current === 0 && selectedRMIndex === 0 && !initialRowsExpansion)
				) {
					setRowsToExpand(() => addIndexToArray(rowsToExpand, selectedRMIndex));
					// Update previousSelectedRMIndex to the current value
					previousSelectedRMIndex.current = selectedRMIndex;
					if (previousSelectedRMIndex.current !== 0 && selectedRMIndex !== 0) {
						setInitialRowsExpansion(false);
					}
				}
			}
			if (rowsToExpand.length > 0) {
				setOpen(rowsToExpand.includes(index.toString()));
				// Expand first Row by default on inital page load
			} else if (initialRowsExpansion) {
				setOpen(index === 0);
			}
		}, [row]);
		const addIndexToArray = (array, item) => {
			const stringIndex = item.toString();
			if (!array.includes(stringIndex)) {
				return [...array, stringIndex];
			}
			return array;
		};
		const removeIndexFromRowsToExpand = (baseRow, indexToRemove) => {
			return baseRow.filter((item) => item !== indexToRemove.toString());
		};
		return (
			<React.Fragment>
				{/* TO DO: AVOID LONG TERNARY OPERATIONS */}
				{analysisResult.irAnalysis.includeMixture ? (
					<>
						<TableRow>
							<TableCell component="td" scope="row" width={'25%'}>
								<Table style={{ marginLeft: '20px' }}>
									<TableRow>
										<TableCell width={'7%'} style={{ borderBottom: 'none' }}>
											<IconButton
												aria-label="expand row"
												disabled={row?.mixtures?.length >= 1 ? false : true}
												size="small"
												color="primary"
												onClick={() => {
													if (open) {
														const updatedExpandedRows = removeIndexFromRowsToExpand(expandedRows, index);
														if (updatedExpandedRows.length === 0 || index === selectedRMIndex) {
															setAllRowsCollapsed(true);
														}
														setRowsToExpand(updatedExpandedRows);
													} else {
														setRowsToExpand(() => addIndexToArray(rowsToExpand, index));
														setAllRowsCollapsed(false);
													}
													setInitialRowsExpansion(false);
												}}
											>
												{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
											</IconButton>
										</TableCell>
										<TableCell width="93%" style={{ borderBottom: 'none' }}>{`Mixture ${row.rank}`}</TableCell>
									</TableRow>
								</Table>
							</TableCell>
							<TableCell width={'15%'}>{'-'}</TableCell>
							<TableCell width={'10%'}>{'-'}</TableCell>
							<TableCell width={'15%'}>{'-'}</TableCell>
							<TableCell width={'15%'}>{renderMatchFactorCell(row)}</TableCell>
							<TableCell width={'10%'}> {matchedResult[`${index}`]?.weights}</TableCell>
							{!analysisResult.isCompleted() && analysisResult.isConfirmedByLabMember() && (
								<TableCell width={'10%'} align="center" style={{ textAlign: 'left', paddingLeft: '1.5rem' }}>
									<Radio value={index} disabled={!row.selectedResult} checked={row.selectedResult} />
								</TableCell>
							)}

							{!analysisResult.isCompleted() && !analysisResult.isConfirmedByLabMember() ? (
								<TableCell width={'10%'} align="center">
									<div style={{ marginRight: '20px' }}>
										<Radio
											onChange={(e) => {
												tablesetSelectedRadio(parseInt(e.target.value));
												setRowsToExpand(() => addIndexToArray(rowsToExpand, index));
											}}
											value={index}
											checked={tableselectedRadio === index}
										/>
									</div>
								</TableCell>
							) : (
								<TableCell width={'10%'}></TableCell>
							)}
						</TableRow>
						<TableRow>
							<TableCell colSpan={7}>
								<Collapse in={open} timeout="auto" unmountOnExit>
									<Box>
										<Table
											aria-label="mixtures"
											sx={{
												'& .MuiTableBody-root 	.MuiTableRow-root': {
													'&:last-child': {
														'& .MuiTableCell-root': {
															borderBottom: 'none',
															padding: 0,
														},
													},
												},
											}}
										>
											<TableBody>
												{row.mixtures.map((item, i) => (
													<TableRow key={`mixture${i}`} sx={{ height: '2.5rem' }}>
														<TableCell width={'25%'}>
															<Table>
																<TableBody>
																	<TableRow>
																		<TableCell
																			width={'7%'}
																			style={{ borderBottom: 'none', paddingLeft: '' }}
																		></TableCell>
																		<TableCell
																			width={'93%'}
																			style={{ borderBottom: 'none', paddingLeft: '25px' }}
																		>
																			<div style={{ display: 'flex' }}>
																				<span style={{ marginTop: '-3px' }}>
																					{item?.isSimilarSpectra && <ExceptionIndicator />}
																				</span>
																				<span>{item.substanceName}</span>
																			</div>
																		</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableCell>

														<TableCell width={'15%'}>{item.casNumber || '-'}</TableCell>
														<TableCell width={'10%'}>
															<QualityGradeChip isReferenceActive={true} qualityGrade={item?.qualityGrade} />
														</TableCell>
														<TableCell width={'15%'}>{item.library}</TableCell>
														<TableCell width={'15%'} component="td" scope="row">
															<Table>
																<TableBody>
																	<TableRow>
																		<TableCell width={'20%'} style={{ padding: 0 }}>
																			<div
																				style={{
																					maxWidth: '5rem',
																					minWidth: '5rem',
																				}}
																			></div>
																		</TableCell>
																		<TableCell width={'80%'} style={{ padding: 0 }}>{`${Math.round(
																			item.hqi <= 1 ? item.hqi * 100 : item.hqi,
																		)}%  `}</TableCell>
																	</TableRow>
																</TableBody>
															</Table>
														</TableCell>
														<TableCell width={'10%'}>{item.weights}</TableCell>
														<TableCell width={'10%'}></TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								</Collapse>
							</TableCell>
						</TableRow>
					</>
				) : (
					<>
						<TableRow>
							<TableCell style={{ padding: 0 }} colSpan={7}>
								<Box>
									<Table
										size="small"
										aria-label="without-mixtures"
										sx={{
											'& .MuiTableBody-root 	.MuiTableRow-root': {
												'&:last-child': {
													'& .MuiTableCell-root': {
														borderBottom: 'none',
														padding: 0,
													},
												},
											},
										}}
									>
										<TableBody>
											{row.mixtures.map((item, i) => (
												<TableRow key={`without-mixtures-${i}`} sx={{ height: '2.5rem' }}>
													<TableCell width={'25%'}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell width={'7%'}></TableCell>
																	<TableCell width={'93%'}>
																		<div style={{ display: 'flex' }}>
																			<span style={{ marginTop: '-3px' }}>
																				{item?.isSimilarSpectra && <ExceptionIndicator />}
																			</span>
																			<span>{item.substanceName}</span>
																		</div>
																	</TableCell>
																</TableRow>
															</TableBody>
														</Table>
													</TableCell>
													<TableCell width={'15%'}>{item.casNumber || '-'}</TableCell>
													<TableCell width={'10%'}>
														<QualityGradeChip isReferenceActive={true} qualityGrade={item?.qualityGrade} />
													</TableCell>
													<TableCell width={'15%'}>{item.library}</TableCell>
													<TableCell component="td" scope="row" width={'15%'}>
														{renderMatchFactorCell(row)}
													</TableCell>
													<TableCell width={'10%'}>{item.weights}</TableCell>
													<TableCell
														width={'10%'}
														align="center"
														style={{ textAlign: 'left', paddingLeft: '1.5rem' }}
													>
														{!analysisResult.isCompleted() && analysisResult.isConfirmedByLabMember() && (
															<Radio
																value={index}
																disabled={!row.selectedResult}
																checked={row.selectedResult}
															/>
														)}
														{!analysisResult.isCompleted() && !analysisResult.isConfirmedByLabMember() && (
															<Radio
																onChange={(e) => {
																	tablesetSelectedRadio(parseInt(e.target.value));
																}}
																value={index}
																checked={tableselectedRadio === index}
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Box>
							</TableCell>
						</TableRow>
					</>
				)}
			</React.Fragment>
		);
	}

	function renderRow(i, row) {
		return (
			<TableRow key={`row-${i}`}>
				<TableCell width={'20%'}>
					<Stack direction="row" alignItems="center">
						{row?.isSimilarSpectra && (
							<div>
								<ExceptionIndicator />
							</div>
						)}
						{row?.substanceName}
					</Stack>
				</TableCell>
				<TableCell width={'15%'}>{row?.casNumber || '-'}</TableCell>
				<TableCell width={'15%'}>
					<QualityGradeChip isReferenceActive={true} qualityGrade={row?.qualityGrade} />
				</TableCell>
				<TableCell width={'15%'}>{row?.library}</TableCell>

				<TableCell width={'20%'} component="th" scope="row">
					{renderMatchFactorCell(row)}
				</TableCell>
				<TableCell width={'15%'}></TableCell>
			</TableRow>
		);
	}

	// TO DO: NO ANY ALLOWED
	function PurityCheckRow(props: { row: any; Index: number }) {
		const { row } = props;
		return (
			<React.Fragment>
				{row?.mixtures?.length >= 1 && (
					<TableCell style={{ padding: 0 }} colSpan={8}>
						<Box>
							<Table
								size="small"
								aria-label="purityCheck"
								sx={{
									'& .MuiTableBody-root 	.MuiTableRow-root': {
										'& .MuiTableCell-root': {
											padding: '6px 8px',
										},
									},
								}}
							>
								<TableBody>{row.mixtures.map((item, i) => renderRow(i, item))}</TableBody>
							</Table>
						</Box>
					</TableCell>
				)}
			</React.Fragment>
		);
	}
	return (
		<Stack direction="column">
			<IrAnalysisMatchedDescription analysisResult={analysisResultData} />
			{rankedRMData.length > 0 && (
				<Stack direction="column">
					<Box
						sx={{
							borderRadius: '10px',
							border: '1px solid',
							borderColor: 'grey.200',
							height: 'fit-content',
						}}
					>
						<TableContainer component={Paper}>
							{type === IrAnalysisType.IDENTIFICATION ? (
								<Table
									aria-label="identification	"
									sx={{
										'& .MuiTableBody-root 	.MuiTableRow-root': {
											'& .MuiTableCell-root': {
												padding: '0px',
											},
										},
									}}
								>
									<TableHead>
										<TableRow
											sx={{
												'& .MuiTableCell-head': {
													padding: '8px 0px',
												},
											}}
										>
											<TableCell width={'25%'}>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell width={'7%'} style={{ borderBottom: 'none' }}></TableCell>
															<TableCell width={'93%'} style={{ borderBottom: 'none' }}>
																<Tr.IrPortal path="analysis-result.substance-name" />
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableCell>

											<TableCell width={'15%'}>
												<Tr.IrPortal path="analysis-result.cas-number" />
											</TableCell>
											<TableCell width={'10%'}>
												<Tr.IrPortal path="analysis-result.quality-grade" />
											</TableCell>
											<TableCell width={'15%'}>
												<Tr.IrPortal path="analysis-result.library" />
											</TableCell>
											<TableCell width={'15%'}>
												<TableRow>
													<Tr.IrPortal path="analysis-result.match-factor" />
												</TableRow>
											</TableCell>

											<TableCell width={'10%'}>
												<Tr.IrPortal path="analysis-result.weight" />
											</TableCell>

											<TableCell width={'10%'}>
												{!analysisResultData.isCompleted() && <Tr.IrPortal path="analysis-result.take-as-result" />}
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{rankedRMData.map((row, i) => (
											<Row
												key={`row${i}`}
												row={row}
												index={i}
												tableselectedRadio={selectedRadio}
												tablesetSelectedRadio={setSelectedRadio}
												analysisResult={analysisResultData}
												rowsToExpand={expandedRows}
												setRowsToExpand={setExpandedRows}
											/>
										))}
									</TableBody>
								</Table>
							) : (
								<Table
									aria-label="collapsible table"
									sx={{
										'& .MuiTableBody-root 	.MuiTableRow-root': {
											'& .MuiTableCell-root': {
												padding: '6px 8px',
											},
											'&:last-child': {
												'& .MuiTableCell-root': {
													borderBottom: 'none',
												},
											},
										},
									}}
								>
									<TableHead>
										<TableRow
											sx={{
												'& .MuiTableCell-head': {
													padding: '6px 8px',
												},
											}}
										>
											<TableCell width={'20%'}>
												<Tr.IrPortal path="analysis-result.substance-name" />
											</TableCell>
											<TableCell width={'15%'}>
												<Tr.IrPortal path="analysis-result.cas-number" />
											</TableCell>
											<TableCell width={'15%'}>
												<Tr.IrPortal path="analysis-result.quality-grade" />
											</TableCell>
											<TableCell width={'15%'}>
												<Tr.IrPortal path="analysis-result.library" />
											</TableCell>
											<TableCell width={'20%'}>
												{type === IrAnalysisType.QUANTIFICATION ? (
													<Tr.IrPortal path="analysis-result.reference-peak-area" />
												) : (
													<Tr.IrPortal path="analysis-result.match-factor" />
												)}
											</TableCell>
											<TableCell width="15%"></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										<TableRow>
											{rankedRMData.map((row, i) => (
												<PurityCheckRow key={`purity-${i}`} row={row} Index={i} />
											))}
										</TableRow>
									</TableBody>
								</Table>
							)}
						</TableContainer>
					</Box>
					{resultImpurities && resultImpurities?.length > 0 && <IrImpurites impurities={resultImpurities} />}

					<TableLegend analysisResult={analysisResultData} />
					{matchedResult.some((item) => item.isFalsePositive && item.matchFactor > 0.92) && (
						<Stack sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
							<ExceptionIndicator />
							<Typography variant="pg-m" fontWeight={500}>
								<Tr.IrPortal path={getWarningMessage(matchedResult)} />
							</Typography>
						</Stack>
					)}
				</Stack>
			)}
			{analysisResultData?.matchType === IrAnalysisResultMatchType.NOMATCH && <IrNoMatchTable analysisResult={analysisResultData} />}
			{isSimilarSpectrum && (
				<>
					<Alert severity="warning" sx={{ backgroundColor: '#FEFBF5' }} icon={<ExceptionIndicator />}>
						<Typography variant="pg-m">
							<Tr.IrPortal path="analysis-result-table.similar-spectra-warning" />
						</Typography>
					</Alert>
				</>
			)}
			{integrationMessage && (
				<Alert severity="warning" sx={{ backgroundColor: '#FEFBF5' }} icon={ExceptionIndicator(true)}>
					<Typography variant="pg-m">{integrationMessage}</Typography>
				</Alert>
			)}
		</Stack>
	);
};
