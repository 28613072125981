import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formFieldSpacer, innerTitleSpacer, Tr } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikAutoComplete, FormikTextfield, GenericCard } from '@components/common';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { FrequenciesList, SolventsListNMR } from '@models/analysis';

interface NmrReferenceAcquisitionParametersProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

export const NmrReferenceAcquisitionParameters: FC<NmrReferenceAcquisitionParametersProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
}) => {
	const { t } = useTranslation('portal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack marginBottom={innerTitleSpacer}>
				<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
					<Tr.Portal path="drm-detail.acquisition-parameters" />
				</Typography>
			</Stack>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={6}>
					<FormikAutoComplete
						formik={formik}
						value={formik.values.solvent ? { label: formik.values.solvent, value: formik.values.solvent } : null}
						onSelectionChange={(data: unknown) => {
							formik.setFieldValue('solvent', (data as (typeof SolventsListNMR)[0])?.label || '');
						}}
						autocompleteProps={{
							isOptionEqualToValue: (opt, val) => val.value === opt.value,
						}}
						select={false}
						title={t('drm-detail.solvent')}
						placeholder={t('user-references.placeholders.solvent')}
						variant="outlined"
						name="solvent"
						required
						options={SolventsListNMR}
						disabled={false}
						data-testid={'user-reference-nmr-solvent-dropdown'}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikAutoComplete
						formik={formik}
						value={formik.values.frequency ? { label: formik.values.frequency, value: formik.values.frequency } : null}
						onSelectionChange={(data: unknown) => {
							formik.setFieldValue('frequency', (data as (typeof FrequenciesList)[0])?.label || '');
						}}
						autocompleteProps={{
							isOptionEqualToValue: (opt, val) => val.value === opt.value,
						}}
						select={false}
						title={t('drm-detail.frequency-header')}
						placeholder={t('user-references.placeholders.frequency')}
						variant="outlined"
						name="frequency"
						required
						options={FrequenciesList}
						disabled={false}
						data-testid={'user-reference-nmr-frequency-dropdown'}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="relaxationTime"
						title={t('drm-detail.relaxation-time-with-unit')}
						placeholder={t('user-references.placeholders.relaxation-time')}
						variant="outlined"
						disabled={disableRmInActiveFields}
						onChange={() => {
							formik.setFieldTouched('relaxationTime', true);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="numberOfScans"
						title={t('drm-detail.number-of-scans')}
						placeholder={t('user-references.placeholders.number-of-scans')}
						variant="outlined"
						disabled={disableRmInActiveFields}
						onChange={() => {
							formik.setFieldTouched('numberOfScans', true);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="acquisitionTime"
						title={t('drm-detail.acquisition-time-with-unit')}
						placeholder={t('user-references.placeholders.acquisition-time')}
						variant="outlined"
						disabled={disableRmInActiveFields}
						onChange={() => {
							formik.setFieldTouched('acquisitionTime', true);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="temperature"
						title={t('drm-detail.temperature-with-unit')}
						placeholder={t('user-references.placeholders.temperature')}
						variant="outlined"
						disabled={disableRmInActiveFields}
						onChange={() => {
							formik.setFieldTouched('temperature', true);
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="batchNumber"
						title={t('user-references.batch-number')}
						placeholder={t('user-references.placeholders.batch-number')}
						variant="outlined"
						disabled={disableRmInActiveFields}
						onChange={() => {
							formik.setFieldTouched('batchNumber', true);
						}}
					/>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
