import { FC, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { GenericCard, InfoMessageBox, ModalDialog } from '@components/common';
import { UserStoreModel } from '@models/user';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { TheoreticalRunExecutingWarningMessage } from '../AnalysisResultRunTheoretical';

interface IConfirmAnalysisProps {
	visible: boolean;
	user: Maybe<UserStoreModel>;
	onConfirmation: (comment: string) => void;
	onCancel: () => void;
	isTheoreticalExecuting?: boolean;
}
/* TODO: refactor here use DeactivationConfirmPopUp component */
export const NmrConfirmAnalysis: FC<IConfirmAnalysisProps> = ({ user, visible, onConfirmation, onCancel, isTheoreticalExecuting }) => {
	const [comment, setComment] = useState<string>('');
	const { t } = useTranslation('portal');

	return (
		<ModalDialog
			onClose={onCancel}
			title={
				<Typography variant="inherit">
					<Tr.Portal path="analysis-result.confirmation" />
				</Typography>
			}
			onBackdropClick={onCancel}
			open={visible}
			variant="secondary"
			sx={{
				'& .MuiDialog-container': {
					'& .MuiPaper-root': {
						width: '100%',
						maxWidth: '832px',
					},
				},
			}}
		>
			<GenericCard sx={{ marginY: 2, overflow: 'auto', border: 'none', paddingTop: 0, paddingX: 10 }}>
				<Stack sx={{ mb: 3 }} textAlign="center">
					<Typography variant="subtitle1">
						<Tr.Portal path="analysis-result.statement-of-confirmation" />
					</Typography>
				</Stack>
				{isTheoreticalExecuting && <InfoMessageBox message={<TheoreticalRunExecutingWarningMessage />} />}
				<Typography data-testid="confirmation-title-id" variant="pg-m" sx={{ marginY: 2 }}>
					<Tr.Portal path="analysis-result.statement-message" />
				</Typography>
				<TextField
					multiline
					hiddenLabel
					rows={3}
					placeholder={t('analysis-result.confirm-comment')}
					variant="outlined"
					sx={{ backgroundColor: 'grey.50', marginBottom: 2, padding: 0 }}
					focused={false}
					InputProps={{
						sx: {
							'& 	.MuiInputBase-input': {
								fontSize: 15,
								padding: 0,
							},
						},
					}}
					inputProps={{ maxLength: 4000, 'data-testid': 'confirm-text-id' }}
					onChange={(e) => setComment(e.target.value)}
				/>
				<Stack>
					<Stack direction="row" justifyContent="space-between">
						<Stack direction="row" spacing={3} width="100%" alignItems="flex-start">
							<Stack width="50%" sx={{ wordBreak: 'break-word' }}>
								<Typography variant="pg-s">
									<Tr.Portal path="analysis-result.user-signature" />
								</Typography>
								<Typography variant="pg-s" color="primary">
									{user?.name} {user?.surname}
								</Typography>
							</Stack>
							<Stack width="50%" sx={{ wordBreak: 'break-word' }}>
								<Typography variant="pg-s">
									<Tr.Portal path="analysis-result.user-role" />
								</Typography>
								<Typography variant="pg-s" color="primary" overflow="break-word">
									{user?.roles?.map((r) => t(`user-management.roles.${r}`)).join(', ')}
								</Typography>
							</Stack>
						</Stack>
						<Stack direction="row" justifyContent="flex-end" columnGap={3}>
							<Button
								sx={{ width: '180px' }}
								variant="outlined"
								data-testid="cancel-button-id"
								disableElevation
								onClick={onCancel}
							>
								<Tr.Portal path="analysis-result.cancel" />
							</Button>
							<Button
								sx={{ width: '180px' }}
								data-testid="approve-button-id"
								variant="contained"
								disableElevation
								onClick={() => onConfirmation(comment)}
							>
								<Tr.Portal path="analysis-result.i-approve" />
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</GenericCard>
		</ModalDialog>
	);
};
