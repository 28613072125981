import Plotly from 'plotly.js-dist-min';
import {
	fileService,
	irAnalysisService,
	IrAnalysisResult,
	RankBasedMixtureData,
	SampleSpectrumInfo,
	IrAnalysisReportImages,
	IrReportImageModel,
	IrAnalysisResultMatchType,
} from '@services';
import { RxUtils } from '../Rx';

import { PromiseUtils } from '@utils/PromiseUtils';
import { getTransMissionData, SpectraColorCodes, mixtureColorCodes, getDynamicGraphLayout } from '..';
import { IrSettings } from '@components/ir-portal';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SmilesDrawer = require('smiles-drawer') as any;

interface ICordinate {
	x: number[];
	y: number[];
}
const PROMISE_RESOLVER = 'Molecular structure promise resolved.';
const BASE64_KEY = 'data:image/png;base64,';

const drawer = new SmilesDrawer.SmiDrawer({
	width: 450,
	height: 450,
	compactDrawing: false,
	themes: {
		light: {
			C: '#000',
			O: '#000',
			N: '#000',
			F: '#000',
			CL: '#000',
			BR: '#000',
			I: '#000',
			P: '#000',
			S: '#000',
			B: '#000',
			SI: '#000',
			H: '#000',
			BACKGROUND: '#fff',
		},
	},
});

const drawSpectrum = async (ermChartElement: HTMLElement, sampleData: ICordinate, transmissionSpectra: boolean) => {
	return Plotly.newPlot(
		ermChartElement,
		[
			{
				x: sampleData.x,
				y: transmissionSpectra ? getTransMissionData([...sampleData.y]) : sampleData.y,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.GRAY },
				direction: 'counterclockwise',
			},
		],
		{
			autosize: true,
			width: 500,
			height: 400,
			margin: { pad: 0, t: 0, r: 50, b: 50, l: 70 },
			xaxis: {
				range: [4000, 0],
				showdividers: false,
				showline: true,
				zeroline: false,
				title: 'Wavenumber [cm⁻¹]',
			},
			yaxis: {
				showdividers: false,
				showline: true,
				zeroline: false,
				title: transmissionSpectra ? 'Transmission [%]' : 'Absorbance [A.U.]',
			},
		},
	);
};

export const getJSONData = async (url: string): Promise<ICordinate> => {
	return new Promise<{ x: number[]; y: number[] }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const yData = data['user_spectrum'].y;
			const xData = [data['user_spectrum'].x.start, data['user_spectrum'].x.stop];
			const xAry: number[] = [];
			for (let i = xData[0]; i >= xData[1]; i--) {
				xAry.push(i);
			}
			resolve({
				x: xAry,
				y: yData,
			});
		});
	});
};

export const sendIrImage = async (
	analysisResult: IrAnalysisResult,
	rankedRMData: RankBasedMixtureData,
	userSpectrumInfo: SampleSpectrumInfo,
	cb: (pdfUrl: string) => void,
	ir: IrSettings,
) => {
	const returnedModel: IrAnalysisReportImages = {
		substanceImages: [],
		mixtureImages: '',
	};
	const sampleData = await getJSONData(analysisResult.sampleSpectrum);
	const stackedElement = document.createElement('div');
	stackedElement.style.height = '200px';

	const { transmissionSpectra } = ir;
	const chartDataAry: any = [];
	const userChartData = {
		y: transmissionSpectra ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
		x: userSpectrumInfo.sample_x,
		type: 'scatter',
		mode: 'lines',
		marker: { color: SpectraColorCodes.RICH_GREEN },
		direction: 'counterclockwise',
		name: 'Input Spectrum',
		yaxis: 'y',
	};
	chartDataAry.push(userChartData);

	const combinedChartData = {
		y:
			transmissionSpectra && rankedRMData
				? getTransMissionData([...rankedRMData['combined_spectrum_y']])
				: rankedRMData && rankedRMData['combined_spectrum_y'],
		x: rankedRMData && rankedRMData.spectrum_x,
		type: 'scatter',
		mode: 'lines',
		marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
		direction: 'counterclockwise',
		name: 'Combined Spectrum',
		yaxis: 'y2',
	};
	if (rankedRMData?.mixtures?.length > 1) {
		chartDataAry.push(combinedChartData);
	}

	for (let i = 0; i < rankedRMData?.mixtures?.length || 0; i++) {
		const tempObj: Partial<IrReportImageModel> = {};
		const mixture = rankedRMData.mixtures[`${i}`];
		const referenceMaterial = analysisResult.referenceMaterials.filter((item) => item.casNumber === mixture.casNumber);
		const { smilesCode, casNumber } = referenceMaterial[0] ?? { smilesCode: undefined, casNumber: undefined };
		const molecularStructureImg = document.createElement('img');
		const ermChartElement = document.createElement('div');
		const tableOfAssignment = {
			rmPeakPosition: rankedRMData?.table_of_assignment?.reference_peak_pos || [],
			rmintensity: rankedRMData?.table_of_assignment?.reference_peak_intensity || [],
			samplePeakPosition: rankedRMData?.table_of_assignment?.sample_peak_pos || [],
			sampleintensity: rankedRMData?.table_of_assignment?.sample_peak_intensity || [],
			peakPosition: rankedRMData?.table_of_assignment?.peak_pos || [],
		};

		const mixtureChartData = {
			y: transmissionSpectra ? getTransMissionData([...mixture.scaled_spectrum_y]) : mixture.scaled_spectrum_y,
			x: rankedRMData.spectrum_x,
			type: 'scatter',
			mode: 'lines',
			marker: { color: mixtureColorCodes[`${i}`] },
			direction: 'counterclockwise',
			name: mixture.substanceName,
			yaxis: 'y' + (3 + i),
		};

		chartDataAry.push(mixtureChartData);
		try {
			const imgPromise = new Promise((resolve, reject) => {
				drawer.draw(
					smilesCode,
					molecularStructureImg,
					'light',
					() => {
						resolve(PROMISE_RESOLVER);
					},
					() => {
						reject();
					},
				);
			});
			await Promise.resolve(imgPromise).catch();
		} catch {
			//
		}

		await PromiseUtils.delay(() => {
			tempObj.moleculeImage = molecularStructureImg.src.replace(BASE64_KEY, '');
			tempObj.casNumber = casNumber;
			tempObj.peaktableJson = [tableOfAssignment];
			returnedModel.substanceImages.push(tempObj);
		}, 500);
		const targetResult = rankedRMData.mixtures.filter((item) => item.casNumber === casNumber);

		for (let j = 0; j < targetResult.length; j++) {
			await drawSpectrum(ermChartElement, sampleData, transmissionSpectra || false);
			const ermImage = await Plotly.toImage(ermChartElement, { scale: 2, format: 'png', width: 850, height: 500 });
			tempObj.referenceMaterialSpectrum = ermImage.replace(BASE64_KEY, '');
		}
	}
	const layoutConfig = getDynamicGraphLayout(chartDataAry, userSpectrumInfo);
	await Plotly.newPlot(stackedElement, chartDataAry, layoutConfig as any, {});
	const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });
	const spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
	await PromiseUtils.delay(() => {
		returnedModel.mixtureImages = analysisResult.matchType === IrAnalysisResultMatchType.NOMATCH ? null : spectrumSeperated;
	}, 500);
	RxUtils.promisify(irAnalysisService.sendAnalysisImage(analysisResult?.analysisId, returnedModel), (pdfUrl) => cb(pdfUrl));
};
