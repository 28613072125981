import { FC, ReactElement } from 'react';
import { activeTechnologySelector, setActiveTechnology, userSelector } from '@store/slices/common/common.slice';
import { useDispatch, useSelector } from 'react-redux';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { useLocation } from 'react-router-dom';

export type PortalElementProps = {
	nmrComponent: ReactElement;
	irComponent: ReactElement;
};

export const PortalElement: FC<PortalElementProps> = ({ nmrComponent, irComponent }) => {
	const user = useSelector(userSelector);
	const activeTechnology = useSelector(activeTechnologySelector);
	const loc = useLocation();
	const dispatch = useDispatch();

	const getCurrentLabTechnology = () => {
		const technology = user?.laboratories?.find((lab) => Number(user?.currentLabId) === lab.id)?.technology || null;

		if (technology === SupportedTechnology.NMR) {
			return SupportedTechnology.NMR;
		} else if (technology === SupportedTechnology.IR) {
			return SupportedTechnology.IR;
		}

		return null;
	};

	if (activeTechnology && (loc.pathname.includes('/user-libraries/') || loc.pathname.includes('/references/edit'))) {
		if (activeTechnology === SupportedTechnology.NMR) {
			return nmrComponent;
		}
		return irComponent;
	}

	if (!loc.pathname.includes('/user-libraries/') && !loc.pathname.includes('/references/edit')) {
		dispatch(setActiveTechnology(getCurrentLabTechnology()));
	}

	if (!user || !user.currentLabId || !user.laboratories) {
		return null;
	}

	const lab = user.laboratories.find((l) => l.id === +(user.currentLabId ?? 0));
	if (!lab) {
		return null;
	}
	switch (lab.technology) {
		case SupportedTechnology.NMR:
			return nmrComponent;
		case SupportedTechnology.IR:
			return irComponent;
		default:
			return nmrComponent;
	}
};
