import React from 'react';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { SingleSelectMenu } from '../SingleSelectMenu';
import { useDispatch, useSelector } from 'react-redux';
import { activeTechnologySelector, setActiveTechnology, userSelector } from '@store/slices/common/common.slice';
import { useTranslation } from 'react-i18next';
import { UserRole } from '@models/user';

const TechnologySelector = () => {
	const { t } = useTranslation('common');
	const dispatch = useDispatch();
	const activeTechnology = useSelector(activeTechnologySelector);

	const onSelectedItemChange = (item) => {
		if (item === 'NMR') {
			dispatch(setActiveTechnology(SupportedTechnology.NMR));
		} else {
			dispatch(setActiveTechnology(SupportedTechnology.IR));
		}
	};
	const user = useSelector(userSelector);
	const noOfLaboratories = user?.laboratories?.length || 0;
	const isUserLabAdmin = user?.roles.includes(UserRole.LAB_ADMIN);
	return (
		isUserLabAdmin &&
		!(noOfLaboratories === 1) && (
			<SingleSelectMenu
				items={Object.keys(SupportedTechnology).map((i) => ({ id: i, label: t(`${i} Technology`) }))}
				onSelectedItemChange={onSelectedItemChange}
				sx={{ minWidth: 182, maxHeight: 40, marginTop: '5px' }}
				defaultValue={activeTechnology}
			/>
		)
	);
};

export default TechnologySelector;
