import React, { createRef, FC, ReactNode, useContext } from 'react';
import { Divider, StackProps, SxProps, Stack, Typography, TypographyProps, Button } from '@mui/material';
import { GenericCard, OverflowText, PortalBadge, QualityGradeChip } from '@components/common';
import {
	AnalysisResultTheoreticalSpecrumDetail,
	NmrAnalysisTypeIndicator,
	NmrDRMDetail,
	NmrDRMIndicator,
	TableButton,
} from '@components/nmr-portal';
import { useDRMDetail } from '@hooks';
import { Tr } from '@utils';
import { MolecularFormula } from '@components/common';
import { AnalysisResultContext } from '@routes/Portal/nmr/NmrAnalysisResult';
import { differenceInMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';
import { useMemoizedTestId } from '@hooks';
import { TechnologyChip } from '@components/common/TechnologyChip';
import { QualityGrade } from '@models/reference-material';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
interface ISampleDetailIndicatorProps extends Omit<StackProps, 'title'> {
	title: ReactNode;
	value: ReactNode;
	sx?: SxProps;
	valueProps?: TypographyProps & { 'data-testid': string };
}
const SampleDetailIndicator: FC<ISampleDetailIndicatorProps> = ({ title, value, sx, valueProps }) => {
	return (
		<Stack direction="row" marginBottom={1.5}>
			<Stack sx={{ maxWidth: '230px', width: '100%', marginRight: '32px' }}>
				<Typography variant="pg-m" sx={{ color: 'text.primary', whiteSpace: 'nowrap' }}>
					{title}
				</Typography>
			</Stack>
			<Stack sx={{ minWidth: '300px', width: '100%' }}>
				<Typography variant="pg-m" sx={{ color: 'grey.800', maxWidth: '468px', ...sx }} {...valueProps}>
					{value}
				</Typography>
			</Stack>
		</Stack>
	);
};

interface UnMathcedElementProps {
	isTheoretical: boolean;
	id: number;
	name: string;
	smilesCode?: string;
	physicalProductNumber?: string;
	drmFreq?: string;
	drmSolvent?: string;
	version?: string;
	casNumber?: string;
	linearFormula?: string;
	molecularWeight?: number;
	qualityGrade?: QualityGrade;
	technology?: string;
	isActive?: boolean;
	ermProductNumber?: string;
}

export const NmrAnalysisSetupSummary: FC = () => {
	const { analysisResultData: analysisResult } = useContext(AnalysisResultContext);

	if (!analysisResult) return null;

	const { measurementDeviceBrand, measurementType, targetType, solvent, productAndBatchNumber, details, measurementId, frequency } =
		analysisResult.nmrAnalysis;

	const trimmedMeasurementId = measurementId.replace(/(\$\$)(.*)$/, '');

	const { certifiedReferenceMaterial } = analysisResult;

	const { resetDetail, setDRMDetail, drmId, visible: DRMVisible, ...drmDetailProps } = useDRMDetail();
	const { t } = useTranslation('portal');

	const matchedElements = analysisResult?.referenceMaterials?.filter(({ id }) =>
		analysisResult?.resultMatches.some((res) => !res.isTheoretical && id === res.referenceMaterialId),
	);

	const unMatchedElements = analysisResult?.referenceMaterials?.filter(
		({ id }) => !analysisResult?.resultMatches.find((res) => id === res.referenceMaterialId),
	);

	const showNotMatch =
		unMatchedElements && unMatchedElements?.length > 0 && !(analysisResult?.isQualitative() && !analysisResult.isTargeted());
	const showMatch = matchedElements && matchedElements?.length > 0;

	const matchedElementsTheoretical = analysisResult?.referenceMaterials?.filter(({ casNumber }) =>
		analysisResult?.resultMatches.some((res) => res.isTheoretical && casNumber === res.casNumber),
	);

	const showMatchTheoretical = matchedElementsTheoretical && matchedElementsTheoretical?.length > 0;
	const drmDetailRefs = matchedElements?.map(() => React.createRef<HTMLDivElement>());
	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	const UnMatchedElement = (params: UnMathcedElementProps) => {
		return (
			<Stack key={`not-found-${params.id}`} direction="row" marginBottom={4}>
				<Stack marginRight={4}>
					<MolecularFormula
						width={250}
						height={200}
						sx={{
							width: '268px',
							height: '201px',
						}}
						smilesFormula={params.smilesCode}
						zoomIconProps={{
							'data-testid': useMemoizedTestId('zoom-icon'),
						}}
						ref={molecularFormulaRef}
					/>
				</Stack>
				<Stack display="flex" justifyContent="center">
					<Stack direction="row">
						<Stack direction="row" spacing={1.5} marginBottom={2}>
							{params && (
								<QualityGradeChip
									qualityGrade={params?.qualityGrade ? params?.qualityGrade : QualityGrade.DRM}
									isReferenceActive={true}
								/>
							)}
							{params && (
								<TechnologyChip
									technology={params?.technology || ''}
									qualityGrade={params?.qualityGrade}
									isActive={true}
									sx={{ height: '1.5rem', marginBottom: 0 }}
								/>
							)}

							{Math.abs(differenceInMonths(certifiedReferenceMaterial?.systemCreationDate, new Date())) < 1 && (
								<PortalBadge color="warning" badgeContent={<Tr.Common path="new" />} />
							)}
						</Stack>
					</Stack>

					<Stack direction="row" alignItems="center" marginBottom={2}>
						<Typography variant="h3" sx={{ color: 'text.primary' }} data-testid={`no-matching-drm-name-${params.id}`}>
							{params.name}
						</Typography>
					</Stack>

					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.cas-number" />}
						value={params.casNumber}
						containerSx={{ marginBottom: 1.5 }}
					/>
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.linear-formula" />}
						value={params.linearFormula}
						containerSx={{ marginBottom: 1.5 }}
					/>
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.molecular-weight-without-unit" />}
						value={params.molecularWeight ? `${params.molecularWeight} ${t('drm-detail.molecular-weight-unit')}` : '-'}
						containerSx={{ marginBottom: 1.5 }}
					/>
					<NmrDRMIndicator label={<Tr.Portal path="drm-detail.reference-code" />} value={params?.ermProductNumber} />
				</Stack>
				<TableButton.Outlined
					sx={{ marginLeft: 'auto', width: '7rem' }}
					data-testid="view-summary-button"
					onClick={() =>
						setDRMDetail({
							drmId: params.id,
							visible: true,
							onHandleAction: () => null,
							actionLabel: '',
							isTheoretical: params.isTheoretical,
						})
					}
				>
					<Button size="small">
						<Tr.Portal path="new-analysis.view-details" />{' '}
					</Button>
				</TableButton.Outlined>
			</Stack>
		);
	};
	const drmDetailRef = createRef<HTMLDivElement>();
	return (
		<GenericCard
			title={
				<Typography variant="h3" sx={{ color: 'primary.main' }}>
					<Tr.Portal path="analysis-setup-summary.analysis-summary" />
				</Typography>
			}
			sx={{
				boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
				border: 'none',
				paddingX: 3,
				paddingY: 4,
			}}
		>
			<Stack>
				<Stack direction="row" id="analysis-setup-summary-id" columnGap={4}>
					<Stack direction="column" width="30.25rem" rowGap={0.5}>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.analysis-id" />}
							value={analysisResult?.analysisId}
							valueProps={{ 'data-testid': 'analysis-id-value' }}
						/>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.analysis-technology" />}
							value={SupportedTechnology.NMR}
							valueProps={{ 'data-testid': 'analysis-technology-value' }}
						/>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.type-of-analysis" />}
							value={
								<NmrAnalysisTypeIndicator
									measurementType={measurementType}
									targetType={targetType}
									sx={{ marginTop: 'unset', marginBottom: 'unset', color: 'grey.800' }}
								/>
							}
							valueProps={{ 'data-testid': 'analysis-type-value' }}
						/>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.sample-solvent" />}
							value={solvent}
							valueProps={{ 'data-testid': 'analysis-sample-solvent-value' }}
						/>

						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.frequency" />}
							value={frequency + ' MHz'}
							valueProps={{ 'data-testid': 'analysis-frequency-value' }}
						/>
					</Stack>
					<Stack direction="column" width="100%" maxWidth="calc(100% - 31.75rem)" rowGap={0.5}>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.sample-measurement-device" />}
							value={measurementDeviceBrand || '-'}
							valueProps={{ 'data-testid': 'analysis-sample-measurement-device-value' }}
						/>
						{analysisResult?.isQuantitative() && (
							<SampleDetailIndicator
								title={<Tr.Portal path="analysis-setup-summary.calibration" />}
								value={analysisResult.calibrationName || '-'}
								valueProps={{ 'data-testid': 'analysis-calibration' }}
							/>
						)}
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.product-and-batch-number" />}
							value={productAndBatchNumber || '-'}
							valueProps={{ 'data-testid': 'analysis-product-batch-value' }}
						/>
						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.additional-details" />}
							value={details || '-'}
							valueProps={{ 'data-testid': 'analysis-additional-details-value' }}
						/>

						<SampleDetailIndicator
							title={<Tr.Portal path="analysis-setup-summary.measurementId" />}
							value={
								<OverflowText variant="pg-m" width="100%" color="grey.800" enableTooltip>
									{trimmedMeasurementId}
								</OverflowText>
							}
							valueProps={{ 'data-testid': 'analysis-measurement-id-value', sx: { width: '100%' } }}
						/>
					</Stack>
				</Stack>
				{showMatch && (
					<>
						<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
						<Stack direction="column">
							<Typography variant="h3" sx={{ color: 'primary.main' }} data-testid="drm-matching-title">
								<Tr.Portal
									path={
										matchedElements.length === 1
											? 'analysis-setup-summary.matching-reference'
											: 'analysis-setup-summary.matching-references'
									}
								/>
							</Typography>

							{matchedElements?.map((referenceMaterial, index) => (
								<Stack
									data-testid={`erm-detail-id-${index}`}
									key={`key-${index}-${referenceMaterial.id}`}
									id={`drm-details-${0}-id`}
								>
									<Stack direction="row" sx={{ marginTop: '1rem' }}>
										<OverflowText
											variant="h5"
											enableTooltip={true}
											sx={{ color: 'primary.text', marginTop: '8px', marginBottom: '16px', width: '100%' }}
										>
											{referenceMaterial.name}
										</OverflowText>
										{referenceMaterial && (
											<QualityGradeChip
												qualityGrade={referenceMaterial?.qualityGrade || QualityGrade.DRM}
												isReferenceActive={true}
											/>
										)}
										{referenceMaterial && (
											<TechnologyChip
												technology={referenceMaterial?.technology || ''}
												qualityGrade={referenceMaterial?.qualityGrade}
												isActive={true}
												sx={{ height: '1.5rem', marginBottom: 0, marginLeft: '0.5rem' }}
											/>
										)}
									</Stack>
									{referenceMaterial && (
										<NmrDRMDetail
											analysisResult={true}
											drmData={referenceMaterial}
											showModal={false}
											ref={drmDetailRefs[`${index}`]}
										/>
									)}
								</Stack>
							))}
						</Stack>
					</>
				)}

				{showMatchTheoretical && (
					<>
						<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
						<Stack direction="column">
							<Typography variant="h3" color="primary.main" data-testid="theoretical-matching-title">
								<Tr.Portal
									path={
										matchedElementsTheoretical?.length > 1
											? 'analysis-setup-summary.theoretical-spectra-matching'
											: 'analysis-setup-summary.theoretical-spectrum-matching'
									}
								/>
							</Typography>

							{matchedElementsTheoretical?.map((theoreticalSpectrum, index) => (
								<Stack marginTop={index === 0 ? 2 : 4} key={`key-${index}-theoretical-${theoreticalSpectrum.id}`}>
									<Stack direction="row" display="flex" justifyContent="center" spacing={1.5} marginBottom={2}>
										<OverflowText variant="h5" enableTooltip={true} sx={{ color: 'primary.text', width: '100%' }}>
											{theoreticalSpectrum.substanceName}
										</OverflowText>

										<QualityGradeChip
											qualityGrade={
												theoreticalSpectrum?.qualityGrade ? theoreticalSpectrum?.qualityGrade : QualityGrade.DRM
											}
											isReferenceActive={theoreticalSpectrum?.status === UserReferenceStatus.Active}
										/>
										{theoreticalSpectrum && (
											<TechnologyChip
												technology={theoreticalSpectrum?.technology}
												qualityGrade={theoreticalSpectrum?.qualityGrade}
												isActive={theoreticalSpectrum?.status === UserReferenceStatus.Active}
												sx={{ height: '1.5rem', marginBottom: 0 }}
											/>
										)}
									</Stack>

									<AnalysisResultTheoreticalSpecrumDetail detailData={theoreticalSpectrum} />
								</Stack>
							))}
						</Stack>
					</>
				)}

				{showNotMatch && (
					<Stack direction="column" data-testid="no-match-erm-id">
						<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
						<Typography variant="h3" sx={{ color: 'error.main', marginBottom: 2 }} data-testid="no-matching-title">
							<Tr.Portal
								path={
									unMatchedElements.length > 1
										? 'analysis-setup-summary.drms-not-matching'
										: 'analysis-setup-summary.drm-not-matching'
								}
							/>
						</Typography>
						<Stack>
							{unMatchedElements?.map(
								({
									id,
									name,
									linearFormula,
									solvent: drmSolvent,
									frequency: drmFreq,
									smilesCode,
									casNumber,
									version,
									physicalProductNumber,
									qualityGrade,
									technology,
									status,
									molecularWeight,
									ermProductNumber,
								}) =>
									UnMatchedElement({
										id,
										name,
										smilesCode,
										physicalProductNumber,
										drmFreq,
										drmSolvent,
										version,
										casNumber,
										linearFormula,
										isTheoretical: false,
										qualityGrade,
										technology,
										isActive: status === UserReferenceStatus.Active,
										molecularWeight,
										ermProductNumber,
									}),
							)}
						</Stack>
					</Stack>
				)}
			</Stack>
			<NmrDRMDetail
				showModal
				visible={DRMVisible}
				drmId={drmId}
				onCloseClick={() => resetDetail()}
				{...drmDetailProps}
				ref={drmDetailRef}
			/>
		</GenericCard>
	);
};
