import { PagedResult, PatchType } from '@models';
import { CreateLabModal, HttpRequestGenericOptions, Lab, httpService } from '@services';
import { concatMap, tap, map } from 'rxjs/operators';
import { RxUtils, TypeUtils } from '@utils';

import { logger } from '@services';
import { CreateOrganizationModel } from './create-organization-model.interface';
import { Organization, OrganizationManagementModel, OrganizationRequestModel } from './organization.model';
import { OrganizationStatus } from '@models/organization';
import { coreConfig } from '../coreConfig';

export class OrganizationService {
	private getURL() {
		return `${coreConfig.url}/Organization`;
	}

	get(id: number) {
		return httpService.get<Organization>(this.getURL(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Organization, result)),
		);
	}

	getLaboratories(id: number, options?: HttpRequestGenericOptions) {
		return httpService.get<PagedResult<Lab>>(this.getURL(), `${id}/laboratories`, { errorHandler: 'notification', ...options }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getLaboratories.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new PagedResult<Lab>(Lab), result)),
		);
	}

	getAll(getOrganizationsRequest: Partial<OrganizationRequestModel>) {
		const params = new URLSearchParams();
		Object.entries(getOrganizationsRequest).forEach(([k, v]) => params.append(k, v.toString()));
		return this.getAllWithParams(params);
	}

	getAllWithParams(params: URLSearchParams) {
		return httpService
			.get<PagedResult<OrganizationManagementModel[]>>(this.getURL(), '', {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getAll.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) =>
					TypeUtils.transformFromExist(new PagedResult<OrganizationManagementModel>(OrganizationManagementModel), result),
				),
			);
	}

	create(organization: CreateOrganizationModel) {
		return httpService.post<CreateOrganizationModel>(this.getURL(), '', { body: organization, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.create.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Organization, result)),
		);
	}

	edit(organization: Partial<Organization>) {
		return httpService.put<Organization>(this.getURL(), '', { body: organization, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.edit.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Organization, result)),
		);
	}

	updateMfaSettings(mfaData: { id: number; mfaEnabled: boolean }) {
		return httpService.put<Organization>(this.getURL(), '/mfa', { body: mfaData, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.updateMfaSettings.name}]`, result)),
			map((result) => TypeUtils.transform(Organization, result)),
		);
	}

	addLaboratory(lab: CreateLabModal, id: Number) {
		return httpService.post<CreateLabModal>(this.getURL(), `${id}/laboratories`, { body: lab, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.addLaboratory.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(Lab, result)),
		);
	}

	changeStatus(id: number, status: OrganizationStatus, comment?: string) {
		const statusPatch = {
			op: PatchType.REPLACE,
			path: '/Status',
			value: status === OrganizationStatus.ACTIVE ? OrganizationStatus.INACTIVE : OrganizationStatus.ACTIVE,
		};
		return httpService.patch(this.getURL(), `${id}/status`, {
			body: comment
				? [
						statusPatch,
						{
							op: PatchType.REPLACE,
							path: '/Comment',
							value: comment,
						},
					]
				: [statusPatch],
			errorHandler: 'notification',
		});
	}

	changeSecondApprovalProcess(secondApproval: boolean) {
		const secondApprovalPatch = {
			op: PatchType.REPLACE,
			path: '/SecondApproval',
			value: secondApproval,
		};
		return httpService
			.patch<Organization>(this.getURL(), 'secondApproval', {
				body: [secondApprovalPatch],
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.changeSecondApprovalProcess.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transform(Organization, result)),
			);
	}
}
export const organizationService = new OrganizationService();
