import { FC, ReactNode, useEffect, useState } from 'react';
import { OverflowText, OverflowTextMaxLines } from '@components/common';
import { Stack, StackProps, Typography, Tabs, Tab } from '@mui/material';
import { Organization, organizationService } from '@services';
import { RxUtils } from '@utils';
import { OrganizationLabs } from '@components/common/OrganizationLabs';
import { TabDivider } from '@components/common/TabDivider';
import { OrganizationDetails } from '../OrganizationDetails';
import { SubscriptionDetails } from '@components/common/SubscriptionDetails';
import { UserManagement } from '@routes/Portal/common/UserManagement';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrganizationPermission, UserRole } from '@models/user';
import { useHasPermissions, useRestrictedComponent } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb, userSelector } from '@store/slices/common/common.slice';
import { PortalEditOrganizationTabs } from '@models/organization/edit-organization-tabs.enum';
import { PortalPageRoutes } from '@models/router';
import { useTranslation } from 'react-i18next';
interface ISettingIndicatorProps {
	title: ReactNode;
	children: ReactNode | undefined;
	enableTooltip?: boolean;
	heightMax?: boolean;
	dataTestId?: string;
}
// TO DO: Move it under different file
export const SettingIndicator: FC<Omit<StackProps, 'title'> & ISettingIndicatorProps> = ({
	title,
	children,
	enableTooltip = false,
	heightMax = false,
	sx,
	dataTestId = 'setting-indicatior-test-id',
}) => {
	return (
		<Stack marginTop={1} sx={sx} height="100%" width={'100%'}>
			<Typography variant="label-s" sx={{ color: 'grey.800' }}>
				{title}
			</Typography>
			<Stack flex={1} flexDirection={'row'} flexGrow={1} sx={{ marginTop: 1.5, borderRadius: 1, width: 1 }} data-testid={dataTestId}>
				{!heightMax ? (
					<OverflowText
						enableTooltip={enableTooltip}
						variant="pg-m"
						sx={{
							color: 'text.primary',
							minHeight: '20px',
							textTransform: 'initial',
							paddingRight: '2px',
							maxLines: 3,
						}}
					>
						{children ?? ''}
					</OverflowText>
				) : (
					<OverflowTextMaxLines
						backgroundColor={'#ffffff'}
						maxHeight={'4.8rem'}
						variant="pg-m"
						sx={{ color: 'text.primary', textTransform: 'initial', fontWeight: '400', lineHeight: '1.6rem', fontSize: '1rem' }}
					>
						{children ?? ''}
					</OverflowTextMaxLines>
				)}
			</Stack>
		</Stack>
	);
};

export const PortalOrganizationSettings = () => {
	const [organization, setOrganization] = useState<Organization>();
	const [currentTab, setCurrentTab] = useState<PortalEditOrganizationTabs>(PortalEditOrganizationTabs.OVERVIEW);
	const loggedinUser = useSelector(userSelector);
	const hasSubscriptionPermission = useHasPermissions(OrganizationPermission.VIEW_SUBSCRIPTION_DETAILS);

	const { id = loggedinUser?.organizationId, name = PortalEditOrganizationTabs.OVERVIEW } = useParams<{
		id: string;
		name: PortalEditOrganizationTabs;
	}>();
	const navigate = useNavigate();
	const loc = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation('portal');

	useEffect(() => {
		const orgId = id ?? loggedinUser?.organizationId;
		orgId &&
			RxUtils.promisify(organizationService.get(+orgId), (data) => {
				setOrganization(data);
			});
	}, [id, loggedinUser]);

	const isOnlyOrgAdmin = loggedinUser?.roles?.length === 1 && loggedinUser.roles[0] === UserRole.ORG_ADMIN;
	useEffect(() => {
		setCurrentTab(name);
		if (!isOnlyOrgAdmin) {
			dispatch(setBreadcrumb({ tab: t(`dashboard.${name}`), ':id': id }));
		}
	}, [loc, t]);

	const contentMap: Record<PortalEditOrganizationTabs, ReactNode> = {
		overview: <OrganizationDetails organization={organization} />,
		subscription: <SubscriptionDetails organization={organization} />,
		labs: <OrganizationLabs organizationData={organization} />,
		users: <UserManagement isMfaEnabled={organization?.mfa} />,
	};
	return (
		<Stack alignItems={'stretch'} flex={1} flexGrow={1} height={1}>
			<Typography variant="h1" color="primary.main">
				{organization?.name}
			</Typography>
			<Tabs
				value={currentTab}
				textColor="primary"
				sx={{ position: 'relative', marginTop: 3, marginBottom: 5 }}
				onChange={(_, newValue) => {
					if (!isOnlyOrgAdmin) {
						navigate(`../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}/${id}/${newValue}`);
					} else {
						navigate(`../${PortalPageRoutes.DASHBOARD}/${id}/${newValue}`);
					}
				}}
			>
				<Tab
					value={PortalEditOrganizationTabs.OVERVIEW}
					label={t(`dashboard.${PortalEditOrganizationTabs.OVERVIEW}`)}
					sx={{
						color: currentTab === PortalEditOrganizationTabs.OVERVIEW ? 'primary.main' : 'grey.800',
					}}
				/>
				{hasSubscriptionPermission && (
					<Tab
						value={PortalEditOrganizationTabs.SUBSCRIPTION}
						label={t(`dashboard.${PortalEditOrganizationTabs.SUBSCRIPTION}`)}
						data-testid="subscription-button"
						sx={{
							color: currentTab === PortalEditOrganizationTabs.SUBSCRIPTION ? 'primary.main' : 'grey.800',
						}}
					/>
				)}
				<Tab
					data-testid="settings-labs-test-id"
					value={PortalEditOrganizationTabs.LABS}
					label={t(`dashboard.${PortalEditOrganizationTabs.LABS}`)}
					sx={{
						color: currentTab === PortalEditOrganizationTabs.LABS ? 'primary.main' : 'grey.800',
					}}
				/>

				{useRestrictedComponent(
					OrganizationPermission.USER,
					<Tab
						data-testid="settings-users-test-id"
						value={PortalEditOrganizationTabs.USERS}
						label={t(`dashboard.${PortalEditOrganizationTabs.USERS}`)}
						sx={{
							color: currentTab === PortalEditOrganizationTabs.USERS ? 'primary.main' : 'grey.800',
						}}
					/>,
				)}

				<TabDivider />
			</Tabs>

			{contentMap[`${currentTab}`]}
		</Stack>
	);
};
