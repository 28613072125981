import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertService, irDrmService } from '@services';
import { Button, Stack, SxProps } from '@mui/material';
import { Tr } from '@utils/Translation';
import { formFieldSpacer } from '@utils/Theme';
import { IrReferenceOverview } from './IrReferenceOverview';
import { IrReferenceSubstanceDetails } from './IrReferenceSubstanceDetails';
import { IrReferenceAcquisitionParameters } from './IrReferenceAcquistionParameters';
import { IrReferenceAdditionalDetails } from './IrReferenceAdditionalDetails';
import { IUploadedFile } from '@models/file';
import { RxUtils } from '@utils/Rx';
import { PortalPageRoutes } from '@models/router';

interface UserReferenceFormLayoutProps {
	formik: any;
	referenceFileId: number;
	editing?: boolean;
	setUploadedFile: (file) => void;
	uploadedFile: IUploadedFile | null;
	materialStatus?: string;
}

const borderStyle = {
	border: 'none',
	paddingX: 3,
	paddingY: 4,
	boxShadow: '0px 0px 1px rgba(15, 26, 46, 0.15), 0px 1px 4px rgba(15, 26, 46, 0.15)',
	marginTop: formFieldSpacer,
};

interface CreateRefSaveFieldsProps {
	formik: any;
	editing?: boolean;
	containerSx?: SxProps;
	saveButtonText?: string;
	disabled?: boolean;
	handleCancelEdit?: () => void;
	uploadedFile?: IUploadedFile | null | undefined;
	fileReuploaded?: boolean;
}

export const CreateRefSaveFields: FC<CreateRefSaveFieldsProps> = ({
	formik,
	editing,
	containerSx,
	disabled,
	uploadedFile,
	fileReuploaded: fileReuploaded,
}) => {
	const navigate = useNavigate();
	const isFormTouched = Object.keys(formik?.touched)?.length > 0;
	const shouldCheckForUploadFile = editing ? !isFormTouched && !fileReuploaded : !uploadedFile;

	const handleCancel = () => {
		const { id } = formik.values;
		if (id) {
			RxUtils.promisify(irDrmService.deleteUserReference(id));
		}
		navigate(`/portal/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`);
	};

	const onSaveClick = () => {
		if (editing) {
			alertService.send({
				titleText: <Tr.IrPortal path="user-references.confirmation" />,
				onClose: () => null,
				onConfirm: () => handleCancel?.(),
				content: <Tr.IrPortal path="user-references.unsaved.content" />,
				confirmTextComponent: <Tr.IrPortal path="user-references.confirm" />,
				closeTextComponent: <Tr.IrPortal path="user-references.cancel" />,
				confirmTextHeader: <Tr.IrPortal path="user-references.unsaved.title" />,
			});
		} else {
			alertService.send({
				titleText: <Tr.IrPortal path="user-references.confirmation" />,
				onClose: () => null,
				onConfirm: () => handleCancel?.(),
				content: <Tr.IrPortal path="user-references.create-leave-message" />,
				confirmTextComponent: <Tr.IrPortal path="user-references.confirm" />,
				closeTextComponent: <Tr.IrPortal path="user-references.cancel" />,
				confirmTextHeader: <Tr.IrPortal path="user-references.leave-this-page" />,
			});
		}
	};
	return (
		<Stack direction="row" columnGap={2} margin="auto 0 0 auto" sx={containerSx}>
			<Button variant="outlined" data-testid="close-button-test-id" size="medium" sx={{ paddingX: 2 }} onClick={onSaveClick}>
				<Tr.Admin path="organization.cancel" />
			</Button>

			<Button
				data-testid="save-organization-button-id"
				type="submit"
				variant="contained"
				size="medium"
				sx={{ color: 'white' }}
				disabled={disabled !== undefined ? disabled : !formik.isValid || !formik.dirty || shouldCheckForUploadFile}
			>
				{!editing ? <Tr.IrPortal path="device-management.create-device" /> : <Tr.IrPortal path="user-references.save" />}
			</Button>
		</Stack>
	);
};

export const UserReferenceFormLayout: FC<UserReferenceFormLayoutProps> = ({
	formik,
	referenceFileId,
	editing,
	setUploadedFile,
	uploadedFile,
}) => {
	return (
		<Stack data-testid="user-reference-layout-id" direction="column" width={1} justifyContent="space-between">
			{referenceFileId ? (
				<IrReferenceOverview
					setUploadedFile={setUploadedFile}
					formik={formik}
					sx={borderStyle}
					referenceFileId={referenceFileId}
					editing={editing}
					uploadedFile={uploadedFile}
				/>
			) : null}
			<IrReferenceSubstanceDetails formik={formik} sx={borderStyle} editing={editing} />
			<IrReferenceAcquisitionParameters formik={formik} sx={borderStyle} editing={editing} />
			<IrReferenceAdditionalDetails formik={formik} sx={borderStyle} editing={editing} />
		</Stack>
	);
};
