import { Column } from 'react-table';
import { Tr } from '@utils/Translation';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@components/common';
import { FC, useContext, useMemo } from 'react';
import { Typography, Card, Box, TypographyProps } from '@mui/material';
import { AnalysisResultContext } from '../analysis-result-context';
import { DataTestId } from '@utils/DataTestId';
import { NonTargetedResultUnitMapping } from './non-targeted-field-unit-mapping';
import { QuantitativeNonTargetedCompoundResult } from '@services/nmr';

const Cell: FC<TypographyProps> = ({ sx, ...props }) => (
	<Typography variant="pg-m" style={{ textAlign: 'left' }} sx={{ textAlign: 'center', wordBreak: 'break-word', ...sx }} {...props} />
);

const getTableValue = (val: unknown) => {
	if (!val) return undefined;

	if (isNaN(Number(val))) return undefined;

	if (Number(val) === 0) return undefined;

	return val;
};

export const QuantitativeNonTargetedSuccessfullResult: FC = () => {
	const { t } = useTranslation('portal');
	const { analysisResultData } = useContext(AnalysisResultContext);

	const columns = useMemo<Column[]>(
		() => [
			{
				accessor: 'compoundName',
				disableSortBy: true,
				Cell: ({ value, row }) => <Cell data-testid={DataTestId.getStaticTestId(`compoundName-${row.index}`)}>{value || '-'}</Cell>,
				Header: <Tr.Portal path={`analysis-result.table.compound-name`} />,
			},
			{
				accessor: 'molecularWeight',
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<Cell data-testid={DataTestId.getStaticTestId(`molecularWeight-${row.index}`)}>
						{getTableValue(value) ? value : '-'} {getTableValue(value) ? NonTargetedResultUnitMapping.molecularWeight : ''}
					</Cell>
				),
				Header: <Tr.Portal path={`analysis-result.table.molecular-weight`} />,
			},
			{
				accessor: 'sampleWeight',
				disableSortBy: true,
				Cell: ({ row }) => (
					<Cell data-testid={DataTestId.getStaticTestId(`concentration-${row.index}`)}>
						{analysisResultData?.sampleWeight ? analysisResultData?.sampleWeight : '-'}{' '}
						{analysisResultData?.sampleWeight ? NonTargetedResultUnitMapping.sampleWeight : ''}
					</Cell>
				),
				Header: <Tr.Portal path={`analysis-result.table.sample-weight`} />,
			},
			{
				accessor: 'solventWeight',
				disableSortBy: true,
				Cell: ({ row }) => (
					<Cell data-testid={DataTestId.getStaticTestId(`solventWeight-${row.index}`)}>
						{getTableValue(analysisResultData?.solventWeight) ? analysisResultData?.solventWeight : '-'}{' '}
						{getTableValue(analysisResultData?.solventWeight) ? NonTargetedResultUnitMapping.solventWeight : ''}
					</Cell>
				),
				Header: <Tr.Portal path={`analysis-result.table.solvent-weight`} />,
			},
			{
				accessor: 'massFraction',
				disableSortBy: true,
				Cell: ({ value, row }) => {
					const showMassFraction =
						(value || value === 0) && getTableValue((row.original as QuantitativeNonTargetedCompoundResult).molecularWeight);

					return (
						<Cell data-testid={DataTestId.getStaticTestId(`concentration-${row.index}`)}>
							{showMassFraction ? `${value} ` : '-'}
							{showMassFraction ? NonTargetedResultUnitMapping.massFraction : ''}
						</Cell>
					);
				},
				Header: <Tr.Portal path={`analysis-result.table.mass-fraction`} />,
			},
			{
				accessor: 'concentration',
				disableSortBy: true,
				Cell: ({ value, row }) => (
					<Cell data-testid={DataTestId.getStaticTestId(`concentration-${row.index}`)}>
						{value || '-'} {(row.original as QuantitativeNonTargetedCompoundResult).unitOfConcentration || ''}
					</Cell>
				),
				Header: t('analysis-result.concentration'),
			},
		],
		[],
	);

	return (
		<Card variant="elevation" sx={{ padding: '2rem 1.5rem 2.5rem' }}>
			<Typography variant="h3" mb={1.5} sx={{ color: 'success.main' }}>
				{t('analysis-result.analysis-results')}
			</Typography>
			<Typography paragraph mb={3} variant="label-m" sx={{ color: 'grey.800' }}>
				{t('analysis-result.quantitative-non-targeted')}
			</Typography>
			<Typography paragraph mb={3} variant="pg-m" sx={{ color: 'grey.800' }}>
				{t('analysis-result.quantitative-non-targeted-text', { key: analysisResultData?.nmrAnalysis.title })}
			</Typography>
			<Box
				sx={{
					border: '1px solid',
					height: 'fit-content',
					borderRadius: '0.5rem',
					borderColor: 'grey.200',
				}}
			>
				<DataTable
					data={
						analysisResultData?.results?.sort((a, b) =>
							a.compoundName.toLowerCase() < b.compoundName.toLowerCase() ? -1 : 1,
						) ?? []
					}
					columns={columns}
					sx={{
						'& .MuiTableBody-root 	.MuiTableRow-root': {
							'&:last-child': {
								'& .MuiTableCell-root': {
									borderBottom: 'none',
								},
							},
							'& .MuiTableCell-root': {
								padding: '0 1rem',
							},

							height: '2.5rem',
						},
						'& .MuiTableRow-head': {
							'& .MuiTableCell-head': {
								padding: '0.7rem 1rem',
							},
						},
					}}
				/>
			</Box>
		</Card>
	);
};
