import { FC, useEffect, useState } from 'react';

import { IrResultImpurites, RmSpectrumInfoPurity, SampleSpectrumInfoPurity } from '@services';
import { SpectraColorCodes, Tr, getDynamicGraphLayout, getTransMissionData, mixtureColorCodes } from '@utils';

import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { useTranslation } from 'react-i18next';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IStackedSpectrum {
	subtitleVisibility?: boolean;
	rankedRMData: RmSpectrumInfoPurity;
	userSpectrumInfo: SampleSpectrumInfoPurity;
	chartType?: string;
	resultImpurities?: IrResultImpurites[];
}

export const IrStackedSpectrumPurityCheck: FC<IStackedSpectrum> = ({
	subtitleVisibility,
	rankedRMData,
	userSpectrumInfo,
	resultImpurities,
}) => {
	const chartClassName = `c-overlay-purityCheck-chart`;

	const [chartDataList, setChartDataList] = useState<any>([]);
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};

	const { t } = useTranslation('irportal');
	const [layout, setLayout] = useState<any>();
	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			//set user spectrum
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.sample-spectrum'),
				yaxis: 'y',
			};
			chartDataAry.push(userChartData);

			const targetSubstanceData = {
				y: displaySettingsToggle ? getTransMissionData([...rankedRMData.spectrum_y]) : rankedRMData.spectrum_y,
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: t('analysis-result.target-substance-spectrum'),
				yaxis: 'y2',
			};
			chartDataAry.push(targetSubstanceData);

			if (resultImpurities) {
				resultImpurities.map((item, i) => {
					const impurityData = item && item?.impuritySpectrumData;
					const substanceName = item?.substanceName || '';
					if (impurityData) {
						const impurityChartData = {
							y: displaySettingsToggle ? getTransMissionData([...impurityData['y']]) : impurityData['y'],
							x: userSpectrumInfo?.sample_x,
							type: 'scatter',
							mode: 'lines',
							marker: { color: mixtureColorCodes[`${i}`] },
							direction: 'counterclockwise',
							name: substanceName,
							yaxis: 'y' + (3 + i),
						};

						chartDataAry.push(impurityChartData);
					}
				});
			}
			const layoutConfig = getDynamicGraphLayout(chartDataAry, userSpectrumInfo);
			setLayout(layoutConfig);
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle, resultImpurities]);
	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && userSpectrumInfo && rankedRMData && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={chartClassName}
					enableZoom={false}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={layout}
				/>
			)}
		</Stack>
	);
};
