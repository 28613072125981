import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { LanguageCode, DEFAULT_LANGUAGE, AppNotification, NotificationType, UserStoreModel, Flag } from '@models';
import { SxProps } from '@mui/material';
import { SupportedTechnology } from '@services/nmr/nmr-analysis/technology.enum';

export interface ICommonState {
	user?: Maybe<UserStoreModel>;
	language: LanguageCode;
	notifications?: Maybe<AppNotification[]>;
	breadcrumb?: any;
	flags: Flag;
	refreshUser: Maybe<number>;
	pageBackgroundSx: Maybe<SxProps>;
	urlHistory: string[];
	activeTechnology: SupportedTechnology | null;
}

const initialState: ICommonState = {
	user: null,
	language: DEFAULT_LANGUAGE.code,
	breadcrumb: null,
	flags: {},
	refreshUser: 1,
	pageBackgroundSx: null,
	urlHistory: ['/'],
	activeTechnology: null,
};

export const {
	actions: {
		setUser,
		setLanguage,
		setBannerNotifications,
		setBreadcrumb,
		setFlags,
		setRefreshUser,
		setPageBackgroundSx,
		setUrlHistory,
		setActiveTechnology,
	},
	reducer,
} = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setUser: (state, data: PayloadAction<Maybe<UserStoreModel>>) => {
			state.user = data.payload ? { ...data.payload } : null;
		},

		setLanguage: (state, data: PayloadAction<LanguageCode>) => {
			state.language = data.payload || DEFAULT_LANGUAGE.code;
		},
		setBannerNotifications: (state, data: PayloadAction<Maybe<AppNotification[]>>) => {
			const appNotifications = data.payload ?? [];
			const resultNotifications: AppNotification[] = [];
			const notificationOrder = [
				NotificationType.SubscriptionExpired,
				NotificationType.ReachedTheCountLimitOfAnalysis,
				NotificationType.SubscriptionOneWeekRemained,
				NotificationType.ReachedNinetyPercentOfAnalysis,
			];
			const notifications = appNotifications?.filter((x) => x.isPermanent);
			for (const notificationType of notificationOrder) {
				const found = notifications?.find((n) => n.notificationType === notificationType);
				if (found) {
					resultNotifications.push(found);
					break;
				}
			}

			state.notifications = resultNotifications;
		},
		setBreadcrumb: (state, data: PayloadAction<any>) => {
			state.breadcrumb = data.payload;
		},
		setFlags: (state, data: PayloadAction<Maybe<Flag>>) => {
			if (data.payload) {
				state.flags = { ...state.flags, ...data.payload };
			}
		},
		setRefreshUser: (state) => {
			state.refreshUser = state.refreshUser ? state.refreshUser + 1 : 1;
			return;
		},
		setPageBackgroundSx: (state, data: PayloadAction<Maybe<any>>) => {
			state.pageBackgroundSx = data.payload;
			return;
		},
		setUrlHistory: (state, data: PayloadAction<any>) => {
			state.urlHistory = data.payload;
		},

		setActiveTechnology: (state, data: PayloadAction<SupportedTechnology | null>) => {
			state.activeTechnology = data.payload || null;
		},
	},
});

export const userSelector = (state: RootState) => state.Common.user;
export const languageSelector = (state: RootState) => state.Common.language || DEFAULT_LANGUAGE.code;
export const subscriptionNotificationsSelector = (state: RootState) => state.Common.notifications;
export const breadcrumbSelector = (state: RootState) => state.Common.breadcrumb;
export const flagsSelector = (state: RootState) => state.Common.flags;
export const refreshUserSelector = (state: RootState) => state.Common.refreshUser;
export const pageBackgroundSxSelector = (state: RootState) => state.Common.pageBackgroundSx;
export const urlHistorySelector = (state: RootState) => state.Common.urlHistory;
export const activeTechnologySelector = (state: RootState) => state.Common.activeTechnology;
