export interface ILoginUserModel {
	user?: any;
	oldPassword?: string;
	firstName?: string;
	lastName?: string;
	newPassword?: string;
	phoneNumber?: string;
	email?: string;
	maskedEmail?: string;
	confirmPassword?: string;
}

export enum LoginUserErrors {
	COMPROMISED_PASSWORD = 'Provided password cannot be used for security reasons.',
	SESSION_EXPIRED = 'Invalid session for the user, session is expired.',
}
