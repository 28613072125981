import { OverflowText } from '@components/common';
import { invalidValues } from '@components/ir-portal';
import { Grid, Link } from '@mui/material';
import { FC } from 'react';

export const DrmRow: FC<{ label: string; result?: string | number; isActive?: boolean; unit?: string; showProductNum?: boolean }> = ({
	label,
	result,
	isActive = true,
	unit,
	showProductNum,
}) => {
	return (
		<Grid item xs={12}>
			<Grid container alignItems="center" columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 16 }}>
				<Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
					<OverflowText enableTooltip variant="pg-m" width="100%" color={isActive ? 'text.primary' : 'grey.500'}>
						{label}
					</OverflowText>
				</Grid>
				{!showProductNum ? (
					<Grid item xs={12} sm={4} md={6} lg={7} xl={11}>
						<OverflowText variant="pg-m" enableTooltip color={isActive ? 'grey.800' : 'grey.500'} width="100%">
							{!result || invalidValues.includes(result) ? '-' : result}
							{result && unit ? unit : ''}
						</OverflowText>
					</Grid>
				) : (
					<Grid item xs={12} sm={4} md={6} lg={7} xl={11}>
						<Link
							underline="hover"
							variant="body1"
							href={`https://www.sigmaaldrich.com/US/en/product/sial/${result}`}
							sx={{ cursor: 'pointer' }}
							target="_blank"
						>
							{result}
						</Link>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};
