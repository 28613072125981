import { GenericCard } from '@components/common';
import { LabSettingsSwitch } from '@components/common/CommonLabDetails/LabSettingsSwitch';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import { authService, notificationService, organizationService } from '@services/core';
import { setRefreshUser } from '@store/slices/common/common.slice';
import { RxUtils } from '@utils/Rx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
interface IMFAInfoTitleProps {
	title: string;
	sx?: SxProps;
}

interface UserMFADetails {
	mfaEnabled: boolean;
	setMfaStatus: (value: boolean) => void;
	organizationId: string;
}
export const UserMFADetails: FC<UserMFADetails> = ({ mfaEnabled, setMfaStatus, organizationId }) => {
	const { t } = useTranslation('common');
	const dispatch = useDispatch();

	const MFAInfoTitle: FC<IMFAInfoTitleProps> = ({ title, sx }) => {
		return (
			<Typography
				variant="label-m"
				color={mfaEnabled ? 'text.primary' : 'grey.500'}
				sx={{ paddingBottom: 1, fontWeight: 600, ...sx }}
			>
				{title}
			</Typography>
		);
	};
	const InfoDivider = () => {
		return <Divider orientation="vertical" sx={{ color: 'red' }} flexItem />;
	};

	const handleSettingStatusChange = (status: boolean) => {
		RxUtils.promisify(
			organizationService.updateMfaSettings({
				id: +organizationId,
				mfaEnabled: status,
			}),
			() => {
				authService.refreshToken(() => {
					dispatch(setRefreshUser());
				});
				setMfaStatus(status);
				notificationService.sendSuccess(
					status ? t('user-management.mfa-info.enable-message') : t('user-management.mfa-info.disable-message'),
				);
			},
			({ Message }) => {
				notificationService.sendError(Message);
			},
		);
	};

	return (
		<Stack>
			<GenericCard>
				<Stack direction="row" spacing={3} width={'80%'}>
					<Stack direction="row" spacing={3} sx={{ flex: 1.5 }}>
						<Stack direction="column">
							<MFAInfoTitle
								title={t('user-management.mfa-info.two-factor-authentication')}
								sx={{ color: 'text.primary', pb: 2 }}
							/>
							<LabSettingsSwitch
								isDisabled={false}
								status={mfaEnabled}
								onStatusChange={(status) => handleSettingStatusChange(status)}
								tooltipTitle={''}
								isLabDeactive={false}
							/>
						</Stack>
					</Stack>
					<InfoDivider />
					<Stack direction="column" justifyContent="flex-start" sx={{ flex: 2 }}>
						<MFAInfoTitle title={t('user-management.mfa-info.text-title')} />
						<Typography variant="pg-m" color={mfaEnabled ? 'grey.800' : 'grey.500'}>
							{t('user-management.mfa-info.text-desc')}
						</Typography>
					</Stack>
					<InfoDivider />
					<Stack direction="column" justifyContent="flex-start" sx={{ flex: 2 }}>
						<MFAInfoTitle title={t('user-management.mfa-info.auth-title')} />
						<Typography variant="pg-m" color={mfaEnabled ? 'grey.800' : 'grey.500'}>
							{t('user-management.mfa-info.auth-desc')}
						</Typography>
					</Stack>
				</Stack>
			</GenericCard>
		</Stack>
	);
};
