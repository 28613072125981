import { OverflowText, PortalBadge, QualityGradeChip } from '@components/common';
import { useAnalysis } from '@hooks';
import { useMemoizedTestId } from '@hooks';
import { QualityGrade } from '@models/reference-material';
import {
	Box,
	Button,
	ButtonProps,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableHead,
	TableRow,
	Typography,
	TypographyProps,
} from '@mui/material';
import { NmrDrmDetail } from '@services/nmr';
import { checkReferenceMaterialNew } from '@utils/ReferenceMaterial';
import { Tr } from '@utils/Translation';
import { FC, useRef } from 'react';
import { NmrReferenceMaterialFavorite } from '../NmrFavorites/NmrReferenceMaterialFavorite/NmrReferenceMaterialFavorite';

const tableLabelStyle: TypographyProps['sx'] = {
	fontWeight: 700,
	marginRight: 1,
	fontSize: '14px',
	whiteSpace: 'nowrap',
	color: 'text.primary',
};

const textStyle: TypographyProps['sx'] = {
	fontWeight: 400,
	fontSize: '14px',
	wordBreak: 'break-word',
	color: 'text.primary',
};

const cellStyle: TableCellProps['sx'] = {
	paddingLeft: 0,
	paddingY: 1,
	pr: 2.5,
};

interface ISubstanceTableItemProps {
	referenceMaterial: NmrDrmDetail;
	type?: 'add' | 'remove';
	actionLabel?: string;
	onDrmDetail?: (visible: boolean, detail: NmrDrmDetail) => void;
	onDrmAdd?: () => void;
	onDrmRemove?: () => void;
	onRefreshSubstanceList?: () => void;
	actionProps?: ButtonProps;
}

const DRMTableField: FC<ISubstanceTableItemProps> = ({
	referenceMaterial,
	onDrmDetail,
	type,
	onDrmAdd,
	onDrmRemove,
	actionLabel,
	actionProps,
	onRefreshSubstanceList,
	...props
}) => {
	const { solvent, frequency, qualityGrade, createTime, ermProductNumber, id, favoriteId, nmrReferenceMaterialLibrary } =
		referenceMaterial;
	const isNew = checkReferenceMaterialNew(createTime);
	const rowRef = useRef<HTMLTableRowElement>(null);
	const dataTestId = useMemoizedTestId('substance-list-', true);
	const { addedERMs } = useAnalysis();
	const getActionButton = () => {
		if (type === 'add' && onDrmAdd) {
			return (
				<Button
					variant="contained"
					data-testid={`substance-list-add-button-${dataTestId}`}
					size="small"
					disableElevation
					onClick={onDrmAdd}
					sx={{ ml: 1.5 }}
					{...actionProps}
					disabled={actionProps?.disabled || addedERMs?.some((drm) => drm.selectedReferenceMaterials?.some((rm) => rm.id === id))}
				>
					{actionLabel}
				</Button>
			);
		} else if (type === 'remove' && onDrmRemove) {
			return (
				<Button
					variant="outlined"
					data-testid={`substance-list-remove-button-${dataTestId}`}
					size="small"
					disableElevation
					onClick={() => onDrmRemove()}
					sx={{ ml: 1.5 }}
					{...actionProps}
				>
					{actionLabel}
				</Button>
			);
		}
		return null;
	};
	return (
		<TableRow
			ref={rowRef}
			sx={{
				width: '100%',
				'&:last-child td': {
					borderBottom: 0,
				},
			}}
		>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1, width: '24px' }}>
				<NmrReferenceMaterialFavorite
					drmCode={ermProductNumber}
					onSuccess={() => onRefreshSubstanceList?.()}
					favoriteId={favoriteId}
				/>
			</TableCell>
			<TableCell sx={{ ...cellStyle, width: '18%', overflow: 'hidden' }}>
				{ermProductNumber && (
					<Box width={(rowRef.current?.clientWidth ?? 1350) * 0.11}>
						<OverflowText enableTooltip data-testid={`${props['data-testid']}-prod-num`} sx={{ ...textStyle }}>
							{ermProductNumber}
						</OverflowText>
					</Box>
				)}
			</TableCell>

			<TableCell sx={{ ...cellStyle, width: '13%' }}>
				<QualityGradeChip qualityGrade={qualityGrade ?? QualityGrade.DRM} isReferenceActive={true} />
			</TableCell>

			<TableCell sx={{ ...cellStyle, width: '13%' }}>
				<Typography sx={{ ...textStyle }}>{nmrReferenceMaterialLibrary || '-'}</Typography>
			</TableCell>
			<TableCell sx={{ ...cellStyle, width: '13%' }}>
				<Typography sx={{ ...textStyle }}>{solvent || '-'}</Typography>
			</TableCell>

			<TableCell sx={{ ...cellStyle, width: '10%' }}>
				<Typography sx={{ ...textStyle, maxWidth: '150px' }}>{frequency || '-'}</Typography>
			</TableCell>

			<TableCell sx={{ ...cellStyle, alignItems: 'start' }}>
				{isNew && <PortalBadge color="warning" badgeContent={<Tr.Common path="new" />} />}
			</TableCell>
			<TableCell sx={{ paddingLeft: 0, paddingY: 1, textAlign: 'right', pr: 0, width: 'auto' }}>
				<Stack direction="row-reverse">
					{getActionButton()}
					<Button
						data-testid={`${props['data-testid']}-view-details`}
						sx={{
							padding: 0.75,

							':hover': {
								backgroundColor: 'transparent',
							},
						}}
						disableElevation
						variant="outlined"
						size="small"
						onClick={() => onDrmDetail?.(true, referenceMaterial)}
					>
						<Tr.Portal path="new-analysis.view-details" />
					</Button>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

interface ISubstanceTableProps {
	referenceMaterials: NmrDrmDetail[];
	onDrmDetail?: (visible: boolean, detail: NmrDrmDetail) => void;
	type?: 'add' | 'remove';
	actionLabel?: string;
	onDrmAdd?: (substanceId: number) => void;
	onDrmRemove?: (substance: number) => void;
	onRefreshSubstanceList?: () => void;
	actionProps?: ButtonProps;
}
export const SubstanceTable: FC<ISubstanceTableProps> = ({
	referenceMaterials,
	onDrmDetail,
	type,
	actionLabel,
	onDrmAdd,
	onDrmRemove,
	onRefreshSubstanceList,
	actionProps,
}) => {
	const tableTestId = useMemoizedTestId('table-id', true);
	return (
		<Table sx={{ marginTop: 2, borderTop: '2px solid #E1E1EA' }}>
			<TableHead>
				<TableRow
					sx={{
						width: '100%',
						'&:last-child td': {
							borderBottom: 0,
						},
						'&:first-child td': {
							maxWidth: '24px',
							width: '24px',
							overflow: 'hidden',
						},
					}}
				>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography></Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography sx={{ ...tableLabelStyle }}>
							<Tr.Portal path="drm-detail.reference-code" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography sx={{ ...tableLabelStyle }}>
							<Tr.Portal path="drm-detail.quality-grade" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography sx={{ ...tableLabelStyle }}>
							<Tr.Portal path="drm-detail.library" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography sx={{ ...tableLabelStyle }}>
							<Tr.Portal path="new-analysis.solvent" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0, maxWidth: '15%' }}>
						<Typography sx={{ ...tableLabelStyle, maxWidth: '150px' }}>
							<Tr.Portal path="drm-detail.frequency-header" />
						</Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography></Typography>
					</TableCell>
					<TableCell sx={{ paddingLeft: 0 }}>
						<Typography></Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{referenceMaterials?.map((drm, index) => (
					<DRMTableField
						key={drm.id}
						onDrmDetail={onDrmDetail}
						type={type}
						actionLabel={actionLabel}
						referenceMaterial={drm}
						onDrmAdd={onDrmAdd ? () => onDrmAdd(drm.id) : undefined}
						onDrmRemove={onDrmRemove ? () => onDrmRemove(drm.id) : undefined}
						onRefreshSubstanceList={onRefreshSubstanceList}
						actionProps={{
							...actionProps,
							disabled: actionProps?.disabled,
						}}
						data-testid={`data-table-${tableTestId}-${index}`}
					/>
				))}
			</TableBody>
		</Table>
	);
};
