import { Currency } from '@models/subscribe';
import { Stack, Palette, Typography } from '@mui/material';
import { FC } from 'react';
import { BudgetConsumptionDetails } from '../SubscriptionProgress';

interface ITechnologyConsumptionChip {
	content: BudgetConsumptionDetails;
	currency?: Currency;
	technologyFieldPrice?: number;
	remainingAnalysis?: string;
}

export const TechnologyConsumptionChip: FC<ITechnologyConsumptionChip> = ({
	content,
	currency,
	technologyFieldPrice = 1,
	remainingAnalysis,
}) => {
	const analysisValue = content.consumed / technologyFieldPrice;
	const formattedAnalysis = analysisValue % 1 === 0 ? analysisValue : analysisValue.toFixed(2);
	const prepaidPlan = content.name !== 'Used' && currency;
	return (
		<Stack
			direction="row"
			sx={(theme) => {
				const [colorKey, shade] = content.color?.split('.') || [];
				const borderColor =
					(colorKey && shade && theme.palette[colorKey as keyof typeof theme.palette]?.[shade as keyof Palette]) ||
					content.color ||
					theme.palette.primary.main;
				return {
					alignItems: 'center',
					border: '1px solid',
					borderColor: 'grey.400',
					borderRadius: '4px',
					paddingX: 1,
					paddingY: 0.5,
					borderLeft: `8px solid ${borderColor}`,
					height: '100%',
				};
			}}
		>
			<Stack direction={prepaidPlan ? 'column' : 'row'} sx={{ textAlign: 'left', gap: 1, minWidth: '100px' }}>
				<Typography variant="label-s" color="grey.800">
					{content.name}:
				</Typography>
				<Typography color="grey.800" variant="label-s">{`${content.consumed} ${currency || ''}`}</Typography>
				{prepaidPlan && (
					<Typography variant="label-s" color="grey.800" fontWeight="400" sx={{ display: 'inline', flexWrap: 'nowrap' }}>
						{`${remainingAnalysis || formattedAnalysis + ' Analysis'}`}
					</Typography>
				)}
			</Stack>
		</Stack>
	);
};
