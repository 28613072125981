import { FC, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Stack, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield } from '@components/common';
import { useTranslation } from 'react-i18next';
import { RxUtils } from '@utils/Rx/RxUtils';
import { IrDevice, irDeviceTypes, irDeviceService } from '@services/ir';

interface IDeviceListForIr {
	name: string;
	formik: FormikApiType;
	title?: string;
	required?: boolean;
	typographyProps?: any;
	disabled?: boolean;
}

export const DeviceListForIr: FC<IDeviceListForIr> = ({ name, title, required, typographyProps, formik, disabled = false }) => {
	const [deviceData, setDeviceData] = useState<IrDevice[]>([]);
	const [drophidden, setDropHidden] = useState(false);
	const { t } = useTranslation('irportal');

	useEffect(() => {
		const params = { deviceKey: irDeviceTypes.CALIBRATED };
		const searchParams = new URLSearchParams(params);
		RxUtils.promisify(irDeviceService.getDevices(searchParams), (data) => {
			setDeviceData(data);
		});
	}, []);
	const defaultTitle = () => (
		<Stack direction="row">
			<Typography
				variant="label-s"
				className={required ? 'required' : ''}
				color={disabled ? 'grey.200' : 'grey.800'}
				display="inline"
			>
				{t('new-analysis.measurement-device')}
			</Typography>
		</Stack>
	);
	const onDeviceChange = (e: any) => {
		setDropHidden(true);
		formik.setFieldValue(name, e.target.value);
		formik.setFieldValue('deviceId', e.target.value);
	};

	return (
		<Stack direction="column" sx={{ marginBottom: 1 }}>
			<FormControl>
				<InputLabel sx={{ display: drophidden ? 'none' : 'initial', top: '1.2rem', opacity: 0.5 }}>
					<Typography variant="label-m" color="grey.800">
						{!formik.values[`${name}`] ? t('new-analysis.device-placeholder') : null}
					</Typography>
				</InputLabel>

				<FormikTextfield
					formikApi={formik}
					name={name}
					select
					required={false}
					disabled={disabled}
					value={formik.values[`${name}`] || ''}
					onChange={onDeviceChange}
					placeholder="device"
					title={title ?? defaultTitle()}
					variant="outlined"
					typographyProps={typographyProps}
					fullWidth
					type="number"
					sx={{
						backgroundColor: 'grey.50',
						'& .MuiOutlinedInput-root': {
							height: '40px',
						},
					}}
				>
					{deviceData.map((item, index) => (
						<MenuItem
							key={`index-${index}}`}
							value={item.id}
							sx={{ height: '40px' }}
							data-testid={`${index}-measurementDevice-test-id`}
						>
							{item.deviceName}
						</MenuItem>
					))}
				</FormikTextfield>
			</FormControl>
		</Stack>
	);
};
