import { FormikApiType } from '@components/common';
import { UserRole } from '@models/user';
import { Checkbox, MenuItem, Select, Stack, Typography, IconButton, SelectProps } from '@mui/material';
import { Tr } from '@utils/Translation';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptionConfig, SetConfigMapping } from './MultipleRoleSelector.config';
import { DataTestId } from '@utils/DataTestId';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TypeUtils } from '@utils/Type';
interface IMultipleRoleSelector {
	formik: FormikApiType;
	name: string;
	set?: string;
	optionSet?: string[];
}
export const MultipleRoleSelector: FC<IMultipleRoleSelector & SelectProps> = ({ formik, name, set, optionSet = [], ...props }) => {
	const { t } = useTranslation('common');

	const { config, options } = useMemo(() => {
		let setConfig;
		if (set) {
			setConfig = TypeUtils.returnValueOfKey<typeof SetConfigMapping, IOptionConfig>(set, SetConfigMapping);
		}
		return (
			setConfig ?? {
				options: optionSet,
				config: {
					[UserRole.SYSTEM_ADMIN]: {
						alwaysDisabled: true,
					},
				},
			}
		);
	}, [set]);
	return (
		<Select
			data-testid="multiple-role-selector-id"
			multiple
			value={formik.values[`${name}`]?.length > 0 ? formik.values[`${name}`] : [t('roles.select-role')]}
			sx={{
				padding: '0px 6px',
				minHeight: '2.5rem',
				display: 'flex',
				flexWrap: 'wrap',
				'& .MuiSelect-select': {
					display: 'flex',
					flexWrap: 'wrap',
					padding: '6px 8px',
					color: 'black',
				},
				'& .MuiSvgIcon-root': {
					color: !props.disabled ? 'primary.main' : undefined,
				},
			}}
			onChange={(event) => {
				const filterEventTarget = event.target.value.filter((i: string) => i !== t('roles.select-role'));
				formik.setFieldValue(name, filterEventTarget).then(() => formik.validateForm());
			}}
			renderValue={(value) => (
				<Stack direction="row" height={'100%'} marginTop="auto" alignItems="center">
					<Typography
						variant="label-m"
						color="text.primary"
						sx={{
							whiteSpace: 'normal',
							wordWrap: 'break-word',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: 'block',
						}}
					>
						{value
							.map((role: string) => (role === t('roles.select-role') ? t('roles.select-role') : t(`roles.${role}`)))
							.join(', ') ?? t('roles.select-role')}
					</Typography>
				</Stack>
			)}
			fullWidth
			onBlur={() => formik.validateForm()}
			inputProps={{ 'data-testid': DataTestId.getStaticTestId('role-selector-test-id') }}
			IconComponent={(params) => (
				<IconButton
					sx={{
						position: 'absolute',
						pointerEvents: 'none',
						right: 0,
					}}
				>
					<KeyboardArrowDownIcon {...params} />
				</IconButton>
			)}
			{...props}
		>
			{options.map((item: string) => (
				<MenuItem
					key={`role-selector-${item}`}
					data-testid={DataTestId.getStaticTestId(`role-selector-${item}`)}
					value={item}
					sx={{ padding: 0, borderBottom: '1px solid #E1E1EA' }}
					disabled={
						config[`${item}`]?.alwaysDisabled ||
						formik.values[`${name}`]?.some((role: UserRole) => config?.[`${item}`]?.disabledOnChoosen?.includes(role))
					}
				>
					<Checkbox
						checked={formik.values[`${name}`]?.includes(item)}
						sx={{ paddingLeft: '0.75rem' }}
						disabled={
							config[`${item}`]?.alwaysDisabled ||
							formik.values[`${name}`]?.some((role: UserRole) => config?.[`${item}`]?.disabledOnChoosen?.includes(role))
						}
					/>
					<Tr.Common path={`roles.${item}`} />
				</MenuItem>
			))}
		</Select>
	);
};
