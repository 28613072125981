import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormikTextfield, GenericCard, InfoIconOutlined, ModalDialog } from '@components/common';
import { Box, Button, Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import MuiOutlinedInfoIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';
import { UserRole } from '@models/user';

interface IRejectAnalysisProps {
	visible: boolean;
	analysisId: number;
	onCancel: () => void;
	onReject: (decision: boolean, comment: string, analysisError: boolean) => void;
}

type FormFields = Extendable<{
	comment: string;
	decision: boolean;
	analysisError: boolean;
}>;

const SummaryRecordWarning = () => {
	const heights = '0.875rem';
	return (
		<Stack direction="row" alignItems="center" spacing={0.75} height={heights}>
			<InfoIconOutlined
				sx={{ width: heights, minWidth: heights, height: heights, marginTop: 0, overflow: 'hidden' }}
				iconSx={{ fontSize: 8 }}
			/>
			<Typography variant="label-s" lineHeight="0.875rem">
				<Tr.IrPortal path="analysis-result.stay-on-record" />
			</Typography>
		</Stack>
	);
};

const UserInfoRow: FC<{ title: string; value: string }> = ({ title, value }) => {
	return (
		<Stack>
			<Typography variant="pg-s">{title}</Typography>
			<Typography variant="pg-s" overflow="break-word" color="primary.main">
				{value}
			</Typography>
		</Stack>
	);
};

const AnalysisRejectionUserInfo = () => {
	const { t } = useTranslation('portal');
	const user = useSelector(userSelector);

	const userRole = user?.roles?.map((r) => t(`user-management.roles.${r}`)).join(', ') || UserRole.LAB_MEMBER;
	return (
		<Stack direction="row" justifyContent="space-between" spacing={3}>
			<UserInfoRow title={t('analysis-result.user-signature')} value={user?.name ? `${user.name} ${user.surname}` : ''} />
			<UserInfoRow title={t('analysis-result.user-role')} value={user?.roles ? t(userRole) : ''} />
		</Stack>
	);
};

export const IrRejectAnalysis: FC<IRejectAnalysisProps> = ({ visible, onCancel, onReject }) => {
	const { t } = useTranslation('irportal');
	const checkRef = useRef<HTMLInputElement>(null);

	const formik = useFormik<FormFields>({
		initialValues: {
			comment: '',
			sendReview: false,
			analysisError: false,
		},
		validationSchema: yup.object({
			comment: yup.string().required(t('analysis-result.rejection-reason')),
		}),
		onSubmit: ({ comment, analysisError }) => {
			onReject(false, comment, analysisError);
		},
	});

	useEffect(() => {
		formik?.resetForm();
	}, [visible]);

	const showSummaryWarning = !!formik?.values?.comment && !formik?.errors?.comment;
	return (
		<ModalDialog
			width={472}
			onClose={onCancel}
			title={
				<Typography variant="pg-s">
					<Tr.IrPortal path="analysis-result.reject-analysis" />
				</Typography>
			}
			titleProps={{ sx: { paddingBottom: 0 } }}
			onBackdropClick={onCancel}
			open={visible}
			variant="secondary"
		>
			<form onSubmit={formik?.handleSubmit}>
				<GenericCard
					dataTestId="reject-card"
					sx={{ overflow: 'auto', border: 'none', paddingTop: 4, paddingBottom: 7, paddingX: 8 }}
				>
					<Stack spacing={4}>
						<Stack sx={{ mb: 3 }} textAlign="center">
							<Typography variant="h3">
								<Tr.IrPortal path="analysis-result.reject-analysis-reason" />
							</Typography>
						</Stack>

						<Stack spacing={4}>
							<Stack spacing={1.25}>
								<FormikTextfield
									sx={{ backgroundColor: 'grey.50' }}
									InputProps={{
										sx: {
											'& 	.MuiInputBase-input': {
												fontSize: 16,
											},
											paddingY: 1.25,
											paddingX: 1.5,
											'& + .MuiFormHelperText-root.Mui-error': {
												bottom: '-24px',
												top: 'unset',
											},
										},
									}}
									formikApi={formik}
									multiline
									rows={5}
									name="comment"
									title={
										<Typography variant="label-s">
											<Tr.IrPortal path="analysis-result.reason" />
											<span style={{ color: '#E61E50' }}>*</span>
										</Typography>
									}
									placeholder={t('analysis-result.rejection-comment')}
								/>
								{showSummaryWarning && <SummaryRecordWarning />}
							</Stack>

							<Stack>
								<Box sx={{ pl: 2, pr: 1, mt: 1, border: '1px solid', borderColor: 'grey.200', borderRadius: 1 }}>
									<Stack justifyContent="space-between" alignItems="center" direction="row">
										<Stack>
											<FormControlLabel
												sx={{ width: 'fit-content' }}
												control={<Checkbox inputRef={checkRef} size="small" />}
												label={
													<Typography variant="pg-s">
														<Tr.IrPortal path="analysis-result.report-issue" />
													</Typography>
												}
											/>
										</Stack>
										<Stack>
											<Tooltip
												title={<Tr.IrPortal path="analysis-result.reject-info-tooltip" />}
												placement="top"
												arrow
											>
												<MuiOutlinedInfoIcon style={{ color: 'grey', width: '16px', cursor: 'pointer' }} />
											</Tooltip>
										</Stack>
									</Stack>
								</Box>
							</Stack>

							<AnalysisRejectionUserInfo />
						</Stack>
					</Stack>

					<Stack direction="row" width={1} columnGap={1.5} sx={{ marginTop: 4 }}>
						<Button data-testid="cancel-button-id" variant="outlined" sx={{ width: 1 }} onClick={onCancel}>
							<Tr.IrPortal path="analysis-result.cancel" />
						</Button>
						<Button
							data-testid="reject-button-id"
							variant="contained"
							sx={{ width: 1 }}
							disableElevation
							type="submit"
							onClick={() => {
								formik.setFieldValue('analysisError', !!checkRef.current?.checked);
							}}
						>
							<Tr.IrPortal path="analysis-result.save" />
						</Button>
					</Stack>
				</GenericCard>
			</form>
		</ModalDialog>
	);
};
