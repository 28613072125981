export enum LoginStep {
	LOGIN_STEP = 'loginStep',
	REGISTER_STEP = 'registerStep',
	PHONE_NUMBER = 'phoneNumber',
	MFA = 'mfa',
	MFA_SELECTION = 'mfaSelection',
	RESET_PASSWORD = 'resetPassword',
	SET_NEW_PASSWORD = 'setNewPassword',
	RESET_SUCCESSFULLY = 'resetSuccessfully',
	USER_CONSENT = 'userConsent',
}

export enum MfaType {
	SMS = 'SMS',
	TOTP = 'TOTP',
}

export const MfaRules = {
	CODE_LENGTH: 6,
	ACCEPTED_CHARACTERS: /^[0-9]$/, //single number
	ACCEPTED_CODE: /^[0-9]+$/, //only numbers
	isValid: (code: string) => code.length === MfaRules.CODE_LENGTH && MfaRules.ACCEPTED_CODE.test(code),
};

export const checkMfaType = (type: MfaType) => {
	return {
		isSMS: () => type === MfaType.SMS,
		isTOTP: () => type === MfaType.TOTP,
	};
};

export class UserMFAStatus {
	email: string;
	username: string;
	mfaMethods: string;
}
