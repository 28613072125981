import { SampleSpectrumInfoPurity } from '@services/ir';
import { SpectraColorCodes } from '@utils/Transmission';

export const getPeakHighligtShapes = (data): any => {
	const shapesArray: any = [];
	const peakData = data || [];
	peakData.map((item, index) => {
		shapesArray.push({
			type: 'rect',
			xref: 'x',
			// y-reference is assigned to the plot paper [0,1]
			yref: 'paper',
			x0: item?.peakMin,
			y0: 0,
			x1: item?.peakMax,
			y1: 1,
			fillcolor: 'rgb(220,240,231)',
			opacity: 0.7,
			line: {
				width: 1,
				color: 'rgb(65,175,126)',
			},
			label: {
				text: peakData.length > 1 ? `Peak ${index + 1}` : `Peak ${peakData.length - index}`,
				textposition: 'top center',
				yanchor: 'bottom',
			},
		});
	});

	return shapesArray;
};

export const getDynamicGraphLayout = (chartDataAry: any, userSpectrumInfo: SampleSpectrumInfoPurity) => {
	const layout = {
		margin: { pad: 0, t: 0, r: 50, b: 50, l: 50 },
		hovermode: 'x unified',
		legend: {
			traceorder: 'reversed',
			yanchor: 'bottom',
			y: 1.1,
			xanchor: 'center',
			x: 0.4,
			orientation: 'h',
			bgcolor: 'transparent',
			font: { color: SpectraColorCodes.RICH_BLUE, size: 12 },
		},
		xaxis: {
			showdividers: false,
			showline: true,
			zeroline: false,
			range: userSpectrumInfo.sample_x_range,
			gridcolor: SpectraColorCodes.GRAY,
			griddash: 'dot',
		},
	};

	const domainLength = Number((10 / chartDataAry?.length / 10).toFixed(2));
	let startingDomain = 0;
	let endingDomain = 0;

	chartDataAry.map((item, index) => {
		const yaxisName = item?.yaxis?.split('')?.slice(-1);
		const yaxisTitle = index === 0 ? 'yaxis' : `yaxis${yaxisName}`;
		if (index !== 0) {
			startingDomain = endingDomain;
		}
		endingDomain = Number((endingDomain + domainLength).toFixed(2));
		Object.assign(layout, {
			[`${yaxisTitle}`]: {
				domain: [startingDomain, index === 0 ? domainLength : endingDomain],
				showline: true,
				zeroline: false,
				showticklabels: false,
				griddash: 'dashdot',
			},
		});
	});

	return layout;
};
