import { IrPdfReportImpurityPeak, IrPeaksInfo, IrPeaksQuantificationInfo, IrReportImageModel } from '@services/ir';
import { ICordinate } from './IrDrawPurityQuantification';
import Plotly from 'plotly.js-dist-min';
import { getTransMissionData, mixtureColorCodes, SpectraColorCodes } from '@utils/Transmission';
import { IrAnalysisType } from '@models/analysis';

export function peakDataUtils(analysisResult, data) {
	const irResultAtom: IrPeaksInfo[] = [];
	const irResultAtomforQuantification: IrPeaksQuantificationInfo[] = [];
	const resultData = data['peack_json'];
	const referencePeakRatio = data['reference_peak_ratio'];
	const userPeakRatio = data['user_peak_ratio'];

	if (analysisResult?.irAnalysis?.type === IrAnalysisType.QUANTIFICATION) {
		resultData.map((item, index: number) => {
			const irResultAtomData: IrPeaksQuantificationInfo = {
				rmPeakXminXmax: item.peak_xmin_xmax,
				rmPeakArea: item.reference_peak_area,
				userPeakArea: item.user_peak_area,
				peakNumber: index + 1,
			};
			irResultAtomforQuantification.push(irResultAtomData);
		});
	} else {
		const irResultAtomData: IrPeaksInfo = {
			rmPeakPosition: resultData.reference_peak_pos,
			rmintensity: resultData.reference_peak_intensity,
			samplePeakPosition: resultData.sample_peak_pos,
			sampleintensity: resultData.sample_peak_intensity,
			peakPosition: resultData.peak_pos,
		};
		irResultAtom.push(irResultAtomData);
	}

	return {
		irResultAtomforQuantification: irResultAtomforQuantification,
		irResultAtom: irResultAtom,
		referencePeakRatio: referencePeakRatio,
		userPeakRatio: userPeakRatio,
	};
}

export function buildReportObject(analysisResult, molecularStructureImg, peakData, impurityPeakData, peakJsonData, casNumber) {
	const reportObj: Partial<IrReportImageModel> = {};
	const BASE64_KEY = 'data:image/png;base64,';
	const analysisType = analysisResult?.irAnalysis?.type;
	reportObj.moleculeImage = molecularStructureImg?.src?.replace(BASE64_KEY, '');
	reportObj.casNumber = casNumber;
	if (analysisType === IrAnalysisType.PURITYCHECK && peakData && impurityPeakData) {
		const impurityPeakPdfReportData: IrPdfReportImpurityPeak[] = [];
		impurityPeakData.map((item) => {
			const data = {
				drmCode: item.impurity_drm_code,
				impurityPeakPosition: item.impurity_peak_pos,
				impurityPeakIntensity: item.impurity_peak_intensity,
				substanceName: item.substance_name,
			};
			impurityPeakPdfReportData.push(data);
		});
		reportObj.impurityPeakJson = [...impurityPeakPdfReportData];
	}
	reportObj.peaktableJson = peakJsonData.peakarray;
	reportObj.referencePeakRatio = peakJsonData.referencePeakRatio;
	reportObj.userPeakRatio = peakJsonData.userPeakRatio;

	return reportObj;
}

export function drawSpectrum(ermChartElement: HTMLElement, sampleData: ICordinate, transmissionSpectra: boolean) {
	return Plotly.newPlot(
		ermChartElement,
		[
			{
				x: sampleData.x,
				y: transmissionSpectra ? getTransMissionData([...sampleData.y]) : sampleData.y,
				type: 'scatter',
				mode: 'lines',
				marker: { color: 'gray' },
				direction: 'counterclockwise',
			},
		],
		{
			autosize: true,
			width: 700,
			height: 400,
			margin: { pad: 0, t: 0, r: 50, b: 50, l: 70 },
			xaxis: {
				range: [4000, 0],
				showdividers: false,
				showline: true,
				zeroline: false,
				title: 'Wavenumber [cm⁻¹]',
			},
			yaxis: {
				showdividers: false,
				showline: true,
				zeroline: false,
				title: transmissionSpectra ? 'Transmission [%]' : 'Absorbance [A.U.]',
			},
		},
	);
}

export function getChartData(transmissionSpectra, userSpectrumInfoPurityCheck, rmSpectrumInfoPurityCheck, resultImpurities) {
	const chartData: any = [];
	const userChartData = {
		y: transmissionSpectra ? getTransMissionData([...userSpectrumInfoPurityCheck.sample_y]) : userSpectrumInfoPurityCheck?.sample_y,
		x: userSpectrumInfoPurityCheck.sample_x,
		type: 'scatter',
		mode: 'lines',
		marker: { color: SpectraColorCodes.RICH_GREEN },
		direction: 'counterclockwise',
		name: 'Sample spectrum',
		yaxis: 'y',
	};
	chartData.push(userChartData);
	const targetSubstanceData = {
		y: transmissionSpectra ? getTransMissionData([...rmSpectrumInfoPurityCheck.spectrum_y]) : rmSpectrumInfoPurityCheck.spectrum_y,
		x: rmSpectrumInfoPurityCheck.spectrum_x,
		type: 'scatter',
		mode: 'lines',
		marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
		direction: 'counterclockwise',
		name: 'Target substance spectrum',
		yaxis: 'y2',
	};
	chartData.push(targetSubstanceData);
	if (resultImpurities) {
		resultImpurities.map((item, index) => {
			const impurityData = item && item?.impuritySpectrumData;
			const substanceName = item?.substanceName || '';

			if (impurityData) {
				const impurityChartData = {
					y: transmissionSpectra ? getTransMissionData([...impurityData['y']]) : impurityData['y'],
					x: userSpectrumInfoPurityCheck?.sample_x,
					type: 'scatter',
					mode: 'lines',
					marker: { color: mixtureColorCodes[`${index}`] },
					direction: 'counterclockwise',
					name: substanceName,
					yaxis: 'y' + (3 + index),
				};
				chartData.push(impurityChartData);
			}
		});
	}

	return chartData;
}
