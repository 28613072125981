import Plotly from 'plotly.js-dist-min';
import {
	fileService,
	irAnalysisService,
	IrAnalysisResult,
	RankBasedMixtureData,
	RmSpectrumInfoPurity,
	SampleSpectrumInfoPurity,
	IrAnalysisReportImages,
	IrReportImageModel,
	IrPurityCheckPeakjson,
	IrImpurityPeak,
	IrResultImpurites,
} from '@services';
import { PromiseUtils } from '@utils/PromiseUtils';
import { RxUtils } from '@utils/Rx';
import { getDynamicGraphLayout } from '@utils';
import { getPeakHighligtShapes } from '@utils';
import { buildReportObject, getChartData, peakDataUtils, drawSpectrum } from './IrDrawPurityQuantification.helpers';

export interface IrSettings {
	transmissionSpectra?: boolean;
	chart?: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SmilesDrawer = require('smiles-drawer') as any;
const PROMISE_RESOLVER = 'Molecular structure promise resolved.';

export interface ICordinate {
	x: number[];
	y: number[];
}

interface ICordinatePeak {
	peakarray: any[];
	referencePeakRatio: string;
	userPeakRatio: string;
}

const BASE64_KEY = 'data:image/png;base64,';

const drawer = new SmilesDrawer.SmiDrawer({
	width: 450,
	height: 450,
	compactDrawing: false,
	themes: {
		light: {
			C: '#000',
			O: '#000',
			N: '#000',
			F: '#000',
			CL: '#000',
			BR: '#000',
			I: '#000',
			P: '#000',
			S: '#000',
			B: '#000',
			SI: '#000',
			H: '#000',
			BACKGROUND: '#fff',
		},
	},
});

const getJSONDataPeak = async (url: string, analysisResult: IrAnalysisResult): Promise<ICordinatePeak> => {
	return new Promise<{ peakarray: any[]; referencePeakRatio: string; userPeakRatio: string }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const { irResultAtomforQuantification, irResultAtom, referencePeakRatio, userPeakRatio } = peakDataUtils(analysisResult, data);
			resolve({
				peakarray: analysisResult.isQuantification() ? irResultAtomforQuantification : irResultAtom,
				referencePeakRatio: referencePeakRatio,
				userPeakRatio: userPeakRatio,
			});
		});
	});
};

export const getJSONData = async (url: string): Promise<ICordinate> => {
	return new Promise<{ x: number[]; y: number[] }>((resolve) => {
		RxUtils.promisify(fileService.getFileFromUrl(url), (data) => {
			const yData = data['user_spectrum'].y;
			const xData = [data['user_spectrum'].x.start, data['user_spectrum'].x.stop];
			const xAry: number[] = [];
			for (let i = xData[0]; i >= xData[1]; i--) {
				xAry.push(i);
			}
			resolve({
				x: xAry,
				y: yData,
			});
		});
	});
};

export const sendIrImagePurityQuantification = async (
	analysisResult: IrAnalysisResult,
	rankedRMData: RankBasedMixtureData,
	userSpectrumInfoPurityCheck: SampleSpectrumInfoPurity,
	rmSpectrumInfoPurityCheck: RmSpectrumInfoPurity,
	cb: (pdfUrl: string) => void,
	irSettings: IrSettings,
	peakData?: IrPurityCheckPeakjson[],
	impurityPeakData?: IrImpurityPeak[],
	resultImpurities?: IrResultImpurites[],
) => {
	const returnedModel: IrAnalysisReportImages = {
		substanceImages: [],
		mixtureImages: '',
	};
	const { transmissionSpectra } = irSettings;
	for (let i = 0; i < rankedRMData?.mixtures?.length || 0; i++) {
		const mixture = rankedRMData?.mixtures[`${i}`];
		const referenceMaterial = analysisResult?.referenceMaterials.filter((item) => item.casNumber === mixture.casNumber);
		const { smilesCode, casNumber } = referenceMaterial[0];
		const molecularStructureImg = document.createElement('img');
		const stackedElement = document.createElement('div');
		stackedElement.style.height = '200px';

		const ermChartElement = document.createElement('div');
		try {
			const imgPromise = new Promise((resolve, reject) => {
				drawer.draw(
					smilesCode,
					molecularStructureImg,
					'light',
					() => {
						resolve(PROMISE_RESOLVER);
					},
					() => {
						reject();
					},
				);
			});

			await Promise.resolve(imgPromise).catch();
		} catch {
			//
		}
		let tempObj: Partial<IrReportImageModel> = {};
		const peakJsonData = await getJSONDataPeak(analysisResult?.peakJson, analysisResult);
		await PromiseUtils.delay(() => {
			tempObj = buildReportObject(analysisResult, molecularStructureImg, peakData, impurityPeakData, peakJsonData, casNumber);
			returnedModel.substanceImages.push(tempObj);
		}, 500);

		const targetResult = rankedRMData.mixtures.filter((item: { casNumber: string }) => item.casNumber === casNumber);
		const chartDataAry = getChartData(transmissionSpectra, userSpectrumInfoPurityCheck, rmSpectrumInfoPurityCheck, resultImpurities);
		const layoutConfig = getDynamicGraphLayout(chartDataAry, userSpectrumInfoPurityCheck);

		Object.assign(layoutConfig, {
			shapes: analysisResult?.isQuantification() ? getPeakHighligtShapes(analysisResult?.quantificationDetails) : [],
		});

		for (let j = 0; j < targetResult.length; j++) {
			const sampleData = await getJSONData(analysisResult?.sampleSpectrum);
			await Plotly.newPlot(stackedElement, chartDataAry, layoutConfig as any, {});
			await drawSpectrum(ermChartElement, sampleData, transmissionSpectra || false);
			const ermImage = await Plotly.toImage(ermChartElement);
			tempObj.referenceMaterialSpectrum = ermImage.replace(BASE64_KEY, '');
			const stackedImage = await Plotly.toImage(stackedElement, { scale: 2, format: 'png', width: 750, height: 225 });
			const spectrumSeperated = stackedImage.replace(BASE64_KEY, '');
			await PromiseUtils.delay(() => {
				returnedModel.mixtureImages = spectrumSeperated;
			}, 500);
		}
	}
	RxUtils.promisify(irAnalysisService.sendAnalysisImage(analysisResult?.analysisId, returnedModel), (pdfUrl) => cb(pdfUrl));
};
