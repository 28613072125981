import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RankBasedMixtureData, SampleSpectrumInfo } from '@services';
import { Stack } from '@mui/material';
import { IrChart } from '../IrChart';
import { Tr, getTransMissionData, SpectraColorCodes, mixtureColorCodes, getDynamicGraphLayout } from '@utils';
import { setIrTransmissionSpectra, useIrSettingsSelector } from '@store/slices/ir/ir.slice';
import { useDispatch, useSelector } from 'react-redux';

interface IStackedSpectrumProps {
	createdReport?: boolean;
	subtitleVisibility?: boolean;
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

export const IrStackedSpectrum: FC<IStackedSpectrumProps> = ({
	subtitleVisibility,
	createdReport = false,
	rankedRMData,
	userSpectrumInfo,
}) => {
	const [chartDataList, setChartDataList] = useState<any>([]);
	const irSettings = useSelector(useIrSettingsSelector);
	const [displaySettingsToggle, setDisplaySettingsToggle] = useState<boolean>(irSettings?.transmissionSpectra);
	const dispatch = useDispatch();

	const handleDisplaySettings = (): void => {
		setDisplaySettingsToggle(!displaySettingsToggle);
		dispatch(setIrTransmissionSpectra(!displaySettingsToggle));
	};

	const { t } = useTranslation('irportal');
	const [layout, setLayout] = useState<any>();

	useEffect(() => {
		if (userSpectrumInfo && rankedRMData) {
			const chartDataAry: any = [];
			const userChartData = {
				y: displaySettingsToggle ? getTransMissionData([...userSpectrumInfo.sample_y]) : userSpectrumInfo.sample_y,
				x: userSpectrumInfo.sample_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.RICH_GREEN },
				direction: 'counterclockwise',
				name: t('analysis-result.input-spectrum'),
				yaxis: 'y',
			};
			chartDataAry.push(userChartData);

			const combinedChartData = {
				y: displaySettingsToggle
					? getTransMissionData([...rankedRMData['combined_spectrum_y']])
					: rankedRMData['combined_spectrum_y'],
				x: rankedRMData.spectrum_x,
				type: 'scatter',
				mode: 'lines',
				marker: { color: SpectraColorCodes.VIBRANT_YELLOW },
				direction: 'counterclockwise',
				name: 'Combined Spectrum',
				yaxis: 'y2',
			};

			if (rankedRMData?.mixtures?.length > 1) {
				chartDataAry.push(combinedChartData);
			}

			if (rankedRMData?.mixtures) {
				for (let i = 0; i < rankedRMData?.mixtures?.length; i++) {
					const mixture = rankedRMData?.mixtures[`${i}`];
					const mixtureChartData = {
						y: displaySettingsToggle ? getTransMissionData([...mixture.scaled_spectrum_y]) : mixture.scaled_spectrum_y,
						x: rankedRMData.spectrum_x,
						type: 'scatter',
						mode: 'lines',
						marker: { color: mixtureColorCodes[`${i}`] },
						direction: 'counterclockwise',
						name: mixture.substanceName,
						yaxis: 'y' + (3 + i),
					};
					chartDataAry.push(mixtureChartData);
				}
			}
			const layoutConfig = getDynamicGraphLayout(chartDataAry, userSpectrumInfo);
			setLayout(layoutConfig);
			setChartDataList(chartDataAry);
		}
	}, [rankedRMData, userSpectrumInfo, displaySettingsToggle]);

	return (
		<Stack sx={{ border: '1px solid black', borderColor: 'grey.200', borderRadius: '8px' }}>
			{chartDataList && (
				<IrChart
					subTitle={
						displaySettingsToggle ? (
							<Tr.IrPortal path="analysis-result.transmission" />
						) : (
							<Tr.IrPortal path="analysis-result.intensity" />
						)
					}
					subtitleVisibility={!!subtitleVisibility}
					className={`c-seperated-${rankedRMData.rank}-chart`}
					enableZoom={false}
					hideZoomIcon={createdReport}
					data={chartDataList}
					useResizeHandler
					style={{ width: '100%', height: '100%' }}
					displaySettingsToggle={handleDisplaySettings}
					layout={layout}
				/>
			)}
		</Stack>
	);
};
