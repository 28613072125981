import { Navigate } from 'react-router-dom';
import { AnalysisHistoryTabs, IRoute, PageType, PortalPageRoutes, PermissionEnum, DeviceManagementTabs } from '@models';
import { Tr } from '@utils';
import {
	NmrAddCalibration,
	NmrAuditTrailTable,
	NmrCompletedAnalysisTable,
	NmrEditUserReference,
	NmrOngoingAnalysisTable,
	NmrRecalibrateDevice,
} from '@components/nmr-portal';
import {
	IrActiveDeviceTable,
	ArchivedDeviceTable,
	IrAuditTrailTable as IrAuditTrailTable,
	IrCompletedAnalysisTable as IrCompletedAnalysisTable,
	IrInactiveDeviceTable,
} from '@components/ir-portal';
import { IrOngoingAnalysisTable } from '@components/ir-portal/IrOngoingAnalysisTable/IrOngoingAnalysisTable';
import { Portal } from './Portal';
import { NmrDashboard } from './nmr/NmrDashboard';
import { NmrAnalysisHistory } from './nmr/NmrAnalysisHistory';
import { NmrSearchReferences } from './nmr/NmrSearchReferences';
import { NmrFavourites } from './nmr/NmrFavourites';
import { NmrPhysicalReferenceMaterial } from './nmr/NmrPhysicalReferenceMaterial';
import { NmrAnalysis } from './nmr/NmrAnalysis';
import { AuthorizationComponent } from '@routes/Authorization';
import { UserManagement } from './common/UserManagement';
import { YourProfile } from './common/YourProfile/YourProfile';
import { OrganizationSettings } from './common/OrganizationSettings';
import { Analytics } from '@components/common/Analytics';
import { NmrDevice, NmrAddDevice } from './nmr/NmrDevice';
import { AnalysisProvider } from '@hooks';
import { NmrAnalysisResult } from './nmr/NmrAnalysisResult';
import { LabDetails } from './common/LabDetails';
import { PortalElement } from './PortalElement';
import { IrGoToDevice } from './ir/IrDevice';
import { IrDeviceManagementTabView } from './ir/IrDeviceManagement';
import { IrSearchReferences } from './ir/IrSearchReferences';
import { IrPhysicalReferenceMaterial } from './ir/IrPhysicalReferenceMaterial';
import { IrAnalysis } from './ir/IrAnalysis';
import { IrAnalysisResult } from './ir/IrAnalysisResult';
import { IrAddDevice } from './ir/IrDevice';
import { IrRecalibrate } from './ir/IrDevice';
import { IrOrganizationManagement } from './ir/IrOrganizationManagement';
import { IrAnalysisHistory } from './ir/IrAnalysisHistory';
import { IrFavourites } from './ir/IrFavourites/IrFavourites';
import { IrDashboard } from './ir/IrDashboard/IrDashboard';
import { IrUserReferences } from '@routes/Portal/ir/IrUserReferences/IrUserReferences';
import { IrAnalysisProvider } from '@hooks';
import LocalMallOutlined from '@mui/icons-material/LocalMallOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import { IrAddAccessory } from '@routes/Portal/ir/IrDevice/IrAddAccessory';
import { ReactComponent as Science } from '@material-symbols/svg-600/outlined/science.svg';
import { ReactComponent as Home } from '@material-symbols/svg-600/outlined/home.svg';
import { ReactComponent as LabResearch } from '@material-symbols/svg-600/outlined/lab_research.svg';
import { ReactComponent as QuickReferenceAll } from '@material-symbols/svg-600/outlined/quick_reference_all.svg';
import { DeviceDetails } from '@components/nmr-portal/NmrDevice';
import {
	ActiveDeviceCalibrationTable,
	NmrDeviceCalibrationManagement,
	InactiveDeviceCalibrationTable,
	WaitingDeviceCalibrationTable,
} from './nmr/NmrDeviceCalibrationManagement';
import { OrganizationManagement } from './common/OrganizationManagement';

import { IrUserReferenceManagement } from '@routes/Portal/ir/IrUserReferenceManagement';
import { IrUserLibrary } from '@routes/Portal/ir/IrUserLibrary';
import { IrCreateUserReference } from '@components/ir-portal/IrCreateReference';
import { IrEditUserReference } from '@components/ir-portal/IrEditUserReference';
import { NmrUserReferenceManagement } from './nmr/NmrUserReferenceManagement';
import { NmrUserReferences } from './nmr/NmrUserReferences.tsx';
import { NmrUserLibrary } from './nmr/NmrUserLibrary/NmrUserLibrary';
import { NmrCreateUserReference } from '@components/nmr-portal/NmrCreateReference';

export const PORTAL_ROUTES: IRoute = {
	path: '/portal',
	element: (
		<>
			<AuthorizationComponent path="portal">
				<Analytics>
					<Portal />
				</Analytics>
			</AuthorizationComponent>
		</>
	),
	text: 'Portal',
	children: [
		{
			path: PortalPageRoutes.DASHBOARD,
			element: (
				<PortalElement
					nmrComponent={<PortalElement nmrComponent={<NmrDashboard />} irComponent={<IrDashboard />} />}
					irComponent={<IrDashboard />}
				/>
			),
			icon: <Home width={24} height={24} />,
			text: <Tr.Portal path="dashboard.title" />,
			pageType: PageType.STANDARD,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DASHBOARD],
		},
		{
			path: PortalPageRoutes.HISTORY,
			element: <PortalElement nmrComponent={<NmrAnalysisHistory />} irComponent={<IrAnalysisHistory />} />,
			icon: <Science width={24} height={24} />,
			text: <Tr.Portal path="analysis-history.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
			children: [
				{
					path: AnalysisHistoryTabs.ONGOING_ANALYSIS,
					element: <PortalElement nmrComponent={<NmrOngoingAnalysisTable />} irComponent={<IrOngoingAnalysisTable />} />,
					text: <Tr.Portal path="analysis-history.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
				},
				{
					path: AnalysisHistoryTabs.COMPLETED_ANALYSIS,
					element: <PortalElement nmrComponent={<NmrCompletedAnalysisTable />} irComponent={<IrCompletedAnalysisTable />} />,
					text: <Tr.Portal path="analysis-history.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
				},
				{
					path: `${AnalysisHistoryTabs.AUDIT_TRAIL}`,
					element: <PortalElement nmrComponent={<NmrAuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
					text: <Tr.Portal path="analysis-history.audit-trail.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
				},
				{
					path: `${AnalysisHistoryTabs.AUDIT_TRAIL}/:id`,
					element: <PortalElement nmrComponent={<NmrAuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
					text: <Tr.Portal path="analysis-history.audit-trail.title" />,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
				},
			],
		},
		{
			path: PortalPageRoutes.SEARCH,
			element: <PortalElement nmrComponent={<NmrSearchReferences />} irComponent={<IrSearchReferences />} />,
			icon: <LabResearch width={24} height={24} />,
			text: <Tr.Portal path="search-references.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DRM],
		},
		{
			path: PortalPageRoutes.FAVOURITES,
			element: <PortalElement nmrComponent={<NmrFavourites />} irComponent={<IrFavourites />} />,
			icon: <StarBorderOutlined color="inherit" />,
			text: <Tr.Portal path="favourites.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.FAVORITE],
		},
		{
			path: PortalPageRoutes.AUDIT_TRAIL,
			element: <PortalElement nmrComponent={<NmrAuditTrailTable />} irComponent={<IrAuditTrailTable />} />,
			icon: <QuickReferenceAll width={24} height={24} />,
			text: <Tr.Portal path="analysis-history.audit-trail.title" />,
			pageType: PageType.STANDARD,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_AUDIT_TRAIL],
		},
		{
			path: PortalPageRoutes.PHYSICAL_REFERENCE_MATERIAL,
			element: <PortalElement nmrComponent={<NmrPhysicalReferenceMaterial />} irComponent={<IrPhysicalReferenceMaterial />} />,
			icon: <LocalMallOutlined color="inherit" />,
			text: <Tr.Portal path="physical-reference-materials.title" />,
			pageType: PageType.FULLSCREEN,
			hideTitleInPage: true,
			accessPermissions: [PermissionEnum.LabPermission.WEBSHOP],
		},
		{
			path: `${PortalPageRoutes.ANALYSIS}/:id`,
			element: (
				<PortalElement
					nmrComponent={
						<AnalysisProvider>
							<NmrAnalysis />
						</AnalysisProvider>
					}
					irComponent={
						<IrAnalysisProvider>
							<IrAnalysis />
						</IrAnalysisProvider>
					}
				/>
			),
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="new-analysis.title" />,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
		},
		{
			path: `${PortalPageRoutes.ANALYSIS_RESULT}/:id`,
			element: <PortalElement nmrComponent={<NmrAnalysisResult />} irComponent={<IrAnalysisResult />} />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_ANALYSIS],
		},
		{
			path: PortalPageRoutes.MANAGE_ORGANIZATION,
			element: <PortalElement nmrComponent={<OrganizationManagement />} irComponent={<IrOrganizationManagement />} />,
			icon: <SettingsOutlined color="inherit" />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="manage-organization.title" />,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.MANAGE_USERS}/:id`,
			element: <UserManagement />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="manage-users.title" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.USER],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE_MANAGEMENT}`,
			element: <PortalElement nmrComponent={<NmrDeviceCalibrationManagement />} irComponent={<IrDeviceManagementTabView />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.title" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
			children: [
				{
					path: DeviceManagementTabs.ACTIVE_DEVICES,
					element: <IrActiveDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.ARCHIVED_DEVICES,
					element: <ArchivedDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.INACTIVE_DEVICES,
					element: <IrInactiveDeviceTable />,
					text: <Tr.Portal path="device-management.title" />,
					hideTitleInPage: true,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.ACTIVE,
					element: <ActiveDeviceCalibrationTable />,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.WAITING,
					element: <WaitingDeviceCalibrationTable />,
					hasFooter: true,
				},
				{
					path: DeviceManagementTabs.INACTIVE,
					element: <InactiveDeviceCalibrationTable />,
					hasFooter: true,
				},
			],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_DEVICE}`,
			element: <PortalElement nmrComponent={<NmrAddDevice />} irComponent={<IrAddDevice />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/:id`,
			element: <PortalElement nmrComponent={<NmrDevice />} irComponent={<IrRecalibrate />} />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
			children: [
				{
					path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.DEVICE}/:id/${PortalPageRoutes.RECALIBRATE}/:calibrationId`,
					element: <PortalElement nmrComponent={<NmrRecalibrateDevice />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
				{
					path: PortalPageRoutes.DEVICE_DETAILS,
					element: <PortalElement nmrComponent={<DeviceDetails />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
				{
					path: PortalPageRoutes.ADD_CALIBRATION,
					element: <PortalElement nmrComponent={<NmrAddCalibration />} irComponent={<IrRecalibrate />} />,
					text: <Tr.Portal path="device-management.device-details" />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
					accessPermissions: [PermissionEnum.LabPermission.DEVICE],
				},
			],
		},

		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.GO_TO_DEVICE}/:id`,
			element: <IrGoToDevice />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.RESTORE_DEVICE}/:id`,
			element: <IrGoToDevice />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ADD_ACCESSORY}`,
			element: <IrAddAccessory />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="device-management.device-details" />,
			hideTitleInPage: true,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.DEVICE],
		},
		{
			path: PortalPageRoutes.YOUR_PROFILE,
			element: <YourProfile />,
			pageType: PageType.STANDARD,
			text: <Tr.Portal path="your-profile.title" />,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.UserPermission.USER_PROFILE],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.ORGANIZATION_SETTINGS}/:id/:name`,
			element: <OrganizationSettings />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.OrganizationPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/:orgId/${PortalPageRoutes.LAB_DETAILS}/:id`,
			element: <LabDetails />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
			accessPermissions: [PermissionEnum.LabPermission.VIEW_DETAILS],
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}`,
			element: <PortalElement nmrComponent={<NmrUserReferenceManagement />} irComponent={<IrUserReferenceManagement />} />,
			children: [
				{
					path: PortalPageRoutes.REFERENCES,
					element: <PortalElement nmrComponent={<NmrUserReferences />} irComponent={<IrUserReferences />} />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
				},
				{
					path: PortalPageRoutes.LIBRARIES,
					element: <PortalElement nmrComponent={<NmrUserLibrary />} irComponent={<IrUserLibrary />} />,
					hideTitleInPage: true,
					hideFromMenu: true,
					hasFooter: true,
				},
			],
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hasFooter: true,
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}/new`,
			element: <PortalElement nmrComponent={<NmrCreateUserReference />} irComponent={<IrCreateUserReference />} />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
		},
		{
			path: `${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.REFERENCES}/edit/:drmCode/:id/:status`,
			element: <PortalElement nmrComponent={<NmrEditUserReference />} irComponent={<IrEditUserReference />} />,
			pageType: PageType.STANDARD,
			hideFromMenu: true,
			hideTitleInPage: true,
			hasFooter: true,
		},
		{
			hideFromMenu: true,
			path: '',
			element: <Navigate to={`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.DASHBOARD}`} replace />,
		},
	],
};
