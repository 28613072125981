import { Link, Stack, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
interface IndicatorType {
	label?: ReactNode;
	value?: string | number;
	unit?: string;
	containerSx?: SxProps;
	isActive?: boolean;
	showProductNumber?: boolean;
}
export const invalidValues = ['NA', NaN, undefined, null, ''];

export const IrDRMIndicator: FC<IndicatorType> = ({ label, value = '', unit, containerSx, isActive = true, showProductNumber }) => (
	<Stack direction="row" alignItems="center" sx={{ marginBottom: 1.5, ...containerSx }}>
		<Stack>
			<Typography variant="pg-m" sx={{ width: 222, fontWeight: 400, color: isActive ? 'text.primary' : 'grey.500', marginRight: 4 }}>
				{label}
			</Typography>
		</Stack>
		<Stack>
			<Typography variant="pg-m" sx={{ color: isActive ? 'grey.800' : 'grey.500', lineHeight: 1.6, wordBreak: 'break-all' }}>
				{showProductNumber ? (
					<Link
						underline="hover"
						variant="body1"
						href={`https://www.sigmaaldrich.com/US/en/product/sial/${value}`}
						sx={{ cursor: 'pointer' }}
						target="_blank"
					>
						{value}
					</Link>
				) : invalidValues.includes(value) ? (
					'-'
				) : value && unit ? (
					`${value} ${unit}`
				) : (
					value
				)}
			</Typography>
		</Stack>
	</Stack>
);
