import { SubstanceCard } from '@components/admin/SubstanceCard/SubstanceCard';
import { FormikApiType, ModalDialog } from '@components/common';
import { Button, DialogActions, Stack, Typography } from '@mui/material';
import { notificationService } from '@services/core';
import { Substance, substanceService } from '@services/core/substance';
import { DataTestId } from '@utils/DataTestId';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IAddSubstanceModalProps {
	substance: Substance;
	visible: boolean;
	onCancel: () => void;
	isUserReferenceType?: boolean;
	referenceFormik?: FormikApiType;
}

export const AddSubstanceModal: FC<IAddSubstanceModalProps> = ({ substance, visible, onCancel, isUserReferenceType, referenceFormik }) => {
	const { t } = useTranslation('admin');
	const navigate = useNavigate();

	const handleAddSubstance = () => {
		if (!isUserReferenceType && substance) {
			RxUtils.promisify(
				substanceService.addSubstance(substance),
				() => {
					notificationService.sendSuccess(t('substance-management.add-substance-success'));
					navigate(0);
				},
				(err) => notificationService.sendError(err.Message),
			);
			onCancel();
		}
		if (isUserReferenceType && substance && referenceFormik) {
			referenceFormik.setFieldValue('casNumber', substance.casNumber);
			referenceFormik.setFieldValue('linearFormula', substance.linearMolecularFormula);
			referenceFormik.setFieldValue('molecularWeight', substance.molecularWeight);
			referenceFormik.setFieldValue('synonyms', substance.synonyms);
			referenceFormik.setFieldTouched('casNumber', true);
			referenceFormik.setFieldValue('smilesCode', substance.smilesCode);
		}
		onCancel();
	};

	return (
		<>
			<ModalDialog
				variant="primary"
				open={visible}
				maxWidth="sm"
				onBackdropClick={onCancel}
				onClose={onCancel}
				sx={{ zIndex: 2 }}
				iconButtonProps={{ padding: 0, right: 16 }}
				titleProps={{
					sx: {
						justifyContent: 'space-between',
						marginTop: 0,
						marginBottom: 5,
						minHeight: 30,
						backgroundColor: 'grey.50',
						paddingY: 1.2,
						paddingX: 2,
						height: 40,
					},
				}}
				title={
					<Typography variant="body1" sx={{ fontWeight: 400, lineHeight: '160%' }}>
						<Tr.Admin path="substance-management.add-substance" />
					</Typography>
				}
				PaperProps={{
					sx: {
						minWidth: '940px',
						minHeight: '440px',
					},
				}}
				contentProps={{ sx: { padding: 3 } }}
			>
				<Stack>
					<SubstanceCard
						substance={substance}
						firstColumnProps={{ xs: 5 }}
						secondColumnProps={{ xs: 7 }}
						lastColumnProps={{ sx: { paddingLeft: 1.5 } }}
						containerProps={{ spacing: 7 }}
						molecularFormulaDimensions={{
							height: 264,
							width: 350,
						}}
						title={substance?.name}
					/>
					<DialogActions sx={{ padding: '12px' }}>
						<Button variant="outlined" onClick={onCancel} data-testid={DataTestId.getStaticTestId('modal-close-button-id')}>
							<Tr.Admin path="substance-detail.cancel" />
						</Button>
						<Button
							data-testid={DataTestId.getStaticTestId('modal-add-id')}
							variant="contained"
							onClick={() => {
								handleAddSubstance();
							}}
						>
							<Tr.Admin
								path={
									isUserReferenceType
										? 'substance-management.add-to-substance-details'
										: 'substance-management.add-substance'
								}
							/>
						</Button>
					</DialogActions>
				</Stack>
			</ModalDialog>
		</>
	);
};
