import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { formFieldSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import { useService } from '@hooks';
import { useEffect, useState } from 'react';
import { IUploadedFile } from '@models/file';
import { CreateRefSaveFields, NmrUserReferenceFormLayout } from '../NmrReferenceLayout';
import { getNmrUserReferenceValidationSchema } from '@schemas/nmr/user-reference';
import { nmrDrmService } from '@services/nmr';
import { ReferenceStatusCode } from '@components/common/UserReferenceStatus';
import { notificationService } from '@services/core';
import { PortalPageRoutes } from '@models/router';

export const NmrCreateUserReference = () => {
	const { t } = useTranslation('portal');
	const [referenceMaterialId, setreferenceMaterialId] = useState<{ id: number }>({ id: 0 });
	const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);

	const navigate = useNavigate();

	const { data: fetchedReference } = useService(() => {
		const body = { drmCode: null };
		return nmrDrmService.createUserReference(body);
	}, []);

	useEffect(() => {
		if (fetchedReference) {
			formik.setFieldValue('id', fetchedReference);
			setreferenceMaterialId({ id: fetchedReference });
		}
	}, [fetchedReference]);

	const formik = useFormik({
		initialValues: {
			name: null,
			nmrReferenceMaterialLibraryId: '',
			casNumber: '',
			linearFormula: null,
			molecularWeight: null,
			smilesCode: null,
			synonyms: null,
			solvent: null,
			acquisitionTime: '',
			numberOfScans: null,
			frequency: null,
			relaxationTime: '',
			temperature: null,
			batchNumber: null,
			generalComment: null,
		},
		validationSchema: getNmrUserReferenceValidationSchema(t),
		onSubmit: (values) => {
			RxUtils.promisify(
				nmrDrmService.updateUserReference({
					...values,
					id: referenceMaterialId?.id,
					status: ReferenceStatusCode.InProgress,
				}),
				() => {
					notificationService.sendSuccess(t('user-references.created-successfully'));
				},
				(err) => {
					if (+err?.ErrorCode) {
						const errMessage = err?.Message;
						if (errMessage) {
							notificationService.sendError(errMessage);
						} else {
							notificationService.sendError(t('user-references.created-failed-msg'));
						}
					}
				},
			).finally(() => {
				navigate(
					`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
				);
			});
		},
	});

	return (
		<Stack data-testid="create-reference-id" spacing={formFieldSpacer}>
			<form onSubmit={formik.handleSubmit} data-testid="create-reference-form-test-id">
				<Stack direction="row" alignItems="center" spacing={mainTitleSpacer} justifyContent="space-between">
					<Typography variant="h1" sx={{ color: 'text.secondary' }}>
						<Tr.Portal path="user-references.create-reference" />
					</Typography>
					<CreateRefSaveFields uploadedFile={uploadedFile} formik={formik} editing={false} />
				</Stack>
				<NmrUserReferenceFormLayout
					uploadedFile={uploadedFile}
					setUploadedFile={setUploadedFile}
					referenceFileId={referenceMaterialId?.id}
					formik={formik}
				/>
			</form>
		</Stack>
	);
};
