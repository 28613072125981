/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { NmrCalibrationStatus, NmrDeviceCalibration } from '@services';
import { TypeUtils } from '@utils/Type';

type TStatusColor = {
	iconColor: string;
	backgroundColor: string;
	typographyColor: string;
};
const StatusColorMapping = {
	[NmrCalibrationStatus?.ACTIVE]: {
		iconColor: '#01884C',
		backgroundColor: '#01884C1A',
		typographyColor: 'primary.main',
	},
	[NmrCalibrationStatus?.INACTIVE]: {
		iconColor: '#8B8B9C',
		backgroundColor: '#ECECF4',
		typographyColor: 'grey.500',
	},
};

export const CalibrationChip: FC<{ calibration: NmrDeviceCalibration }> = ({ calibration }) => {
	const { name, status } = calibration;
	const isCheckVisible = status === NmrCalibrationStatus.ACTIVE || status === NmrCalibrationStatus.INACTIVE;
	const statusColors = TypeUtils.returnValueOfKey<typeof StatusColorMapping, TStatusColor>(status, StatusColorMapping);
	return (
		<Chip
			clickable={false}
			sx={{
				borderRadius: '4px',
				'&.MuiChip-root': { width: 'fit-content' },
				backgroundColor: isCheckVisible ? statusColors?.backgroundColor : '#FFF6F8',
			}}
			label={
				<Stack
					sx={{
						transform: 'translateY(1px)',
					}}
				>
					<Typography variant="subtitle3" color={isCheckVisible ? statusColors?.typographyColor : 'error.main'}>
						{name}
					</Typography>
				</Stack>
			}
			icon={
				isCheckVisible ? (
					<CheckCircle sx={{ color: `${statusColors?.iconColor} !important`, fontSize: 16 }} />
				) : (
					<ErrorRounded sx={{ color: '#E61E50 !important', fontSize: 16 }} />
				)
			}
		/>
	);
};
