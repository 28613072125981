import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { FormikTextfield } from '@components/common';
import { TableButton } from '@components/nmr-portal';
import { Stack, Typography } from '@mui/material';
import { InputErrorRelativePosition, Tr } from '@utils';
import { PasswordPattern } from './PasswordPattern';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getRegisterValidationSchema } from '@schemas';
import { ILoginUserModel } from '@models/user';

const formikProps = {
	typographyProps: {
		sx: { color: 'grey.800', fontSize: '13px', marginBottom: '5px' },
	},
	sx: { marginBottom: '1rem', ...InputErrorRelativePosition },
	required: true,
};

type FormFields = Extendable<{
	firstName: string;
	lastName: string;
	newPassword: string;
	confirmPassword: string;
	phoneNumber: string;
}>;
interface IRegisterProps {
	onSuccessfullyRegister: (registerInfo: { firstName: string; lastName: string; newPassword: string; phoneNumber: string }) => void;
	proceedToPhoneNumberStep: (registerInfo: { firstName: string; lastName: string; newPassword: string; phoneNumber: string }) => void;
	onBackClick: () => void;
	mfaEnabled: boolean;
	userInfo?: ILoginUserModel;
}

export const Register: FC<IRegisterProps> = ({ onSuccessfullyRegister, proceedToPhoneNumberStep, onBackClick, mfaEnabled, userInfo }) => {
	const [patternValid, setPatternValid] = useState(false);
	const { t } = useTranslation('login');
	const formik = useFormik<FormFields>({
		initialValues: {
			confirmPassword: '',
			firstName: userInfo?.firstName || '',
			phoneNumber: '',
			lastName: userInfo?.lastName || '',
			newPassword: '',
		},
		validationSchema: getRegisterValidationSchema(t),
		onSubmit: () => {
			const { firstName, lastName, newPassword, phoneNumber } = formik.values;
			const cleanedPassword = newPassword.trim();
			const finalPhoneNumber = mfaEnabled ? phoneNumber.replace(' ', '') : '+10000000000';
			if (mfaEnabled) {
				proceedToPhoneNumberStep({ firstName, lastName, newPassword: cleanedPassword, phoneNumber: finalPhoneNumber });
			} else {
				onSuccessfullyRegister({ firstName, lastName, newPassword: cleanedPassword, phoneNumber: finalPhoneNumber });
			}
		},
	});

	return (
		<Stack>
			<Typography variant="h1" sx={{ marginBottom: '2.5rem' }}>
				<Tr.Login path="register.register" />
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<FormikTextfield
					{...formikProps}
					formikApi={formik}
					name="firstName"
					title={<Tr.Login path="register.first-name" />}
					placeholder={t('register.enter-first-name')}
					inputProps={{ 'data-testid': 'first-name-test-id' }}
				/>
				<FormikTextfield
					{...formikProps}
					formikApi={formik}
					name="lastName"
					title={<Tr.Login path="register.last-name" />}
					placeholder={t('register.enter-last-name')}
					inputProps={{ 'data-testid': 'last-name-test-id' }}
				/>
				<FormikTextfield
					{...formikProps}
					formikApi={formik}
					name="newPassword"
					type="password"
					title={<Tr.Login path="register.password" />}
					placeholder={t('register.enter-password')}
					inputProps={{ 'data-testid': 'first-password-test-id' }}
				/>
				<FormikTextfield
					{...formikProps}
					formikApi={formik}
					type="password"
					name="confirmPassword"
					title={<Tr.Login path="register.confirm-password" />}
					placeholder={t('register.re-enter-password')}
					inputProps={{ 'data-testid': 'second-password-test-id' }}
				/>

				<PasswordPattern
					pass1={formik.values.newPassword}
					pass2={formik.values.confirmPassword}
					patternIsValid={(isValid) => setPatternValid(isValid)}
				/>

				<Stack direction="row" marginTop="2.5rem" alignItems="center">
					<Stack
						data-testid="register-back-button-id"
						onClick={() => onBackClick()}
						direction="row"
						sx={{ cursor: 'pointer', alignItems: 'center' }}
					>
						<KeyboardBackspaceIcon color="primary" />
						<Typography variant="label-s" color="primary" marginLeft="0.625rem">
							<Tr.Login path="register.back" />
						</Typography>
					</Stack>
					<TableButton.Contained
						type="submit"
						size="small"
						sx={{ marginLeft: 'auto', display: 'flex', width: '3.75rem', height: '2.5rem' }}
						disabled={!patternValid || !formik.isValid}
						data-testid="register-submit-button-id"
					>
						<Tr.Login path={mfaEnabled ? 'register.next' : 'register.submit'} />
					</TableButton.Contained>
				</Stack>
			</form>
		</Stack>
	);
};
