import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUploadedFile } from '@models';
import { IrAnalysisType } from '@models';
import { cardSpacer, Tr } from '@utils';
import { Checkbox, Divider, FormControl, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { IrAnalysisTypeSelection, SampleFileUploader } from '@components/ir-portal';
import { SecondApprovalSelection } from '@components/nmr-portal';
import { MethodList } from '@components/ir-portal/MethodList';
import { useIrAnalysis } from '@hooks';

interface IrSampleMeasurementsProps {
	spectrumFile?: Partial<IUploadedFile>;
	onUploadedFileChange?: (f?: IUploadedFile) => void;
	formik: FormikApiType;
	onAnalysisTypeChange: (val: IrAnalysisType) => void;
}

export const IrSampleMeasurements: FC<IrSampleMeasurementsProps> = ({
	spectrumFile,
	onUploadedFileChange,
	formik,
	onAnalysisTypeChange,
}) => {
	const { t } = useTranslation('irportal');
	const { isAnalysisEditable } = useIrAnalysis();

	useEffect(() => {
		formik?.setFieldTouched('peakMin2', false);
		formik?.setFieldTouched('peakMax2', false);
	}, [formik?.values?.quantificationMethod]);
	return (
		<GenericCard sx={{ marginTop: cardSpacer, borderBottom: 'none', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
			<Stack>
				{spectrumFile?.content && (
					<Typography variant="h4" color="primary.main" sx={{ mb: 3 }}>
						<Tr.IrPortal path="new-analysis.step1-title" />
					</Typography>
				)}
				<Typography variant="h5" sx={{ mb: 3 }}>
					<Tr.IrPortal path="new-analysis.sample-experimental-spectrum" />
				</Typography>
			</Stack>
			<Grid data-testid="sample-file-uploader" container columnSpacing={20}>
				<Grid item xs={6}>
					<Stack direction="column">
						{useMemo(
							() => (
								<SampleFileUploader
									uploadedFile={spectrumFile}
									analysisHasFile={!!spectrumFile?.content}
									onUploadedFileChange={onUploadedFileChange}
									title={formik?.values.title}
									isDisabled={!isAnalysisEditable}
								/>
							),
							[spectrumFile],
						)}
					</Stack>
				</Grid>
				{spectrumFile?.content ? (
					<Grid item xs={6} data-testid="analysis-type-id">
						<Stack direction="column" spacing={4}>
							<FormikTextfield
								formikApi={formik as FormikApiType}
								name="technology"
								title={t('new-analysis.technology')}
								placeholder={t('new-analysis.technology')}
								variant="outlined"
								fullWidth
								inputProps={{ readOnly: true }}
								sx={{ backgroundColor: 'primary.100' }}
							/>
							<IrAnalysisTypeSelection
								title={t('new-analysis.type-of-analysis')}
								onTypeChange={onAnalysisTypeChange}
								formik={formik}
							/>

							<>
								{formik?.values.type === IrAnalysisType.IDENTIFICATION && (
									<>
										<Divider sx={{ marginY: 0.5 }} />
										<FormControl sx={{ margin: 1 }} disabled={!isAnalysisEditable}>
											<FormControlLabel
												sx={{ marginBottom: 1 }}
												control={
													<Checkbox
														data-testid="check-consent-id"
														disabled={!isAnalysisEditable}
														name="includeMixture"
														checked={formik?.values?.includeMixture}
														onChange={(event) => formik.setFieldValue('includeMixture', event.target.checked)}
														sx={{ marginTop: '-5px', '& .MuiSvgIcon-root': { fontSize: 32 } }}
													/>
												}
												disabled={!isAnalysisEditable}
												label={<Tr.IrPortal path="Include Mixture" />}
											/>
										</FormControl>
									</>
								)}

								{formik?.values?.type === IrAnalysisType.QUANTIFICATION && (
									<Stack>
										<Divider sx={{ marginY: 0.5, marginBottom: 2 }} />
										<MethodList name="quantificationMethod" formik={formik} disabled={!isAnalysisEditable} />
									</Stack>
								)}
							</>
						</Stack>
						<Divider
							sx={{
								mt: 2,
							}}
						/>
						<SecondApprovalSelection
							dataTestId={formik?.values.secondApproval ? 'two-step-approval' : 'one-step-approval'}
							secondApprovalStatus={formik?.values.secondApproval}
							disabled={!isAnalysisEditable}
							onSecondApprovalStatusChange={(secondApprovalStatus: boolean) =>
								formik?.setFieldValue('secondApproval', secondApprovalStatus)
							}
							sx={{ alignItems: 'flex-start', marginLeft: 1, marginTop: 2 }}
						/>
					</Grid>
				) : null}
			</Grid>
		</GenericCard>
	);
};
