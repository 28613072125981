import { FC, useContext } from 'react';
import { RankBasedMixtureData, SampleSpectrumInfo } from '@services';

import { IrAnalysisResultContext } from '@routes/Portal/ir/IrAnalysisResult';
import { Divider, Grid, Typography } from '@mui/material';
import {
	renderPeakItensityDataGrid,
	getTargetAndSampleTableofAssignmentPeaks,
} from '../IrIdentificationTableOfAssignment/IrTableOfAssignmentHelperComponents';
import { Tr } from '@utils';

interface IQuantitativeTableOfAssignment {
	rankedRMData: RankBasedMixtureData;
	userSpectrumInfo: SampleSpectrumInfo;
}

const Expected = 'Expected';
const Detected = 'Detected';

export const IrPurityCheckTableOfAssignment: FC<IQuantitativeTableOfAssignment> = ({ userSpectrumInfo, rankedRMData }) => {
	const { analysisResultData, peakData, impurityPeakData } = useContext(IrAnalysisResultContext);

	const reference_peak_intensity = (peakData && peakData['reference_peak_intensity']) || [];
	const sample_peak_intensity = (peakData && peakData['sample_peak_intensity']) || [];
	const peak_pos = (peakData && peakData['peak_pos']) || [];

	const reference_impurity_peak_intensity = (impurityPeakData && impurityPeakData['reference_peak_intensity']) || [];
	const sample_impurity_peak_intensity = (impurityPeakData && impurityPeakData['sample_peak_intensity']) || [];
	const impurity_peak_pos = impurityPeakData && impurityPeakData['peak_pos'];

	const targetSubstanceTitle = rankedRMData?.mixtures[0].substanceName || '';
	const sampelTitle = analysisResultData?.irAnalysis?.title || '';
	const resultType = analysisResultData?.resultType;

	const getImpuritiesTableofAssignmentPeaks = (type: string) => {
		const impurities = type !== 'Detected' ? peakData && peakData['impurities'] : impurityPeakData && impurityPeakData['impurities'];
		if (impurities && impurities?.length > 0) {
			return impurities.map((item) => {
				if (impurities) {
					const impurity_peak_intensity = (item && item['impurity_peak_intensity']) || [];
					return (
						<>
							{renderPeakItensityDataGrid(
								item?.impurity_substance_name || item['impurity_drm_code'],
								impurity_peak_intensity,
								true,
								'impurity-intensity',
								true,
								resultType,
							)}
						</>
					);
				}
			});
		}
	};

	const displayPeakPosition = (type: string) => {
		if (peak_pos && peak_pos?.length > 0 && type === Expected) {
			return <>{renderPeakItensityDataGrid('', peak_pos, false, 'peak-position')}</>;
		}
		if (impurity_peak_pos && impurity_peak_pos?.length > 0 && type === Detected) {
			return <>{renderPeakItensityDataGrid('', impurity_peak_pos, false, 'peak-position')}</>;
		}
	};

	return userSpectrumInfo || analysisResultData?.peakJson ? (
		<>
			{peakData && (
				<>
					{resultType !== 1 && resultType !== 4 && (
						<Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
							<Tr.IrPortal path="analysis-result.expected-impurities" />
						</Typography>
					)}
					<Grid item container alignItems="center" xs="auto">
						{displayPeakPosition(Expected)}
						{getTargetAndSampleTableofAssignmentPeaks(
							reference_peak_intensity,
							sample_peak_intensity,
							targetSubstanceTitle,
							sampelTitle,
						)}
						{getImpuritiesTableofAssignmentPeaks(Expected)}
					</Grid>
				</>
			)}

			{impurityPeakData && (
				<>
					<Divider sx={{ mt: 5, bgcolor: 'secondary.main' }} />
					<Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
						<Tr.IrPortal path="analysis-result.detected-impurities" />
					</Typography>
					<Grid item container alignItems="center" xs="auto">
						{displayPeakPosition(Detected)}
						{getTargetAndSampleTableofAssignmentPeaks(
							reference_impurity_peak_intensity,
							sample_impurity_peak_intensity,
							targetSubstanceTitle,
							sampelTitle,
						)}
						{getImpuritiesTableofAssignmentPeaks(Detected)}
					</Grid>
				</>
			)}
		</>
	) : (
		<></>
	);
};
