import { FormikApiType, RequestErm, TablePagination } from '@components/common';
import { NmrDRMDetail, NmrDrmTabs, ErmSearchTabValues, NmrReferenceSearchField, NmrSubstanceList } from '@components/nmr-portal';
import { useAnalysis, useDRMDetail, useService } from '@hooks';
import { FilterKey, QualitativeOrQuantitative } from '@models';
import { Stack, Typography } from '@mui/material';
import { DRMDivider } from '@routes/Portal/nmr/NmrSearchReferences';
import {
	NmrDrmDetail,
	nmrFilterService,
	NmrSubstanceListItem,
	NmrSubstanceListResponse,
	nmrSubstanceService,
	notificationService,
} from '@services';
import { DataTestId, getFiltersFromSearchParams, RxUtils, TypeUtils } from '@utils';
import { ChangeEvent, createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NmrAnalyticalSubstanceNoResult } from './NmrAnalyticalSubstanceNoResult';
import { WhatWeQuantify } from './WhatWeQuantify';

const MIN_SEARCH_INPUT_LEN = 1;

export interface IAnalyticalReferenceMaterialRef {
	refreshSubstanceList: () => void;
}

interface IAnalyticalReferenceMaterialProps {
	onAddErmOrSubstance: (substanceId: number, isSubstance: boolean) => void;
	refreshAddedSubstances: () => void;
	formik: FormikApiType;
}
export const NmrAnalyticalReferenceMaterial = forwardRef<IAnalyticalReferenceMaterialRef, IAnalyticalReferenceMaterialProps>(
	({ onAddErmOrSubstance, refreshAddedSubstances, formik }, ref) => {
		const { t } = useTranslation('portal');
		const [typedString, setTypedString] = useState('');
		const [currentTab, setCurrentTab] = useState<Maybe<ErmSearchTabValues>>(ErmSearchTabValues.Recent);
		const [searchResult, setSearchResult] = useState<NmrSubstanceListResponse>();
		const [filterSearchParams, setFilterSearchParams] = useState<URLSearchParams>(new URLSearchParams());
		const [substanceList, setSubstanceList] = useState<NmrSubstanceListItem[]>([]);

		const [isRequestErmModalVisible, setIsRequestErmModalVisible] = useState(false);
		const [drmDetailData, setDrmDetailData] = useState<NmrDrmDetail>();

		const { resetDetail: resetDrmDetail, setDRMDetail, ...drmDetailProp } = useDRMDetail();
		const { isAnalysisEditable, addedERMs } = useAnalysis();
		const { data: filterOptions } = useService(() => nmrFilterService.substanceFilter(), []);

		if (filterOptions && filterOptions.options[1]) {
			filterOptions.options[1].escapesTranslation = true;
		}

		useEffect(() => {
			if (!searchResult) {
				return;
			}

			const resultList = searchResult.substances?.data ? [...searchResult.substances.data] : [];

			const theoreticalIndex = resultList.findIndex((s) => s.isTheoretical);
			if (theoreticalIndex !== -1) {
				resultList[`${theoreticalIndex}`].searchString = typedString;
				resultList[`${theoreticalIndex}`].totalTheoreticalSpectrumCount = searchResult.totalTheoreticalSpectrumCount;
				resultList[`${theoreticalIndex}`].theoreticalStartIndex = theoreticalIndex;
			}

			setSubstanceList(resultList);
		}, [searchResult]);

		useEffect(() => {
			DataTestId.resetState();
			const isRecent = currentTab === ErmSearchTabValues.Recent;
			currentTab !== null && getSubstanceList('', '1', isRecent);
		}, [currentTab]);

		useEffect(() => onSearchClear(), [formik?.values.measurementType]);

		const addSubstanceOrErmToAnalysis = (substanceId: number, isSubstance: boolean) => {
			resetDrmDetail();
			onAddErmOrSubstance(substanceId, isSubstance);
		};

		const getSubstanceList = (searchString: string, pageIndex: string, isLatestUsed?: boolean) => {
			const filteredSearchParams = filterSearchParams;
			filteredSearchParams.set('isLatestUsed', `${isLatestUsed ? 'true' : 'false'}`);
			filteredSearchParams.set('pageIndex', pageIndex);
			filteredSearchParams.set('pageSize', '15');
			filteredSearchParams.set('query', searchString);
			RxUtils.promisify(
				nmrSubstanceService.getSubstances(filteredSearchParams),
				(substanceData) => {
					setSearchResult(substanceData);
				},
				(error) => {
					notificationService.sendError(error.Message || t('search-references.search-error'));
				},
			);
		};

		useImperativeHandle<unknown, IAnalyticalReferenceMaterialRef>(
			ref,
			() => ({
				refreshSubstanceList: () =>
					getSubstanceList(
						typedString,
						(searchResult?.substances.pageIndex ?? 1).toString(),
						currentTab === ErmSearchTabValues.Recent,
					),
			}),
			[typedString, searchResult?.substances.pageIndex, currentTab],
		);
		const handleDRMSearch = (searchKey: string, pageIndex: number) => {
			DataTestId.resetState();
			setTypedString(searchKey);
			getSubstanceList(searchKey, `${pageIndex}`);
		};

		const checkIfFiltersOrSearchApplied = (searchParams: URLSearchParams, searchString: string) => {
			const hasQualityGradeFiltered = Boolean(searchParams.get(FilterKey.QUALITY_GRADES));
			const hasLibrariesFiltered = Boolean(searchParams.get(FilterKey.LIBRARIES));
			return hasQualityGradeFiltered || hasLibrariesFiltered || searchString.length > 0;
		};

		const showSubstanceCount =
			searchResult && searchResult.totalSubstanceCount > 0 && searchResult?.substances.data.some((s) => !s.isTheoretical);
		const showResultCount = (typedString && !currentTab) || (!typedString && currentTab !== ErmSearchTabValues.Recent);
		const onSearchClear = () => {
			const isFiltersOrSearchApplied = checkIfFiltersOrSearchApplied(filterSearchParams, typedString);
			isFiltersOrSearchApplied ? setCurrentTab(null) : setCurrentTab(ErmSearchTabValues.Recent);
			isFiltersOrSearchApplied && getSubstanceList('', '1', false);
			setTypedString('');
		};

		const renderList = () => {
			return (
				<NmrSubstanceList
					divider={<DRMDivider sx={{ marginX: 0 }} />}
					onRefreshSubstanceList={() => (
						getSubstanceList(
							typedString,
							`${searchResult?.substances?.pageIndex ?? 1}`,
							currentTab === ErmSearchTabValues.Recent,
						),
						refreshAddedSubstances()
					)}
					substanceList={substanceList}
					type="add"
					actionLabel={t('drm-detail.add-to-analysis')}
					onSubstanceAdd={(substanceId: number) => {
						addSubstanceOrErmToAnalysis(substanceId, false);
					}}
					disabled={!isAnalysisEditable}
					onDrmDetail={(drmVisibility: boolean, drm: NmrDrmDetail) => {
						const selectedDrm = TypeUtils.transform(NmrDrmDetail, drm);
						setDRMDetail({
							visible: drmVisibility,
							drmId: drm.id,
							isTheoretical: selectedDrm?.isTheoretical(),
							actionLabel: t('drm-detail.add-to-analysis'),
							actionBtnSx: { marginTop: 'auto' },
							onRequestDrm: (drmDetail) => {
								setDrmDetailData(drmDetail);
								setIsRequestErmModalVisible(true);
							},
							onHandleAction: (id: number) => addSubstanceOrErmToAnalysis(id, false),
							actionDisabled:
								!isAnalysisEditable ||
								addedERMs?.some((e) => e.selectedReferenceMaterials?.map((i) => i.id).includes(drm.id)),
						});
					}}
					itemSx={{ padding: '1rem 0' }}
				/>
			);
		};

		const drmModalRef = createRef<HTMLDivElement>();

		const onSearchParamsChange = (searchParams: URLSearchParams) => {
			const isFiltersOrSearchApplied = checkIfFiltersOrSearchApplied(filterSearchParams, typedString);
			isFiltersOrSearchApplied ? setCurrentTab(null) : setCurrentTab(ErmSearchTabValues.Recent);
			setFilterSearchParams(searchParams);
			getSubstanceList(typedString, '1', isFiltersOrSearchApplied ? false : true);
		};

		const onSearchFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const searchedString = event.target.value;
			searchedString === '' ? setCurrentTab(ErmSearchTabValues.Recent) : setCurrentTab(null);
			setTypedString(event.target.value);
			searchedString.length < MIN_SEARCH_INPUT_LEN && setSearchResult(undefined);
		};

		const onRequestRmClick = () => {
			setIsRequestErmModalVisible(true);
		};

		const CurrentCount = () => (
			<Typography variant="pg-xs">
				{typedString &&
					!currentTab &&
					`${searchResult?.totalSubstanceCount} ${t('drm')} ${t('new-analysis.results-for')} "${typedString}"`}
				{!typedString && currentTab !== ErmSearchTabValues.Recent
					? `${searchResult?.totalSubstanceCount} ${t('new-analysis.total-result')}`
					: ''}
			</Typography>
		);
		return (
			<>
				<Stack direction="column" width={1} marginTop={2}>
					{formik?.values.measurementType === QualitativeOrQuantitative.QUANTITATIVE && <WhatWeQuantify />}
					<NmrReferenceSearchField
						placeholder={t('new-analysis.search-message')}
						onSearch={(text) => handleDRMSearch(text, 1)}
						onSearchClear={() => onSearchClear()}
						value={typedString}
						onChange={onSearchFieldChange}
						inputProps={{ 'data-testid': 'erm-search-input-id', sx: { paddingLeft: 0 } }}
						searchParams={filterSearchParams}
						onSearchParamsChange={onSearchParamsChange}
						filteringSelections={getFiltersFromSearchParams(filterSearchParams)}
						filterData={filterOptions?.options ?? []}
					/>

					<Stack direction="row" justifyContent="space-between" marginTop={4}>
						<NmrDrmTabs
							value={currentTab}
							onChange={(_, newTab: ErmSearchTabValues) => {
								setCurrentTab(newTab);
								setFilterSearchParams(new URLSearchParams());
								setTypedString('');
							}}
						/>

						{searchResult && searchResult?.substances?.totalPages > 1 && currentTab !== ErmSearchTabValues.Recent && (
							<TablePagination
								count={searchResult.substances?.totalPages}
								page={searchResult.substances?.pageIndex}
								onChange={(newPage) => {
									handleDRMSearch(typedString, +newPage);
								}}
							/>
						)}
					</Stack>

					{showSubstanceCount && (
						<Stack>
							<CurrentCount />
							{showResultCount && <DRMDivider sx={{ marginTop: 1, marginX: 0 }} />}
						</Stack>
					)}

					{searchResult && searchResult.totalSubstanceCount === 0 && substanceList.length === 0 ? (
						<NmrAnalyticalSubstanceNoResult
							typedString={typedString}
							searchResult={searchResult}
							onRequestRmClick={onRequestRmClick}
						/>
					) : null}
					{searchResult && substanceList.length > 0 ? renderList() : null}
				</Stack>

				{useMemo(
					() => (
						<NmrDRMDetail onCloseClick={() => resetDrmDetail()} {...drmDetailProp} ref={drmModalRef} />
					),
					[drmDetailProp, drmModalRef],
				)}

				{isRequestErmModalVisible && (
					<RequestErm
						open={true}
						details={drmDetailData}
						refreshOnSuccess={false}
						onCancel={() => {
							setIsRequestErmModalVisible(false);
							setDrmDetailData(undefined);
							if (drmDetailProp.isTheoretical) {
								setDRMDetail({ visible: false, drmId: 0 });
							}
						}}
					/>
				)}
			</>
		);
	},
);
